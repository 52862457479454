import classNames from 'classnames';
import './ContentWithIconCard.scss';

interface ContentWithIconCardProps {
  className?: string;
  title?: string;
  content?: string;
  icon?: string;
}

export const ContentWithIconCard = ({
  className,
  title,
  content,
  icon,
}: ContentWithIconCardProps) => {
  return (
    <div className={classNames('ContentWithIconCard', className)}>
      <div className="ContentWithIconCard__icon">
        <img src={icon} alt={title} />
      </div>
      <div className="ContentWithIconCard__content">
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePosts } from '../../../entities/Post';
import {
  usePublicFriendships,
  useSocialMeta,
  useUserMeta,
} from '../../../entities/User';
import { ProfileCard } from '../../../features/ProfileCard';
import { Card } from '../../../horizon-components/Card/Card';
import { Tab, Tabs } from '../../../horizon-components/Tabs/Tabs';
import { useUserContext } from '../../../providers';
import { generateHashFromGuid } from '../../../utils/getUserData';
import { hasContent } from '../../../utils/hasContent';
import { getRandomUserImage } from '../../../utils/randomUserImage';
import { PublicProfileInfo } from '../../../widgets/PublicProfileInfo';
import { PublicProfileButtons } from './PublicProfileButtons';
import { PublicProfileFollowers } from './PublicProfileFollowers';
import { PublicProfileHeader } from './PublicProfileHeader';
import { PublicProfilePosts } from './PublicProfilePosts';
import './PublicProfile.scss';
import { DeletedProfile } from './DeletedProfile';

enum TabsEnum {
  NETWORK = 'network',
  POSTS = 'posts',
}

export const PublicProfile: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [currentTab, setCurrentTab] = useState(TabsEnum.NETWORK);
  const { user } = useUserContext();
  const { id: anotherUserGuid } = useParams();
  const { data: userMeta } = useUserMeta(anotherUserGuid || '');
  const { data: socialMeta } = useSocialMeta(anotherUserGuid);

  const { data: friends, isLoading: isLoadingFriends } =
    usePublicFriendships(anotherUserGuid);
  const { data: posts, isLoading: isLoadingPosts, fetchData } = usePosts();

  useEffect(() => {
    if (anotherUserGuid === 'undefined') return;
    fetchData({
      author_guid: anotherUserGuid || user?.guid || undefined,
    });
  }, [anotherUserGuid, user?.guid]);

  const userMetaHasContent = useMemo(
    () =>
      hasContent(userMeta, [
        'username',
        'profile_image',
        'phone_number',
        'last_name',
        'joined_at',
        'first_name',
        'email',
        'cover_image',
        'address',
      ]),
    [userMeta]
  );
  const socialMetaHasContent = useMemo(
    () => hasContent(socialMeta?.results[0], ['id']),
    [socialMeta?.results]
  );

  const coverSrc = useMemo(() => {
    let src =
      userMeta?.cover_image ||
      getRandomUserImage(generateHashFromGuid(anotherUserGuid || ''));
    return src;
  }, [userMeta?.cover_image]);

  const avatarSrc = useMemo(() => {
    let src =
      userMeta?.profile_image || require('../../../assets/images/profile.200.jpg');
    return src;
  }, [userMeta?.profile_image]);

  if (anotherUserGuid === 'undefined') {
    return <DeletedProfile />;
  }

  if (!user) return null;

  const tabsConfig: Tab[] = [
    {
      id: TabsEnum.NETWORK,
      title: `${t('profile.network')} (${friends?.count})`,
    },
    {
      id: TabsEnum.POSTS,
      title: `${t('posts')} (${posts?.count})`,
    },
  ];

  const toggleTab = (tab: Tab) => {
    setCurrentTab(tab.id as TabsEnum);
  };

  return (
    <div className="PublicProfile m-auto xl:container">
      <PublicProfileHeader />

      <div
        className="-mx-[14px] -mb-11 block h-[88px] bg-cover bg-center bg-no-repeat sm:h-40 md:hidden"
        style={{ backgroundImage: `url(${coverSrc})` }}
      />
      <div
        className={`PublicProfile__content ${
          !(userMetaHasContent || socialMetaHasContent)
            ? 'md:grid-cols-5 lg:grid-cols-7'
            : !anotherUserGuid
            ? 'md:grid-cols-5'
            : 'md:grid-cols-2'
        }`}
      >
        <Card
          extra={`PublicProfile__card ${
            !(userMetaHasContent || socialMetaHasContent)
              ? 'md:col-start-2 md:col-end-5 lg:col-start-3 lg:col-end-6'
              : !anotherUserGuid
              ? 'md:col-span-2'
              : 'md:col-span-1'
          }`}
        >
          <div className="PublicProfile__profile">
            <img src={avatarSrc} alt={userMeta?.username} />
            <div>
              <h2>{userMeta?.username}</h2>
              <p>
                {userMeta?.last_name} {userMeta?.first_name}
              </p>
            </div>
          </div>
          <ProfileCard
            userMeta={userMeta}
            guid={anotherUserGuid}
            className="md-max:hidden"
          />
          <PublicProfileButtons />
        </Card>

        {(userMetaHasContent || socialMetaHasContent) && (
          <Card
            extra={`PublicProfile__card ${
              !anotherUserGuid || !(userMetaHasContent || socialMetaHasContent)
                ? 'md:col-span-3'
                : 'md:col-span-1'
            }`}
          >
            <PublicProfileInfo
              userMeta={userMeta}
              socialMeta={socialMeta?.results[0]}
            />
          </Card>
        )}
      </div>

      <div className={`PublicProfile__content !items-start md:grid-cols-2`}>
        <Card extra={`PublicProfile__card md:!gap-[16px] md:!hidden`}>
          <Tabs
            tabs={tabsConfig}
            onTabChanged={toggleTab}
            tabsExtra={'!gap-[10px] !justify-start !m-0 !overflow-auto'}
            itemExtra={'!min-w-[140px] justify-center'}
          />
          {currentTab === TabsEnum.NETWORK ? (
            <PublicProfileFollowers data={friends} isLoading={isLoadingFriends} />
          ) : (
            <PublicProfilePosts data={posts} isLoading={isLoadingPosts} />
          )}
        </Card>

        <Card extra={`PublicProfile__card md:!gap-[16px] md-max:!hidden`}>
          <PublicProfileFollowers data={friends} isLoading={isLoadingFriends} />
        </Card>

        <Card extra={`PublicProfile__card md:!gap-[16px] md-max:!hidden`}>
          <PublicProfilePosts data={posts} isLoading={isLoadingPosts} />
        </Card>
      </div>
    </div>
  );
};

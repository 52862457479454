import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ApplyIcon } from '../../../assets/icons/filter/apply.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/filter/close.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter/filter.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/filter/refresh.svg';
import { TitleBack } from '../../../components/TitleBack';
import useHistoryParams from '../../../hooks/useHistoryParams';
import { Badge } from '../../../horizon-components/Badge';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { RangeSlider } from '../../../horizon-components/RangeSlider';
import { FilterOption } from '../model/types/types';
import './Filter.scss';

interface FilterProps {
  options?: FilterOption[];
  onFilter?: (filter: { [key: string]: any }) => void;
  onClose?: () => void;
  isOpen?: boolean;
  defaultFilter?: { [key: string]: any };
  showInMobile?: boolean;
  btnStyle?: string;
  defaultTitel?: { [key: string]: any };
  onGetRemove?: () => void;
}

export const Filter = ({
  options,
  onFilter,
  onClose,
  isOpen = false,
  defaultFilter = {},
  showInMobile,
  btnStyle,
  defaultTitel = {},
  onGetRemove,
}: FilterProps) => {
  const location = useLocation();
  const { t } = useTranslation(['translation']);
  const [changed, setChanged] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [titles, setTitles] = useState<{ [key: string]: string }>({});
  const searchParams = new URLSearchParams(location.search);
  const ref = useRef<HTMLDivElement>(null);
  const { updateAllParamsInUrl, removeParamFromUrl } = useHistoryParams();

  useEffect(() => setFilters(defaultFilter), [Object.entries(defaultFilter).length]);
  useEffect(() => setTitles(defaultTitel), [Object.entries(defaultTitel).length]);

  useEffect(() => {
    const filters = { ...defaultFilter };
    const titles = { ...defaultTitel };

    searchParams.forEach((value, key) => {
      let option = options?.find((option) => option.key.includes(key));

      if (option?.id) {
        filters[key] =
          option?.type === 'array' ? value.split(',') : checkBoolean(value);
        titles[key] =
          option?.type === 'array'
            ? value.split(',').length > 1
              ? `${option?.items?.find(
                  (item) => item.slug.toString() === value.split(',')[0].toString()
                )?.title}, ${value.split(',').length - 1}+`
              : option?.items?.find(
                  (item) => item.slug.toString() === value.split(',')[0].toString()
                )?.title
            : option?.items?.find(
                (item) => item.slug.toString() === value.toString()
              )?.title;
      }

      if (
        Object.entries(defaultFilter).findIndex(
          (def) => def[0] === key && def[1] === value
        ) > -1
      ) {
        delete titles[key];
      }
    });

    // Добовляем title для range параметров
    const range = options?.find((option) => option.key.length > 1);
    if (
      range?.key?.length &&
      searchParams.get(range.key[0]) &&
      searchParams.get(range.key[1])
    ) {
      titles[range?.key?.join('')] =
        range?.title +
        ' ' +
        searchParams.get(range.key[0]) +
        '-' +
        searchParams.get(range.key[1]);
    }

    // Удаляем пустыз поля
    for (let key in titles) {
      if (titles[key] === undefined || titles[key] === null || titles[key] === '') {
        delete titles[key];
      }
    }

    setFilters(filters);
    setTitles(titles);
    onFilter?.(filters);
  }, [options, t]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleClick = (
    key: string,
    slug: string | number,
    type: string,
    title: string
  ) => {
    const filter = filters?.[key] || [];
    if (type === 'array') {
      const idx = (filter as any[])?.findIndex((filter) => filter === slug);
      const newFilter = {
        ...filters,
        [key]:
          idx !== -1
            ? (filter as any[]).filter((filter) => filter !== slug)
            : [...(filter as any[]), slug],
      };
      setFilters?.(newFilter);
      if (!(newFilter[key] as [])?.length) {
        newFilter[key] = undefined;
        delete titles[key];
        setTitles({ ...titles });
        return;
      }
      let option = options?.find((option) => option.key.includes(key));
      const newTitle = Array.isArray(newFilter?.[key])
        ? (newFilter?.[key] as []).length > 1
          ? `${option?.items?.find((item) => item.slug === newFilter[key][0])
              ?.title}, ${(newFilter?.[key] as []).length - 1}+`
          : option?.items?.find((item) => item.slug === newFilter[key][0])?.title ||
            ''
        : title;
      setTitles({ ...titles, [key]: newTitle });
    } else {
      if (slug === 'all') {
        filters[key] = undefined;
        delete titles[key];
        setFilters?.({ ...filters });
        setTitles({ ...titles });
        removeParamFromUrl(key);
      } else {
        setFilters?.({ ...filters, [key]: slug });
        if (
          Object.entries(defaultFilter).findIndex(
            (def) => def[0] === key && def[1] === slug
          ) > -1
        ) {
          delete titles[key];
          setTitles({ ...titles });
        } else {
          setTitles({ ...titles, [key]: title });
        }
      }
    }
    setChanged(true);
  };

  const handleChange = (
    keyMin: string,
    keyMax: string,
    min: number,
    max: number,
    title: string
  ) => {
    setFilters?.({
      ...filters,
      [keyMin]: min,
      [keyMax]: max,
    });
    setTitles({
      ...titles,
      [keyMin + keyMax]: `${title} ${min}-${max}`,
    });
    setChanged(true);
  };

  const handleApply = () => {
    setChanged(false);
    onFilter?.(filters);
    updateAllParamsInUrl(filters);
    onClose?.();
  };

  const handleReset = () => {
    onGetRemove?.();
    setChanged(false);
    for (let key in filters) {
      filters[key] = undefined;
      removeParamFromUrl(key);
    }
    setFilters?.({ ...defaultFilter });
    onFilter?.({ ...filters, ...defaultFilter });
    updateAllParamsInUrl({ ...filters, ...defaultFilter });
    setTitles({});
  };

  const handleRemove = (key: string) => {
    onGetRemove?.();
    delete titles[key];
    setTitles?.(titles);

    for (const filterKey in filters) {
      if (filterKey.endsWith('min')) {
        const maxProp = filterKey.replace('min', 'max');
        delete filters[filterKey];
        delete filters[maxProp];
      } else if (filterKey.includes(key)) {
        if (defaultFilter[key]) {
          filters[key as string] = defaultFilter[key];
        } else {
          filters[key] = undefined;
          removeParamFromUrl(key);
        }
      }
    }

    setFilters?.({ ...filters });
    onFilter?.({ ...filters });
    updateAllParamsInUrl({ ...filters });
  };

  const renderFilterModal = () => {
    if (!isOpen) return null;
    return (
      <div className="Filter" ref={ref}>
        <MButton
          color="white"
          variant="highlighted"
          onClick={onClose}
          className="Filter__close shadow-sm shadow-shadow-600 sm-max:hidden"
          size="default"
        >
          <CloseIcon />
        </MButton>

        <div className="flex items-center justify-between gap-3 sm:!hidden">
          <TitleBack
            title={t('buttons.filter')}
            onClick={onClose}
            className="!static"
          />
          <div className="flex items-center justify-between gap-2">
            {!changed ? (
              <MButton
                color="white"
                variant="highlighted"
                onClick={handleReset}
                className="h-[42px] w-[42px] !p-[10px] shadow-sm shadow-shadow-600"
              >
                <RefreshIcon />
              </MButton>
            ) : (
              <MButton
                color="primary"
                variant="highlighted"
                onClick={handleApply}
                className="h-[42px] w-[42px] !p-[10px] shadow-sm shadow-shadow-600"
              >
                <ApplyIcon />
              </MButton>
            )}
          </div>
        </div>

        {options?.map((option) => (
          <div className="Filter__item" key={option.id}>
            <h4>{option.title}</h4>
            {option.component === 'badge' ? (
              <div className="flex flex-wrap gap-2">
                {option?.items?.map((item) => (
                  <Badge
                    variant={option.variant}
                    onClick={() =>
                      handleClick(option?.key[0], item.slug, option.type, item.title)
                    }
                    active={
                      option.type === 'single'
                        ? filters?.[option?.key[0]]
                          ? filters?.[option?.key[0]]
                              .toString()
                              .toLocaleLowerCase() ===
                              item.slug.toString().toLocaleLowerCase() &&
                            item.slug !== 'all'
                          : item.slug === 'all'
                        : option.type === 'array' &&
                          (filters?.[option?.key[0]] as string[])?.length
                        ? (filters?.[option?.key[0]] as string[])?.findIndex(
                            (filter) => filter === item?.slug && item?.slug !== 'all'
                          ) !== -1
                        : false
                    }
                    className="capitalize"
                    key={item.id}
                  >
                    {item.title}
                  </Badge>
                ))}
              </div>
            ) : (
              <div className="-mt-5">
                <RangeSlider
                  min={option.settings?.min || 1}
                  max={option.settings?.max || 20}
                  step={option.settings?.step}
                  value={
                    filters?.[option?.key[0]]
                      ? {
                          min: Number(filters?.[option?.key[0]]),
                          max: Number(filters?.[option?.key[1]]),
                        }
                      : {
                          min: Number(option.settings?.min),
                          max: Number(option.settings?.max),
                        }
                  }
                  onChange={(val) =>
                    handleChange(
                      option.key[0],
                      option.key[1],
                      val.min,
                      val.max,
                      option.title
                    )
                  }
                />
              </div>
            )}
          </div>
        ))}

        <div className="hidden justify-end gap-[10px] sm:flex">
          <MButton
            color="white"
            variant="highlighted"
            className="shadow-sm shadow-shadow-600"
            onClick={handleReset}
          >
            {t('buttons.reset')}
          </MButton>
          <MButton
            color="primary"
            variant="highlighted"
            className="shadow-sm shadow-shadow-600"
            onClick={handleApply}
          >
            {t('buttons.apply')}
          </MButton>
        </div>
      </div>
    );
  };

  const checkBoolean = (str: string) => {
    if (str === 'false') {
      return false;
    } else if (str === 'true') {
      return true;
    } else {
      return str;
    }
  };

  return (
    <>
      <div
        className={`${
          showInMobile ? 'flex' : 'hidden'
        } rounded-2xl bg-white shadow-sm shadow-shadow-700 sm:flex`}
      >
        {!isOpen && !!Object.keys(titles).length && (
          <div className="hidden gap-2 p-[7px] md:flex">
            {Object.entries(titles).map((title, idx) => (
              <Badge
                variant="primary"
                onClick={() => handleRemove(title[0])}
                className="capitalize"
                key={idx}
              >
                {title[1]} <CloseIcon />
              </Badge>
            ))}
          </div>
        )}
        <MButton
          color="white"
          variant="highlighted"
          className={`!h-[42px] !w-[42px] !px-[12px] shadow-sm shadow-shadow-600 ${btnStyle}`}
          onClick={onClose}
        >
          <FilterIcon />
        </MButton>
      </div>
      {renderFilterModal()}
    </>
  );
};

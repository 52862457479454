import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { CommunityView } from '../../../features/CommunityView';
import { JoinStatus } from '../../../models/Community';
import { NoAvatar } from '../../NoAvatar';
import './CommunityCard.scss';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

type Props = {
  id: number;
  name: string;
  image: string;
  members_count: number;
  join_status: JoinStatus;
  subject: string;
  type: string;
  chat: number;
  isFollow?: boolean;
};

export const CommunityCard: React.FC<Props> = ({
  id,
  name,
  image,
  members_count,
  join_status,
  subject,
  type,
  chat,
  isFollow = false,
}) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const [members, setMemebers] = useState<number>(members_count);
  const goTo = (url: string) => navigate(url);
  return (
    <div className={'CommunityCard'}>
      <div className={'CommunityCard__head'}>
        <Link
          to={MainRoutes.weber + WeberRoutes.community + `/${id}`}
          className="flex-none"
        >
          <NoAvatar className={'h-full w-full rounded-xl'} src={image} alt={name} />
        </Link>
        <div>
          <Link to={MainRoutes.weber + WeberRoutes.community + `/${id}`}>
            <h4 className="cursor-pointer">{name}</h4>
          </Link>
          <div className={'flex w-full gap-1 md:gap-2'}>
            {subject && (
              <>
                <span className="capitalize">
                  <Link
                    to={
                      MainRoutes.weber +
                      WeberRoutes.communities +
                      `/?subject=${subject}`
                    }
                  >
                    {subject}
                  </Link>
                </span>
                <span className="!font-semibold">•</span>
              </>
            )}
            <span>
              {members} {t('members')}
            </span>
          </div>
        </div>
      </div>
      <div className={'CommunityCard__buttons'}>
        <CommunityView
          communityId={id}
          join_status={join_status}
          type={type}
          onClick={() => goTo(MainRoutes.weber + WeberRoutes.community + `/${id}`)}
          onChat={() =>
            goTo(
              MainRoutes.auth +
                AuthRoutes.profile +
                ProfileRoutes.chat +
                `?chat=${chat}`
            )
          }
          onFollow={() => setMemebers(members + 1)}
          showChat={isFollow}
        />
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { ReactComponent as PlayIcon } from '../assets/icons/play.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import { useTranslation } from 'react-i18next';
import './GradeCard.scss';

interface GradeCardProps {
  style?: {};
  className?: string;
  title?: string;
  description?: string;
  completed?: number;
  units?: number;
  isActive?: boolean;
  isEnrolled?: boolean;
  onActive?: () => void;
}

export const GradeCard = ({
  style,
  className,
  title,
  description,
  completed,
  units,
  isActive = false,
  isEnrolled = false,
  onActive,
}: GradeCardProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div
      className={classNames('GradeCard', className, {
        active: isActive,
        enrolled: completed,
      })}
      onClick={onActive}
      style={style || {}}
    >
      <div>
        <h4>{title}</h4>
        {description}
      </div>
      <div className="flex items-center gap-1">
        <span>
          {completed ? completed + '/' + units : units + ' ' + t('course.units')}
        </span>
        {isActive && !isEnrolled && <PlayIcon />}
        {isEnrolled && <CheckIcon />}
      </div>
    </div>
  );
};

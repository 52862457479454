import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DashIcon } from '../../assets/horizon/DashIcon';

export type Props = {
  routes: RoutesType[];
  hovered?: boolean;
  mini?: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement | HTMLAnchorElement>;
};

export const SidebarLinks: React.FC<Props> = (props) => {
  const { routes, hovered, mini, onClose } = props;
  let location = useLocation();
  const [active, setActive] = useState('');
  const [page, setPage] = useState('');

  useEffect(() => {
    const path = routes?.[0].path || routes?.[0].items?.[0].path;
    !!path && setActive(path);
  }, [page]);

  useEffect(() => {
    if (location.pathname.split('/')[1] !== page) {
      setPage(location.pathname.split('/')[1]);
    }
  }, [location.pathname]);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    if (routeName === '/' && location.pathname === '/') {
      return true;
    } else {
      return routeName && routeName !== '/' && location.pathname.includes(routeName);
    }
  };

  const onLinkClicked = (
    e: React.MouseEvent<HTMLAnchorElement>,
    route: RoutesType
  ) => {
    if (route.disabled) {
      e.preventDefault();
      return;
    }
    setActive(route.path);
    if (window.innerWidth < 1200) {
      onClose(e);
    }
  };

  const renderLink = (route: RoutesType, key: number) => (
    <Link
      onClick={(e) => onLinkClicked(e, route)}
      to={route.path}
      key={key}
      className={route.disabled ? 'cursor-default' : 'cursor-pointer'}
    >
      {route.icon ? (
        <div>
          <div className="relative mb-2 flex">
            <li className="my-[3px] flex px-8" key={key}>
              <span
                className={`mt-0.5 font-bold ${route.disabled && '!text-gray-300'} ${
                  activeRoute(route.path) ? 'text-purple-800' : 'text-gray-600'
                } ${activeRoute(route.path) ? 'fill-purple-800' : 'fill-gray-600'}`}
              >
                {route.icon}
              </span>
              {hovered && (
                <span
                  className={`ml-2 mt-[3px] flex text-base font-bold ${
                    route.disabled && '!text-purple-300'
                  } ${
                    activeRoute(route.path) ? 'text-purple-800' : 'text-gray-600'
                  }`}
                >
                  {mini === false
                    ? route.name
                    : mini === true && hovered === true
                    ? route.name
                    : route.name[0]}
                </span>
              )}
            </li>
            {activeRoute(route.path) ? (
              <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500" />
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className={`relative mb-2 flex ${
            mini === false
              ? ''
              : mini === true && hovered === true
              ? ''
              : 'xl:justify-center'
          } hover:cursor-pointer`}
        >
          <li className="my-[3px] flex items-center px-[22px]" key={key}>
            <span
              className={`flex text-sm leading-none ${
                mini === false
                  ? 'ml-9'
                  : mini === true && hovered === true
                  ? 'ml-9'
                  : 'ml-9 xl:ml-0'
              } ${
                activeRoute(route.path)
                  ? 'font-medium text-navy-700 dark:text-white'
                  : 'font-medium text-gray-600'
              }`}
            >
              {mini === false
                ? route.name
                : mini === true && hovered === true
                ? route.name
                : route.name[0]}
            </span>
          </li>
        </div>
      )}
    </Link>
  );

  const createLinks = (routes?: RoutesType[]) => {
    return routes?.map((route, key) => {
      if (route.secondary) return null;
      if (route.collapse) {
        return (
          <div key={key}>
            {route?.items?.map((item, index) => renderLink(item, index))}
          </div>
        );
      } else {
        return renderLink(route, key);
      }
    });
  };

  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;

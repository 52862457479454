import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getProfile, getProfileMe } from '../../../api/profile';
import { getTeacherCoursesCount } from '../../../api/teacher';
import { creatChat } from '../../../api/weber/chat';
import { ReactComponent as ChatIcon } from '../../../assets/icons/profile/chat.svg';
import { ReactComponent as FollowIcon } from '../../../assets/icons/profile/follow.svg';
import { ReactComponent as UnfollowIcon } from '../../../assets/icons/profile/unfollow.svg';
import { useFriendships } from '../../../entities/User';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { PublicProfileStats } from '../../../models';
import { FriendshipStatus } from '../../../models/Friendship';
import { useUserContext } from '../../../providers';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

export const PublicProfileButtons = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id: anotherUserGuid } = useParams();
  const [userProfile, setUserProfile] = useState<PublicProfileStats | null>(null);
  const [coursesCount, setCoursesCount] = useState<string>('');

  const {
    data: friendship,
    onFollow,
    onAccept,
    onReject,
    isLoadingButton,
  } = useFriendships(anotherUserGuid);

  useEffect(() => {
    if (anotherUserGuid) {
      getProfile(anotherUserGuid).then(setUserProfile);
    } else {
      getProfileMe().then(setUserProfile);
      getTeacherCoursesCount().then((res) => setCoursesCount(res.courses_count));
    }
  }, [anotherUserGuid]);

  const goToChat = () => {
    creatChat(anotherUserGuid || '')
      .catch(console.log)
      .finally(() => {
        navigate(
          MainRoutes.auth +
            AuthRoutes.profile +
            ProfileRoutes.chat +
            `?chat=${friendship?.results[0].id}`
        );
      });
  };

  return (
    <>
      {userProfile && (
        <div className="PublicProfile__count">
          <div>
            <h4>{coursesCount || 0}</h4>
            <p>{t('course.Courses')}</p>
          </div>
          <div>
            <h4>{userProfile.posts_count}</h4>
            <p>{t('posts')}</p>
          </div>
          <div>
            <h4>{userProfile.network_count}</h4>
            <p>{t('profile.followers')}</p>
          </div>
        </div>
      )}

      {anotherUserGuid !== user?.guid && (
        <div className="PublicProfile__btns">
          <MButton
            color="white"
            variant="highlighted"
            className="hidden shadow-md shadow-shadow-600 md:block"
            onClick={goToChat}
          >
            {t('chatting')} <ChatIcon />
          </MButton>
          {friendship?.results[0]?.status === FriendshipStatus.FRIENDS ? (
            <MButton
              color="primary"
              variant="secondary"
              disabled={isLoadingButton}
              loading={isLoadingButton}
              onClick={() => onReject(friendship?.results[0]?.id)}
            >
              {t('buttons.Disconnect')} <UnfollowIcon />
            </MButton>
          ) : friendship?.results[0]?.status ===
            FriendshipStatus.WANTS_TO_BE_FRIENDS ? (
            <>
              <MButton
                color="primary"
                variant="secondary"
                disabled={isLoadingButton}
                loading={isLoadingButton}
                onClick={() => onReject(friendship?.results[0]?.id)}
              >
                {t('buttons.Reject')}
              </MButton>
              <MButton
                color="primary"
                variant="highlighted"
                disabled={isLoadingButton}
                loading={isLoadingButton}
                onClick={() => onAccept(friendship?.results[0]?.id)}
              >
                {t('buttons.Accept')}
              </MButton>
            </>
          ) : friendship?.results[0]?.status ===
            FriendshipStatus.WAITING_FOR_ACCEPT ? (
            <MButton
              color="primary"
              variant="secondary"
              disabled={isLoadingButton}
              loading={isLoadingButton}
              onClick={() => onReject(friendship?.results[0]?.id)}
            >
              {t('buttons.Cancel_request')}
            </MButton>
          ) : (
            <MButton
              color="primary"
              variant="highlighted"
              disabled={isLoadingButton}
              loading={isLoadingButton}
              onClick={() => anotherUserGuid && onFollow(anotherUserGuid)}
            >
              {t('buttons.Connect')} <FollowIcon />
            </MButton>
          )}
        </div>
      )}
    </>
  );
};

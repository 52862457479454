import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  checkEnrolledUserCourse,
  checkPurchaseDigitalPass,
  enrollUserCourse,
  getCourseViewById,
} from '../../../api/course';
import { saveCurrentUrl } from '../../../api/sessions';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { Loader } from '../../../components/Loader';
import { MediaViewer } from '../../../components/MediaViewer/MediaViewer';
import { TitleBack } from '../../../components/TitleBack';
import { useDPCategoriesByCourse } from '../../../entities/DigitalPass';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { Modal } from '../../../horizon-components/Modal';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Course } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import getErrorMessages from '../../../utils/getErrorMessages';
import { getUserData } from '../../../utils/getUserData';
import { CourseBuyModal } from '../../../widgets/CourseBuyModal';
import { NoAvatar } from '../../../widgets/NoAvatar';
import { CourseCount } from './components/CourseCount';
import { CourseDigitalPass } from './components/CourseDigitalPass';
import CourseInfo from './components/CourseInfo';
import CourseUnitsView from './components/CourseUnitsView';
import './CoursePreview.scss';
import { subscribeFirstLevel } from '../../../api/digitalPass';

export const CoursePreview = () => {
  const { id } = useParams();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [course, setCourse] = useState<Course | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [digitalPassCategoryId, setDigitalPassCategoryId] = useState<
    number | undefined
  >(undefined);
  const [digitalPassId, setDigitalPassId] = useState<number | undefined>(undefined);
  const [breads, setBreads] = useState<Bread[]>([]);
  const { data, isLoading: isCourseLoading, fetchData } = useDPCategoriesByCourse();

  useEffect(() => {
    if (course?.id) {
      setBreads([
        { title: 'EDU', url: MainRoutes.edu },
        { title: t('course.allCourses'), url: MainRoutes.edu + EduRoutes.courses },
        {
          title: course.subject.title,
          url:
            MainRoutes.edu + EduRoutes.courses + `/?subject=${course.subject.slug}`,
        },
        {
          title: course.title,
          url: MainRoutes.edu + EduRoutes.course + `/${id}`,
        },
      ]);
    }
  }, [course, t]);

  const userData = getUserData(course?.owner);
  const introductionFile = course?.introduction_files[0];

  const checkPurchase = useCallback(() => {
    checkPurchaseDigitalPass(Number(id))
      .then((data) => {
        setIsPurchased(data.purchased);
        !data?.purchased && setDigitalPassCategoryId(data?.digital_pass_category_id);
        !data?.purchased && setDigitalPassId(data?.digital_pass_id);
      })
      .catch(() => {
        setIsPurchased(false);
      });
  }, [id]);

  const checkEnroll = useCallback(() => {
    checkEnrolledUserCourse(Number(id))
      .then((data) => {
        setIsEnrolled(data.is_enrolled);
      })
      .catch(() => {
        setIsEnrolled(false);
      });
  }, [id]);

  useEffect(() => {
    const courseId = Number(id);

    if (!id || Number.isNaN(courseId)) {
      navigate(MainRoutes.edu + EduRoutes.courses);
      return;
    }

    setIsLoading(true);
    getCourseViewById(courseId)
      .then((data) => setCourse(data))
      .finally(() => setIsLoading(false));

    setIsPurchased(true);

    checkPurchase();
    checkEnroll();
  }, [id, checkEnroll, checkPurchase, navigate, t]);

  const enrollCourse = () => {
    return enrollUserCourse(Number(id || 0))
      .then(() => navigate(MainRoutes.edu + EduRoutes.enroll + `/${id}`))
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
          position: 'top-center',
        });
      });
  };

  const enrollBtnHandler = () => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    if (!isPurchased) {
      const digital_pass_id = data?.results[0]?.digital_passes?.[0]?.id;

      digital_pass_id &&
        subscribeFirstLevel([digital_pass_id]).then(() => enrollCourse());
    } else if (!isEnrolled) {
      enrollCourse();
    } else {
      goToCourse();
    }
  };

  const goToCourse = () => navigate(MainRoutes.edu + EduRoutes.enroll + `/${id}`);
  const goToBack = () => navigate(-1);

  const handleOpen = () => {
    fetchData(course?.id || 0);
    setIsOpen(!isOpen);
  };

  return (
    <div className="CoursePreview">
      <Breadcrumbs breads={breads} className="hidden md:block" />
      <TitleBack
        title={course?.title}
        onClick={goToBack}
        className="block md:!hidden"
      />

      {isLoading ? (
        <Loader />
      ) : (
        !!course && (
          <div className="CoursePreview__content">
            <div className="CoursePreview__list">
              <div className="CoursePreview__head">
                <div className="CoursePreview__user">
                  <Link
                    to={
                      MainRoutes.auth +
                      AuthRoutes.profile +
                      ProfileRoutes.user +
                      `/${userData?.guid}`
                    }
                  >
                    <NoAvatar src={userData.image} alt={userData.username} />
                  </Link>
                  <div>
                    <h4 className="hidden md:block">{course?.title}</h4>
                    <h4 className="block md:hidden">
                      <Link
                        to={
                          MainRoutes.auth +
                          AuthRoutes.profile +
                          ProfileRoutes.user +
                          `/${userData?.guid}`
                        }
                        className="block md:hidden"
                      >
                        {t('course.By')} {userData.username}
                      </Link>
                    </h4>
                    <div>
                      <span className="md-max:hidden">
                        <Link
                          to={
                            MainRoutes.auth +
                            AuthRoutes.profile +
                            ProfileRoutes.user +
                            `/${userData?.guid}`
                          }
                        >
                          {t('course.By')} {userData.username}
                        </Link>
                      </span>
                      <span className="!font-semibold md-max:hidden">•</span>
                      <span>{moment(course.created_at).format('DD.MM.YY')}</span>
                    </div>
                  </div>
                </div>
                {isEnrolled ? (
                  <MButton
                    onClick={goToCourse}
                    color={'primary'}
                    variant={'outline'}
                  >
                    {t('btnsText.continue')}
                  </MButton>
                ) : (
                  <MButton
                    onClick={() => (!isPurchased ? handleOpen() : enrollCourse())}
                    color={'primary'}
                    variant={'highlighted'}
                    className="z-10 sm-max:fixed sm-max:bottom-[72px] sm-max:left-2 sm-max:w-[calc(100vw_-_16px)]"
                  >
                    {t('btnsText.enroll')}
                  </MButton>
                )}
              </div>
              <CourseDigitalPass course={course} className="md:!hidden" />
              <MediaViewer
                link={introductionFile?.file || ''}
                bg={course.cover_image}
              />
              {/* Course info */}
              <div className="CoursePreview__info content">
                <CourseInfo course={course} isEnrolled={isEnrolled} />
              </div>
            </div>
            <div className="CoursePreview__aside !hidden md:!flex">
              <CourseDigitalPass course={course} />
              <CourseCount
                rating={parseFloat(course?.rating?.toFixed(1)) || 0}
                enrolls={course?.enrolls_count || 0}
              />
              <CourseUnitsView course={course} />
            </div>
          </div>
        )
      )}

      <Modal isOpen={isOpen} onClose={handleOpen}>
        {isCourseLoading ? (
          <Loader />
        ) : (
          <CourseBuyModal
            className="max-w-4xl"
            title={data?.results?.[0]?.subject?.title}
            description={t('course.description', {
              category: data?.results?.[0]?.digital_passes?.[0]?.name || '',
            })}
            img={data?.results[0].image_small}
            school={data?.results[0]?.digital_passes?.[0]}
            pass={data?.results[0]}
            onBuy={enrollBtnHandler}
          />
        )}
      </Modal>
    </div>
  );
};

import instance from './instance';
import { TeacherPublicProfile } from '../models/Teacher';
import { ResponsePagination } from '../models/Response';
import { Tool } from '../models/Tools';

export function getTeacher(teacherId: string): Promise<TeacherPublicProfile> {
  return instance()
    .get(`/auth/teacher-view/${teacherId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function dynamicLinks(): Promise<ResponsePagination<Tool>> {
  return instance()
    .get(`/support/dynamic-links/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getTeacherCoursesCount(): Promise<{ courses_count: string }> {
  return instance()
    .get(`/teacher-performance/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

import instance from './instance';
import { DigitalPassCategory } from '../models/DigitalPass';
import { ResponsePagination } from '../models/Response';

export function getPurchasableSubjectV3(
  page?: number,
  ordering?: string
): Promise<ResponsePagination<DigitalPassCategory>> {
  return instance()
    .get(`/v3/purchasable-subjects/public/purchasable-subject/`, {
      params: { page, ordering },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPurchasedSubjectsV3(
  page?: number
): Promise<ResponsePagination<DigitalPassCategory>> {
  return instance()
    .get(`/v3/purchasable-subjects/private/purchased-subjects/`, {
      params: { page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFavouritePurchasableSubjectsV3(): Promise<number[]> {
  return instance()
    .get(`/v3/purchasable-subjects/private/favourite-purchasable-subjects-ids`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

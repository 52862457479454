import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  OrderBy,
  getCourseReview,
  getUserCoursesReviews,
} from '../../../../api/courseReview';
import { ButtonsFilter } from '../../../../components/ButtonsFilter';
import { CourseComment } from '../../../../components/CourseComment';
import { CourseRating } from '../../../../components/CourseRating';
import { Loader } from '../../../../components/Loader';
import { CourseFeedback } from '../../../../features/CourseFeedback';
import { Pagination } from '../../../../horizon-components/Pagination';
import { TRating, TReview } from '../../../../models/Review';
import { PAGE_SIZE } from '../../../../shared/lib/const/limit';
import getErrorMessages from '../../../../utils/getErrorMessages';

interface CourseReviewProps {
  className?: string;
  isEnrolled: boolean;
}

export const CourseReview = ({ className, isEnrolled }: CourseReviewProps) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const [review, setReview] = useState<TReview>();
  const [reviews, setReviews] = useState<TRating[]>();
  const [mine, setMine] = useState<TRating[]>();
  const [filter, setFilter] = useState<OrderBy>('-rating');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMine, setIsLoadingMine] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getRating();
  }, [id]);

  const getRating = () => {
    setIsLoadingMine(true);
    getCourseReview(Number(id)).then(setReview);
    getUserCoursesReviews(Number(id), 1, 'mine')
      .then((res) => res?.results?.length && setMine(res.results))
      .finally(() => setIsLoadingMine(false));
  };

  useEffect(() => {
    setIsLoading(true);
    getUserCoursesReviews(Number(id), page, 'others', filter)
      .then((res) => {
        setReviews(res.results);
        setPageCount(Math.ceil(res.count / PAGE_SIZE));
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
          position: 'top-center',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page]);

  const sort: { id: OrderBy; title: string }[] = [
    { id: '-rating', title: t('sort.positive') },
    { id: 'rating', title: t('sort.negative') },
    { id: '-created_at', title: t('sort.latest') },
  ];

  const handleFilter = (filterId: OrderBy) => {
    setFilter(filterId);
    setIsLoading(true);
    getUserCoursesReviews(Number(id), 1, 'others', filterId)
      .then((res) => setReviews(res.results))
      .finally(() => setIsLoading(false));
  };

  const generateReviewElement = (review: TRating, mine: boolean) => {
    return (
      <CourseComment
        userReaction={review.user_reaction}
        user={review.user}
        reviewLikes={review.review_reactions}
        reviewDate={review.created_at}
        reviewRating={review.rating}
        reviewId={review.id}
        key={review.id}
        mine={mine}
      >
        {review.comment}
      </CourseComment>
    );
  };

  return (
    <div className={classNames('flex w-full flex-col gap-4 md:gap-6', className)}>
      {isLoadingMine ? (
        <Loader />
      ) : mine?.[0]?.id ? (
        generateReviewElement(mine[0], true)
      ) : (
        isEnrolled && <CourseFeedback onSend={getRating} courseId={Number(id)} />
      )}
      <CourseRating
        totalRating={review?.average_rating}
        ratingsBreakdown={review?.ratings_breakdown}
      />

      <div className="flex flex-col gap-[8px] md:gap-[16px]">
        <div className="flex justify-end">
          <ButtonsFilter buttons={sort} active={filter} onFilter={handleFilter} />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          !!reviews?.length && (
            <>
              {reviews?.map((review) => generateReviewElement(review, false))}
              {pageCount > 1 && (
                <div className={'flex w-full items-center justify-between'}>
                  <p>{t('edu.Your_pagination_could_be_here')}</p>
                  <Pagination
                    currentPage={page}
                    onPageChange={(pageNumber: number) => setPage(pageNumber)}
                    totalPages={pageCount}
                    maxVisiblePages={3}
                    showPrevNext={false}
                  />
                </div>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

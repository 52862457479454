import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../../assets/icons/profile/pen.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { TitleBack } from '../../../../components/TitleBack';
import { Card } from '../../../../horizon-components/Card/Card';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { RoundedImage } from '../../../../horizon-components/RoundedImage';
import { Course, CourseStatus } from '../../../../models/Course';
import { useUserContext } from '../../../../providers';
import { getUserData } from '../../../../utils/getUserData';
import './CoursePerfomanceHeader.scss';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';

interface CoursePerfomanceHeaderProps {
  course?: Course | null;
}

export const CoursePerfomanceHeader = ({ course }: CoursePerfomanceHeaderProps) => {
  const { t } = useTranslation(['translation']);
  const { id: courseId } = useParams();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { username } = getUserData(user);
  const [breads, setBreads] = useState<Bread[]>([]);

  useEffect(() => {
    if (course?.id) {
      setBreads([
        { title: 'EDU', url: MainRoutes.edu },
        {
          title: t('course.createCourse'),
          url: MainRoutes.edu + EduRoutes.edit + `/${courseId}/upload-content`,
        },
        {
          title: course.title,
          url: MainRoutes.edu + EduRoutes.performance + `/${courseId}`,
        },
      ]);
    }
  }, [course, t]);

  const goToEdit = () =>
    navigate(MainRoutes.edu + EduRoutes.edit + `/${courseId}/upload-content`);
  const goToPreview = () =>
    navigate(MainRoutes.edu + EduRoutes.preview + `/${courseId}`, {
      state: { isMyCourse: true },
    });

  return (
    <>
      <Breadcrumbs breads={breads} className="hidden md:block" />
      <TitleBack
        title={t('course.Course_Performance')}
        backUrl={MainRoutes.edu + EduRoutes.courses}
        className="md:!hidden"
      />

      <Card className="CoursePerfomanceHeader">
        <div className={'CoursePerfomanceHeader__course'}>
          {!!course?.cover_image && (
            <RoundedImage
              src={course?.cover_image}
              size={window.innerWidth < 992 ? 34 : 46}
            />
          )}
          <div className={'flex flex-col justify-between'}>
            <h3>{course?.title}</h3>
            <span>
              {t('course.By')} {username}
            </span>
          </div>
        </div>

        <div className={'CoursePerfomanceHeader__buttons'}>
          <MButton
            variant="outline"
            color="primary"
            className="text-nowrap"
            onClick={goToPreview}
          >
            {t('course.Preview_mode')}
          </MButton>
          {(course?.course_status === CourseStatus.Draft ||
            course?.course_status === CourseStatus.Rejected) && (
            <MButton variant="highlighted" color="primary" onClick={goToEdit}>
              <span className="hidden md:block">{t('buttons.Edit')}</span>
              <EditIcon className="!block md:!hidden" />
            </MButton>
          )}
        </div>
      </Card>
    </>
  );
};

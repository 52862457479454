/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as CalcIcon } from '@/assets/icons/wiz/calc.svg';
import { MButton } from '@/horizon-components/MButton';
import { Modal } from '@/horizon-components/Modal';
import { ProgressBar } from '@/horizon-components/ProgressBar';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuizEndModal } from '../QuizEndModal';
import './style.scss';

interface QuizTestProps {
  className?: string;
}

export const QuizTest = ({ className }: QuizTestProps) => {
  const { t } = useTranslation(['translation']);
  const [selected, setSelected] = useState(0);
  const [isStartOpen, setIsStartOpen] = useState(false);

  const hndlSelect = (select: number) => {
    setSelected(select);
  };

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  return (
    <div className={classNames('QuizTest', className)}>
      <div className="QuizTest__head">
        <div className="flex flex-col gap-2">
          <p>Категория: Естествознание</p>
          <h1>Question: What is Blockchain?</h1>
        </div>
        <CalcIcon className="fill-primary-purple-blue-500" />
      </div>
      <div className="QuizTest__content">
        <div
          onClick={() => hndlSelect(1)}
          className={classNames({ selected: selected === 1 }, 'error')}
        >
          A type of building made of blocks
        </div>
        <div
          onClick={() => hndlSelect(2)}
          className={classNames({ selected: selected === 2 })}
        >
          A chain of colorful bracelets
        </div>
        <div
          onClick={() => hndlSelect(3)}
          className={classNames({ selected: selected === 3 }, 'success')}
        >
          A digital ledger that records transactions
        </div>
        <div
          onClick={() => hndlSelect(4)}
          className={classNames({ selected: selected === 4 })}
        >
          60 ER A popular game played with blocks
        </div>
      </div>

      <div className="QuizTest__foot">
        <div className="QuizTest__bar">
          <div className="QuizTest__sec">30</div>{' '}
          <ProgressBar size="md" rating={30} />
        </div>
        <MButton
          variant="highlighted"
          color="primary"
          className="md:!px-8 md:!py-6 md:!text-lg"
          onClick={hndlOpenStartModal}
        >
          Подтвердить
        </MButton>
      </div>
      <Modal isOpen={isStartOpen} onClose={hndlOpenStartModal} showClose={false}>
        <QuizEndModal onStart={hndlOpenStartModal} />
      </Modal>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { AuctionCard } from '../AuctionCard/AuctionCard';
import { useTranslation } from 'react-i18next';
import { Auction } from '../../../models/Auction';
import { getAuctions } from '../../../api/bid';
import { Loader } from '../../../components/Loader';

export const Winnings: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [isLoading, setIsLoading] = useState(false);
  const [auctions, setAuctions] = useState<Auction[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getAuctions('completed')
      .then((data) => {
        setAuctions(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={'px-[8px] lg:px-0'}>
      <h1 className={'g-main-title mb-[16px] lg:mb-[24px]'}>{t('menu.Winnings')}</h1>
      {isLoading ? (
        <Loader />
      ) : (
        auctions.length && (
          <div className={'flex flex-row flex-wrap gap-[16px]'}>
            {auctions.map((item) => (
              <AuctionCard key={item.id} data={item} />
            ))}
          </div>
        )
      )}
    </div>
  );
};

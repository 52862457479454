/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FireIcon } from '@/assets/icons/wiz/fire.svg';
import { ReactComponent as ThunderIcon } from '@/assets/icons/wiz/thunder.svg';
import { ReactComponent as StopIcon } from '@/assets/icons/wiz/stop.svg';
import { MButton } from '@/horizon-components/MButton';
import './style.scss';

interface QuizHeaderProps {
  className?: string;
}

export const QuizHeader = ({ className }: QuizHeaderProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('QuizHeader', className)}>
      <div className="QuizHeader__item">
        <MButton
          variant="highlighted"
          color="primary"
          size="xs"
          className="!rounded-full"
        >
          7 <span className="sm-max:hidden">days</span> <FireIcon />
        </MButton>
        <MButton
          variant="secondary"
          color="primary"
          size="xs"
          className="!rounded-full"
        >
          3 <ThunderIcon />
        </MButton>
        1:59:59
      </div>
      <div className="QuizHeader__item justify-end">
        <MButton
          variant="highlighted"
          color="primary"
          size="xs"
          className="!rounded-full"
        >
          200 <StopIcon />
        </MButton>
      </div>
    </div>
  );
};

import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/share/duplicate.svg';
import EmailIcon from '../../../../assets/icons/share/email.svg';
import TelegramIcon from '../../../../assets/icons/share/telegram.svg';
import TwitterIcon from '../../../../assets/icons/share/twitter.svg';
import WhatsappIcon from '../../../../assets/icons/share/whatsapp.svg';
import { Loader } from '../../../../components/Loader';
import './ShareButtons.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  isReadyToShare: boolean;
  imageShareUrl: string;
};

export const ShareButtons: React.FC<Props> = ({ isReadyToShare, imageShareUrl }) => {
  const { t } = useTranslation(['translation']);
  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(imageShareUrl);
    toast('The link has been copied', {
      type: 'success',
    });
  };
  return (
    <div className="ShareButtons">
      {!isReadyToShare ? (
        <Loader className="mt-5" />
      ) : (
        <>
          <div className="ShareButtons__wrapper">
            <div className="ShareButtons__button">
              <TelegramShareButton
                className="ShareButtons__icon"
                url={imageShareUrl}
                disabled={!isReadyToShare}
              >
                <img src={TelegramIcon} alt="whatsapp" />
              </TelegramShareButton>
              <p>Telegram</p>
            </div>
            <div className="ShareButtons__button">
              <WhatsappShareButton
                className="ShareButtons__icon"
                url={imageShareUrl}
                disabled={!isReadyToShare}
              >
                <img src={WhatsappIcon} alt="whatsapp" />
              </WhatsappShareButton>
              <p>Whatsapp</p>
            </div>
            <div className="ShareButtons__button">
              <TwitterShareButton
                className="ShareButtons__icon"
                url={imageShareUrl}
                disabled={!isReadyToShare}
              >
                <img src={TwitterIcon} alt="whatsapp" />
              </TwitterShareButton>
              <p>Twitter</p>
            </div>
            <div className="ShareButtons__button">
              <EmailShareButton
                className="ShareButtons__icon"
                url={imageShareUrl}
                disabled={!isReadyToShare}
              >
                <img src={EmailIcon} alt="whatsapp" />
              </EmailShareButton>
              <p>E-mail</p>
            </div>
          </div>
          <div>
            <span>{t('wiz.Or_share_with_link')}</span>
            <div className="ShareButtons__copy py-2 md:py-[14px]">
              <span>{imageShareUrl}</span>
              <DuplicateIcon onClick={copyTextToClipboard} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChaineIcon } from '../../../../../assets/icons/science/chaine.svg';
import { ReactComponent as ListIcon } from '../../../../../assets/icons/science/list.svg';
import { ReactComponent as PictureIcon } from '../../../../../assets/icons/science/picture.svg';
import { MButton } from '../../../../../horizon-components/MButton';
import { ScienceCard } from '../../../../../widgets/ScienceCard';
import { ScienceSecondaryCard } from '../../../../../widgets/ScienceSecondaryCard';
import './Science.scss';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TMainContent } from '../../../../../models/Main';

interface ScienceProps {
  className?: string;
  data: TMainContent;
}

export const Science = ({ className, data }: ScienceProps) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const goTo = () => navigate(MainRoutes.weber + WeberRoutes.feeds);

  const scienceData = useMemo(
    () => [
      {
        name: data.science_share_title,
        icon: ChaineIcon,
        description: data.science_share_description,
      },
      {
        name: data.science_create_title,
        icon: PictureIcon,
        description: data.science_create_description,
      },
      {
        name: data.science_research_title,
        icon: ListIcon,
        description: data.science_research_description,
      },
    ],
    [t, data]
  );

  return (
    <div className={classNames('Science', className)}>
      <div className="Science__main">
        <h3>{t('main.science.title')}</h3>
        <p>{data.science}</p>
      </div>
      <div className="Science__content">
        {scienceData.map((course, idx) => (
          <ScienceCard
            title={course.name}
            description={course.description}
            key={idx}
          >
            <course.icon />
          </ScienceCard>
        ))}
        <MButton
          variant="highlighted"
          color="primary"
          className="!text-lg md:!text-2xl"
          onClick={goTo}
        >
          {t('main.science.in_WEBER')}
        </MButton>
      </div>
      <ScienceSecondaryCard
        title={data.community_title}
        description={data.community}
        link={MainRoutes.weber + WeberRoutes.communities}
        btnText={t('main.science.find_your_community')}
      />
      <ScienceSecondaryCard
        title={data.science_dao_title}
        description={data.science_dao}
        link={MainRoutes.weber + WeberRoutes.communities}
        btnText={t('main.science.become_part_of_DAO')}
      />
    </div>
  );
};

import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { FeedRequest } from '../../../../api/weber/feed';
import { Loader } from '../../../../components/Loader';
import { useCommunities } from '../../../../entities/Communities';
import { usePublicUsers } from '../../../../entities/User/model/query/usePublicUsers/usePublicUsers';
import useHistoryParams from '../../../../hooks/useHistoryParams';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { TCommunity } from '../../../../models/Community';
import { FeedStatus, FeedType } from '../../../../models/Feed';
import { AsideCard } from '../../../../widgets/AsideCard';
import { AsideMenu } from '../../../../widgets/AsideMenu';
import { AsideMenuItem } from '../../../../widgets/AsideMenu/ui/AsideMenu';
import { AvatarTitleTextCard } from '../../../../widgets/AvatarTitleTextCard';
import { FeedButtons } from './components/FeedButtons';
import { FeedFilter } from './components/FeedFilter';
import { Feeds } from './components/Feeds';
import { Folders } from './components/Folders';
import './FeedList.scss';

type Props = {
  className?: string;
  communityFeed?: TCommunity;
};

export const FeedList: React.FC<Props> = ({ className = '', communityFeed }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { t } = useTranslation(['translation']);
  const [filters, setFilters] = useState<FeedRequest>({});
  const [status, setStatus] = useState<FeedStatus>('published');
  const [type, setType] = useState<FeedType>('posts');
  const [selectedMode, setSelectedMode] = useState<boolean>(false);
  const {
    data: communities,
    isLoading: isCommunitiesLoading,
    fetchData,
  } = useCommunities();
  const {
    data: publicUsers,
    isLoading: isPublicUsersLoading,
    fetchData: fetchDataUsers,
  } = usePublicUsers('');

  const { addParamToUrl, removeParamFromUrl } = useHistoryParams();

  useEffect(() => {
    if (searchParams.get('feed_type')) {
      setType(searchParams.get('feed_type') as FeedType);
      removeParamFromUrl('following');
    }

    if (searchParams.get('following')) {
      setType('following' as FeedType);
      removeParamFromUrl('feed_type');
    }

    if (searchParams.get('from_me')) {
      setSelectedMode(JSON.parse(searchParams.get('from_me') || ''));
      if (JSON.parse(searchParams.get('from_me') || '')) {
        setStatus((searchParams.get('status') as FeedStatus) || 'published');
        addParamToUrl(
          'status',
          (searchParams.get('status') as FeedStatus) || 'published'
        );
        removeParamFromUrl('feed_type');
        removeParamFromUrl('following');
      } else {
        setType((searchParams.get('feed_type') as FeedType) || 'posts');
        addParamToUrl(
          'feed_type',
          (searchParams.get('feed_type') as FeedType) || 'posts'
        );
        removeParamFromUrl('status');
      }
    }
  }, [t]);

  useEffect(() => {
    fetchData({});
    fetchDataUsers();
  }, [t]);

  const handleMode = (mode: boolean) => {
    setSelectedMode(mode);
    setStatus('published');
    setType('posts');
    addParamToUrl('from_me', `${mode}`);
    addParamToUrl('status', 'published');
    addParamToUrl('feed_type', 'posts');
    const newFilters: FeedRequest = {
      ...filters,
      from_me: mode,
      feed_type: 'posts',
      status: 'published',
    };
    if (newFilters.from_me) {
      delete newFilters.following;
      delete newFilters.feed_type;
      removeParamFromUrl('feed_type');
      removeParamFromUrl('following');
    } else {
      delete newFilters.status;
      removeParamFromUrl('status');
    }
    setFilters(newFilters);
  };

  const handleFilter = (filter: FeedRequest) => {
    setFilters({
      ...filters,
      ...filter,
      page: 1,
    });
  };

  const handleSearch = (search: FeedRequest) => {
    if (!search.title) {
      delete filters.title;
      setFilters({ ...filters, page: 1 });
      removeParamFromUrl('title');
    } else {
      setFilters({ ...filters, ...search, page: 1 });
      addParamToUrl('title', search.title);
    }
  };

  const handleStatus = (mode: string) => {
    if (mode === 'favorites') {
    } else {
      setFilters({ ...filters, status: mode as FeedStatus, page: 1 });
    }
    setStatus(mode as FeedStatus);
    addParamToUrl('status', mode);
  };

  const handleType = (mode: string) => {
    setType(mode as FeedType);
    if (mode === 'following') {
      delete filters.feed_type;
      setFilters({ ...filters, following: true, page: 1 });
      addParamToUrl('following', true);
      removeParamFromUrl('feed_type');
    } else {
      delete filters.following;
      setFilters({ ...filters, feed_type: mode as FeedType, page: 1 });
      addParamToUrl('feed_type', mode);
      removeParamFromUrl('following');
    }
  };

  const asideMenuStatus: AsideMenuItem[] = useMemo(
    () => [
      { id: 'published', title: t('feed.published') },
      { id: 'draft', title: t('feed.draft') },
      { id: 'favorites', title: t('feed.favorites') },
    ],
    [t]
  );

  const asideMenuType: AsideMenuItem[] = useMemo(
    () => [
      { id: 'posts', title: t('feed.posts') },
      { id: 'articles', title: t('feed.articles') },
      { id: 'following', title: t('feed.following') },
    ],
    [t]
  );

  return (
    <div className={classNames('FeedList', className)}>
      <FeedButtons
        communityFeed={communityFeed}
        title={t('feed.title')}
        onMode={handleMode}
        isMy={selectedMode}
      />
      <div className="FeedList__content">
        <div className="FeedList__list">
          {status !== 'favorites' && (
            <FeedFilter
              onFilter={handleFilter}
              onSearch={handleSearch}
              isMy={selectedMode}
            />
          )}
          {status === 'favorites' ? <Folders /> : <Feeds filters={filters} />}
        </div>
        <div className="FeedList__aside">
          <AsideMenu
            menu={selectedMode ? asideMenuStatus : asideMenuType}
            onClick={selectedMode ? handleStatus : handleType}
            active={selectedMode ? status : type}
          />

          {isCommunitiesLoading ? (
            <Loader />
          ) : (
            <AsideCard
              title={t('feed.add_to_your_feed')}
              url={MainRoutes.weber + WeberRoutes.communities}
            >
              {communities?.results?.slice(0, 5)?.map((community) => (
                <Link
                  to={MainRoutes.weber + WeberRoutes.community + `/${community?.id}`}
                  key={community.id}
                >
                  <AvatarTitleTextCard
                    title={community.name}
                    text={[
                      community.subject.charAt(0).toUpperCase() +
                        community.subject.slice(1),
                      community.members_count + ' ' + t('members'),
                    ]}
                    img={community.custom_image || community.predefined_image.image}
                  />
                </Link>
              ))}
            </AsideCard>
          )}

          {isPublicUsersLoading ? (
            <Loader />
          ) : (
            <AsideCard
              title={t('feed.people_you_may_know')}
              url={MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking}
            >
              {publicUsers?.results?.slice(0, 4)?.map((user) => (
                <Link
                  to={
                    MainRoutes.auth +
                    AuthRoutes.profile +
                    ProfileRoutes.user +
                    `/${user?.user.guid}`
                  }
                  key={user.user.guid}
                >
                  <AvatarTitleTextCard
                    title={user.user.user_meta.username}
                    text={[
                      `${
                        user?.user?.user_meta?.first_name !== null
                          ? user?.user?.user_meta?.first_name
                          : ''
                      }${
                        user?.user?.user_meta?.first_name !== null &&
                        user?.user?.user_meta?.last_name !== null
                          ? ' '
                          : ''
                      }${
                        user?.user?.user_meta?.last_name !== null
                          ? user?.user?.user_meta?.last_name
                          : ''
                      }`,
                    ]}
                    img={user?.user?.user_meta?.profile_image || undefined}
                  />
                </Link>
              ))}
            </AsideCard>
          )}
        </div>
      </div>
    </div>
  );
};

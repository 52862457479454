import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  getDigitalPassCategoryById,
  subscribeFirstLevel,
} from '../../../api/digitalPass';
import { saveCurrentUrl } from '../../../api/sessions';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { TitleBack } from '../../../components/TitleBack';
import { MButton } from '../../../horizon-components/MButton';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { DigitalPassCategoryDetail } from '../../../models/DigitalPass';
import { useUserContext } from '../../../providers';
import { SchoolCard } from '../../../widgets/SchoolCard';
import { IncludedCourses } from './IncludedCourses';
import './styles.scss';

export const NFTView: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const [queryParams] = useSearchParams();
  const [digitalPass, setDigitalPass] = useState<DigitalPassCategoryDetail | null>(
    null
  );
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<
    {
      id: number;
      title: string;
      price: number;
      selected: boolean;
      purchased: boolean;
    }[]
  >([]);
  const [breads, setBreads] = useState<Bread[]>([]);

  useEffect(() => {
    if (digitalPass?.id) {
      setBreads([
        { title: 'EDU', url: MainRoutes.edu },
        {
          title: t('menu.DigitalPass'),
          url: MainRoutes.edu + EduRoutes['digital-passes'],
        },
        {
          title: digitalPass?.subject?.title,
          url: MainRoutes.edu + EduRoutes.nft + `/${id}`,
        },
      ]);
    }
  }, [digitalPass, t]);

  const loadDigitalPassCategory = useCallback(() => {
    return getDigitalPassCategoryById(Number(id || '0')).then((data) => {
      setSelected(
        data?.digital_passes?.map((item) => {
          return {
            id: item.id,
            title: item.name,
            price: Number(item.price),
            selected: !item.purchase_id,
            purchased: !!item.purchase_id,
          };
        })
      );
      setDigitalPass(data);
    });
  }, [id, queryParams]);

  useEffect(() => {
    loadDigitalPassCategory();
  }, [loadDigitalPassCategory, queryParams, t]);

  const handleSelectDP = (digitalPassId: number) => {
    setSelected(
      selected.map((selected) => {
        return {
          ...selected,
          selected:
            selected.id === digitalPassId ? !selected.selected : selected.selected,
        };
      })
    );
  };

  const price = useMemo(
    () =>
      selected
        .filter((item) => item.selected)
        .reduce((sum, item) => sum + item.price, 0) || 0,
    [selected]
  );

  const handleBuy = () => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    subscribeFirstLevel(
      selected.filter((item) => item.selected).map((item) => item.id)
    )
      .then(() => loadDigitalPassCategory())
      .catch(() => loadDigitalPassCategory());
  };

  return (
    <>
      <Breadcrumbs breads={breads} className="sm-max:hidden" />
      <TitleBack title={digitalPass?.subject?.title} className="md:!hidden" />
      <div className={'NFTView'}>
        <img className={'NFTView__img'} src={digitalPass?.image_medium} alt={''} />
        <div className="NFTView__school">
          <h1 className="sm-max:hidden">{digitalPass?.subject?.title}</h1>
          {digitalPass?.digital_passes?.map((item, idx) => (
            <SchoolCard
              key={item.id}
              title={item.name}
              description={item.description}
              variant={`${item.level} ${t('course.Grade')}, ${item.units_count} ${t(
                'course.units'
              )}, ${item.course_count} ${t('course.Courses')}`}
              onClick={() => handleSelectDP(item.id)}
              selected={
                selected.findIndex(
                  (selected) => selected.id === item.id && selected.selected
                ) > -1
              }
              purchased={!!item.purchase_id}
              link={
                MainRoutes.edu +
                EduRoutes.nft +
                `/${id}` +
                EduRoutes.shcool +
                `/?school=${item.id}`
              }
              first={idx === 0}
            />
          ))}
          {(digitalPass?.digital_passes?.length || 0) >
            ((digitalPass?.digital_passes?.length &&
              digitalPass?.digital_passes?.filter((item) => !!item.purchase_id)
                ?.length) ||
              0) && (
            <div className="NFTView__purchase">
              <div>
                <h4>{t('nft.purchase')}</h4>
                {selected.map((item) => (
                  <p
                    className={`flex w-full justify-between pr-6 ${
                      item.selected && 'text-black-10'
                    }`}
                    key={item.id}
                  >
                    {item.title}{' '}
                    {!item.purchased && (
                      <span
                        className={`${
                          item.selected && '!text-primary-purple-blue-500'
                        }`}
                      >
                        ${item.price}
                      </span>
                    )}
                  </p>
                ))}
              </div>
              <div>
                <MButton
                  variant={
                    selected.findIndex((selected) => selected.selected) > -1
                      ? 'highlighted'
                      : 'secondary'
                  }
                  color="primary"
                  className="w-full"
                  disabled={
                    !(selected.findIndex((selected) => selected.selected) > -1)
                  }
                  onClick={handleBuy}
                >
                  {t('nft.buy_for')} ${price}
                </MButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="NFTView__list">
        <IncludedCourses />
      </div>
    </>
  );
};

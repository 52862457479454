import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDigitalPassCategoriesByCourse } from '../../../../api/digitalPass';
import { DigitalPassCategory } from '../../../../models/DigitalPass';
import { DEFAULT_LIMIT } from '../../../../shared/lib/const/limit';
import { ResponsePagination } from '../../../../models/Response';

export const useDPCategoriesByCourse = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ResponsePagination<DigitalPassCategory>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setPage] = useState(1);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(
    (course: number, ordering?: string, page?: number) => {
      setIsLoading(true);
      getDigitalPassCategoriesByCourse(course, ordering, page)
        .then((res) => {
          page && setPage(page);
          setData(res);
          res !== undefined && setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    },
    [t]
  );

  return {
    data,
    currentPage,
    total,
    isLoading,
    setPage,
    fetchData,
  };
};

import { Role, User, UserMeta, UserProfile, UserSocialMeta } from '../models';
import instance, { AUTH_API_URL, WEBER_API_URL } from './instance';
import { CommonResponse } from './utils';
import { TeacherProfile } from '../models/Teacher';
import { InstitutionProfile } from '../models/Institution';
import { ResponsePagination } from '../models/Response';
import { UserFriendship } from '../models/Friendship';
import { TAgree, TMainContent, TPossibilities, TSocial } from '../models/Main';
import { TNotifications } from '../models/Notifications';

export interface SignInResponse extends CommonResponse {
  access: string;
  refresh: string;
}

export type TempUserData = {
  email: string;
  user_type: Role;
};

export function createJWT(email: string, password: string): Promise<SignInResponse> {
  return instance()
    .post(`${AUTH_API_URL}/auth/jwt/create/`, {
      email,
      password,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function refreshToken(refresh: string): Promise<SignInResponse> {
  return instance(false)
    .post(`${AUTH_API_URL}/auth/jwt/refresh/`, {
      refresh,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function forgotPassword(email: string): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/auth/reset_password/`, {
      email,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function authMe(): Promise<UserProfile> {
  return instance()
    .get(`${AUTH_API_URL}/auth/users/me/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function publicUsers(
  search: string
): Promise<ResponsePagination<UserFriendship>> {
  return instance()
    .get(`${WEBER_API_URL}/find-connections/`, {
      params: { search },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function signUp(email: string, password: string): Promise<TempUserData> {
  return instance()
    .post(`${AUTH_API_URL}/auth/register/`, {
      email,
      password,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function checkCode(
  email: string,
  confirmation_code: string
): Promise<{ token: string }> {
  return instance()
    .post(`${AUTH_API_URL}/auth/confirm-email/`, {
      email,
      confirmation_code,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function checkCodeResetPassword(
  email: string,
  confirmation_code: string
): Promise<{ token: string }> {
  return instance()
    .post(`${AUTH_API_URL}/auth/reset_password_check_code/`, {
      email,
      confirmation_code,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function resetPasswordConfirm(
  email: string,
  confirmation_code: string,
  password: string
): Promise<{ token: string }> {
  return instance()
    .post(`${AUTH_API_URL}/auth/reset_password_confirm/`, {
      email,
      confirmation_code,
      password,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getTeacherProfile(): Promise<TeacherProfile> {
  return instance()
    .get(`/profile/teacher/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setInstitutionBasicInfo(
  institution_name: string,
  phone_number: string,
  address: string,
  country: string,
  city: string,
  website_url: string
): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/institution-meta/step_1/`, {
      name: institution_name,
      phone_number,
      address,
      country,
      city,
      website: website_url,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setInstitutionApplicationInfo(
  institution_type: string,
  area_of_expertise: string[],
  applying_full_name: string,
  applying_email: string,
  applying_position: string,
  applying_phone_number: string
): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/institution-meta/step_2/`, {
      applier_full_name: applying_full_name,
      applier_email: applying_email,
      applier_position: applying_position,
      applier_phone_number: applying_phone_number,
      areas_of_expertise: area_of_expertise,
      type: institution_type,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setInstitutionApplicationInfoNew(form: FormData): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/institution-meta/step_2/`, form)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function institutionAttachAcFile(file: File): Promise<void> {
  const formData = new FormData();
  formData.set('file', file, file.name);

  return instance()
    .post(`${AUTH_API_URL}/institution-ac-file/`, formData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setSocialMedia(social_links: {
  about: string | null;
  linked_in: string | null;
  instagram: string | null;
}): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/social-meta/`, {
      ...social_links,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setSocialMeta(
  page?: number,
  user__guid?: string,
  user__email?: string,
  id?: string
): Promise<ResponsePagination<UserSocialMeta>> {
  return instance()
    .get(`${AUTH_API_URL}/social-meta/`, {
      params: {
        id,
        user__guid,
        user__email,
        page,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function editUserLogo(
  role: 'teacher' | 'institution' | 'student',
  file?: File
): Promise<void> {
  const body = new FormData();
  if (file) {
    if (role === 'institution') {
      body.append('logo', file);
    } else {
      body.append('profile_image', file);
    }
  }
  return instance()
    .post(
      `${AUTH_API_URL}/auth/`,
      !!file
        ? body
        : role === 'institution'
        ? { logo: null }
        : { profile_image: null }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getGoogleOAuthUrl(): Promise<string> {
  return instance()
    .get<{ auth_url: string }>(`${AUTH_API_URL}/google/url/`)
    .then((response) => response?.data?.auth_url)
    .catch((error) => {
      throw error;
    });
}

export function authorizeViaGoogle(
  code: string,
  redirectUri: string
): Promise<{
  refresh: string;
  access: string;
}> {
  return instance()
    .get(`${AUTH_API_URL}/google/callback/`, {
      params: { code, redirect_uri: redirectUri },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeInstitutionMeta(
  id: string,
  body: {
    applier_full_name: string;
    phone_number: string;
    address: string;
    country: string;
    city: string;
    website: string;
    logo?: File;
  }
): Promise<UserProfile['institution_meta']> {
  const _body = new FormData();
  if (body.logo) {
    _body.append('logo', body.logo);
  }
  _body.append('applier_full_name', body.applier_full_name);
  _body.append('phone_number', body.phone_number);
  _body.append('address', body.address);
  _body.append('country', body.country);
  _body.append('city', body.city);
  _body.append('website', body.website);
  return instance()
    .patch(`${AUTH_API_URL}/institution-meta/${id}/`, _body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeUserMeta(
  id: string,
  body: {
    username: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: string;
    education: string;
    major: string;
    degree: string;
    position: string;
    profile_image?: File;
  }
): Promise<UserProfile['user_meta']> {
  const _body = new FormData();

  // Append the profile image if present
  if (body.profile_image) {
    _body.append('profile_image', body.profile_image);
  }

  // Append other form fields
  _body.append('username', body.username);
  _body.append('first_name', body.firstName);
  _body.append('last_name', body.lastName);
  _body.append('phone_number', body.phoneNumber);
  _body.append('address', body.address);
  _body.append('education', body.education);
  _body.append('major', body.major);
  _body.append('degree', body.degree);
  _body.append('position', body.position);

  // Make the patch request
  return instance()
    .patch(`${AUTH_API_URL}/user-meta/${id}/`, _body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeUserMetaAvatar(
  id: string,
  body: {
    profile_image?: File;
    cover_image?: File;
  }
): Promise<UserProfile['user_meta']> {
  const _body = new FormData();

  // Append the profile image if present
  if (body.profile_image) {
    _body.append('profile_image', body.profile_image);
  }
  if (body.cover_image) {
    _body.append('cover_image', body.cover_image);
  }

  // Make the patch request
  return instance()
    .patch(`${AUTH_API_URL}/user-meta/${id}/`, _body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changePassword(body: {
  email: string;
  old_password: string;
  new_password: string;
  confirm_password: string;
}): Promise<void> {
  return instance()
    .post(`${AUTH_API_URL}/auth/change-email-password/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserMeta(id: string): Promise<UserMeta> {
  return instance()
    .get(`${AUTH_API_URL}/user-meta/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// Main Page
export function getMainContent(): Promise<TMainContent> {
  return instance()
    .get(`${AUTH_API_URL}/main_page/maincontent`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMainPossibilities(): Promise<TPossibilities[]> {
  return instance()
    .get(`${AUTH_API_URL}/main_page/possibilities`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMainEarnMoney(): Promise<TPossibilities[]> {
  return instance()
    .get(`${AUTH_API_URL}/main_page/earn-money-possibilities`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMainContacts(): Promise<TSocial[]> {
  return instance()
    .get(`${AUTH_API_URL}/main_page/contacts`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
// Main Page

export function getDocsAgree(): Promise<ResponsePagination<TAgree>> {
  return instance()
    .get(`${AUTH_API_URL}/user-agreement/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// Notifications
export function getNotifications(
  page?: number
): Promise<ResponsePagination<TNotifications>> {
  return instance()
    .get(`${AUTH_API_URL}/notifications/`, { params: { page } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

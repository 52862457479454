import React, { useRef, useState } from 'react';
import { LANG_KEY, Languages, LANGUAGES } from '../../i18n';
import { Dropdown, DropdownRefs } from '../../horizon-components/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';

export const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const langRef = useRef<DropdownRefs>(null);
  const [activeLang, setActiveLang] = useState<Languages>(
    i18n.language as Languages
  );

  const setLang = (lang: Languages) => {
    localStorage.setItem(LANG_KEY, lang);
    setActiveLang(lang);
    i18n.changeLanguage(lang);
    langRef.current?.close();
  };

  return (
    <Dropdown
      ref={langRef}
      button={
        <p className={'mb-0 cursor-pointer text-sm !font-medium md:text-[16px]'}>
          {LANGUAGES[activeLang]}
        </p>
      }
      children={
        <div className="gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500">
          {Object.keys(LANGUAGES).map((key) => (
            <p
              key={key}
              className={'cursor-pointer text-sm !font-medium md:text-[16px]'}
              onClick={() => setLang(key as Languages)}
            >
              {LANGUAGES[key as Languages]}
            </p>
          ))}
        </div>
      }
    />
  );
};

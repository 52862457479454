import { useCallback, useEffect, useState } from 'react';
import { setCourseReviewReaction } from '../../../../../api/courseReview';

export const useCommentLike = (
  reviewId: number,
  userReaction: boolean,
  likesCount: number
) => {
  const [likes, setLikes] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onLike = useCallback(() => {
    setIsLoading(true);
    setCourseReviewReaction(reviewId)
      .then(() => {
        setLikes(!isLiked ? likes + 1 : likes - 1);
        setIsLiked(!isLiked);
      })
      .finally(() => setIsLoading(false));
  }, [isLiked, likes, reviewId]);

  useEffect(() => {
    setIsLiked(userReaction);
    setLikes(likesCount);
  }, [likesCount, userReaction]);

  return {
    onLike,
    isLoading,
    isLiked,
    likes,
  };
};

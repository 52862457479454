import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  getDigitalPass,
  getDigitalPassCategoryById,
  getLevelUnits,
  getUserGPA,
} from '../../../../api/digitalPass';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.primary.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/nft/arrow-left.svg';
import { ReactComponent as ArrowLinkIcon } from '../../../../assets/icons/nft/arrow-right.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/nft/check.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { TitleBack } from '../../../../components/TitleBack';
import { MButton } from '../../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import {
  DigitalPassCategoryDetail,
  DigitalPassSchool,
  UnitItem,
} from '../../../../models/DigitalPass';
import { GradeCard } from '../../../../widgets/GradeCard';
import { PurchaseSchoolCard } from '../../../../widgets/PurchaseSchoolCard';
import { SchoolCourses } from './SchoolCourses';
import { Loader } from '../../../../components/Loader';
import useHistoryParams from '../../../../hooks/useHistoryParams';
import './NFTViewSchool.scss';
import { NotFound } from '../../../../components/NotFound/NotFound';

interface NFTViewSchoolProps {
  className?: string;
}

export const NFTViewSchool = ({ className }: NFTViewSchoolProps) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const [selectedDP, setSelectedDP] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState<number[]>([]);
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnitsLoading, setIsUnitsLoading] = useState(false);
  const [gpa, setGpa] = useState<number>();
  const [schools, setSchools] = useState<DigitalPassSchool[]>([]);
  const [units, setUnits] = useState<UnitItem[]>([]);
  const [digitalPass, setDigitalPass] = useState<DigitalPassCategoryDetail | null>(
    null
  );
  const { addParamToUrl, getParamFromUrl } = useHistoryParams();
  const school = getParamFromUrl('school');

  const unitTitle = useMemo(() => {
    return units?.find((unit) => unit?.id === selectedUnit?.[0])?.title;
  }, [selectedUnit, t]);

  const schoolTitle = useMemo(() => {
    return schools?.find((school) => school?.id === selectedDP)?.name;
  }, [selectedDP, schools, t]);

  const breads: Bread[] = useMemo(
    () => [
      { title: 'EDU', url: MainRoutes.edu },
      {
        title: t('menu.DigitalPass'),
        url: MainRoutes.edu + EduRoutes['digital-passes'],
      },
      {
        title: digitalPass?.subject?.title || '',
        url: MainRoutes.edu + EduRoutes.nft + `/${digitalPass?.id}`,
      },
      {
        title:
          schoolTitle ||
          schools?.find((school) => school?.id === selectedDP)?.name ||
          '',
        url:
          MainRoutes.edu + EduRoutes.nft + `/${digitalPass?.id}/?school=${school}`,
      },
    ],
    [t, digitalPass, selectedDP, school, schoolTitle]
  );

  useEffect(() => {
    getDigitalPassCategoryById(Number(id || '0')).then(setDigitalPass);
  }, [id, t]);

  useEffect(() => {
    setIsLoading(true);
    getUserGPA().then((res) => res?.gpa && setGpa(res?.gpa));
    getDigitalPass(Number(id))
      .then((res) => res?.results?.length && setSchools(res?.results))
      .finally(() => setIsLoading(false));
  }, [t]);

  useEffect(() => {
    if (school && schools.length) {
      const levels = schools?.find((item) => item.id === Number(school));
      const active_level =
        levels?.subject_levels?.length && levels?.subject_levels?.[0]?.id;
      active_level && handleActiveLevel(active_level);
    }
  }, [school, schools]);

  useEffect(() => {
    school && handleOpenDP(Number(school));
  }, [school]);

  const handleOpenDP = (id: number) => {
    setSelectedDP(id);
    addParamToUrl('school', id);
  };

  const handleActiveLevel = (subject_level: number) => {
    setUnits([]);
    setSelectedLevel(subject_level);
    setIsUnitsLoading(true);
    getLevelUnits(selectedDP, Number(id), subject_level)
      .then((res) => res && res?.length && setUnits(res))
      .finally(() => setIsUnitsLoading(false));

    setIsShow(false);
    setSelectedUnit([]);
  };

  const handleShowUnits = () => {
    setIsShow(!isShow);
    setSelectedUnit([]);
  };

  const handleUnit = (id: number) => {
    setIsShow(!isShow);
    setSelectedUnit([id]);
  };

  const renderUnits = (className?: string) => (
    <div
      className={`NFTViewSchool__units ${className}`}
      style={{ order: selectedLevel }}
    >
      {isUnitsLoading || isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center gap-2.5 sm-max:px-3">
            {isShow && (
              <MButton
                variant="highlighted"
                color="white"
                size="xs"
                className="!pl-2.5 shadow-md shadow-shadow-700"
                onClick={handleShowUnits}
              >
                <ArrowLeftIcon /> {t('nft.units')}
              </MButton>
            )}
            <h4>
              {unitTitle ? (
                unitTitle
              ) : (
                <>
                  {units?.length} {t('nft.units')}
                </>
              )}
              {!!units?.length && !isShow && (
                <span onClick={handleShowUnits}>
                  {t('nft.click_to_see_all_courses')}
                </span>
              )}
            </h4>
          </div>
          {!isShow ? (
            !!units.length ? (
              <div className="w-full">
                {units?.map((unit) => (
                  <div
                    className="NFTViewSchool__item"
                    onClick={() => handleUnit(unit.id)}
                    key={unit.id}
                  >
                    {!!unit?.is_completed && (
                      <CheckIcon className="fill-primary-green-500" />
                    )}
                    <h5>{unit.title}</h5>
                    <ArrowRightIcon />
                  </div>
                ))}
              </div>
            ) : (
              <NotFound text={t('course.Coming_soon')} />
            )
          ) : (
            <div className="w-full sm-max:p-3">
              <SchoolCourses
                selectedDPId={selectedDP}
                selectedLevel={selectedLevel}
                selectedUnits={selectedUnit}
              />
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className={classNames('NFTViewSchool', className)}>
      <Breadcrumbs breads={breads} className="sm-max:hidden" />
      <TitleBack title={schoolTitle} className="md:!hidden" />
      <div className="NFTViewSchool__wrapper">
        <div className="NFTViewSchool__left">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="flex items-center justify-between">
                <div className="flex flex-col items-start gap-2">
                  <h3>{t('nft.current_GPA')}</h3>
                  <Link to={MainRoutes.edu + EduRoutes.progress + `/${id}`}>
                    {t('nft.view_learning_progress')} <ArrowLinkIcon />
                  </Link>
                </div>
                <span
                  className={classNames('NFTViewSchool__gpa', {
                    good: gpa && parseFloat(gpa.toFixed(1)) > 4,
                    normal:
                      gpa &&
                      parseFloat(gpa.toFixed(1)) > 3 &&
                      parseFloat(gpa.toFixed(1)) < 4,
                    bad: gpa && parseFloat(gpa.toFixed(1)) < 3,
                  })}
                >
                  {gpa ? parseFloat(gpa.toFixed(1)) : 0.0}
                </span>
              </div>
              {schools?.map((school) => (
                <PurchaseSchoolCard
                  key={school.id}
                  title={school.name}
                  isOpen={school.id === selectedDP}
                  onOpen={() => handleOpenDP(school.id)}
                  isEnrolled={!!school.purchase_id}
                >
                  <>
                    {school?.subject_levels?.map((grade) => (
                      <GradeCard
                        key={grade.id}
                        title={grade.title}
                        description={grade.description}
                        units={grade.units_count}
                        completed={grade.completed_units_count}
                        isActive={grade.id === selectedLevel}
                        onActive={() => handleActiveLevel(grade.id)}
                        isEnrolled={grade.is_completed}
                        className={`order-${grade.id}`}
                        style={{ order: grade.id }}
                      />
                    ))}
                    {window.innerWidth <= 768 && renderUnits('lg:!hidden')}
                  </>
                </PurchaseSchoolCard>
              ))}
            </>
          )}
        </div>
        {window.innerWidth > 768 && renderUnits('md-max:!hidden')}
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../horizon-components/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import { ProgressBar } from '../../../horizon-components/ProgressBar';
import { useNavigate } from 'react-router-dom';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import './DOIModal.scss';
import { Feed } from '../../../models/Feed';

interface DOIModalProps {
  className?: string;
  count?: number;
  isLoading?: boolean;
  onSave?: (title: string) => void;
  onCancel?: () => void;
  feed?: Feed | null;
}

export const DOIModal = ({
  className,
  isLoading = false,
  onSave,
  onCancel,
  count,
  feed,
}: DOIModalProps) => {
  const { t } = useTranslation(['translation']);
  const [urlDOI, setUrlDOI] = useState('');
  const navigate = useNavigate();

  const goTo = () =>
    feed?.id && navigate(MainRoutes.weber + WeberRoutes.feed + `/${feed?.id}`);

  return (
    <div className={classNames('DOIModal', className)}>
      {!!feed?.id && count === 0 ? (
        <div className="text-sm font-medium md:text-base">
          <p>
            {t(
              'feed.An_article_with_such_a_DOI_has_already_been_published_on_the_platform'
            )}{' '}
            {t('feed.Try_downloading_another_article_or_find_out_more')}
          </p>
        </div>
      ) : isLoading || (count && count > 0) ? (
        <div className="DOIModal__bar">
          <h6>
            {t('buttons.Uploaded')} {count}%
          </h6>
          <ProgressBar size="md" rating={count} />
          <p>{t('feed.Wait_for_the_download_to_complete_to_continue')}</p>
        </div>
      ) : (
        <MInput
          label={t('buttons.Enter_the_URL_link_to_the_article_or_DOI')}
          placeholder={t('buttons.Enter')}
          value={urlDOI}
          onChange={setUrlDOI}
          inputClassName={'w-full'}
          wrapperClassName={'w-full'}
        />
      )}
      <div className="DOIModal__btns">
        <MButton
          variant="outline"
          color="primary"
          className="w-full"
          onClick={onCancel}
        >
          {t('buttons.cancel')}
        </MButton>
        <MButton
          variant="highlighted"
          color="primary"
          disabled={!urlDOI || isLoading}
          className="w-full"
          onClick={() =>
            !!feed?.id
              ? goTo()
              : !feed?.id && count === 100
              ? onCancel?.()
              : onSave?.(urlDOI)
          }
        >
          {!!feed?.id ? t('buttons.Open_an_article') : t('buttons.Continue')}
        </MButton>
      </div>
    </div>
  );
};

import instance, { WEBER_API_URL } from './instance';
import { PublicProfileStats } from '../models';

export function getProfileMe(): Promise<PublicProfileStats> {
  return instance()
    .get(`${WEBER_API_URL}/profiles/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getProfile(guid: string): Promise<PublicProfileStats> {
  return instance()
    .get(`${WEBER_API_URL}/profiles/${guid}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

import React from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { NotFound } from '../../../../components/NotFound/NotFound';
import { useCourses } from '../../../../entities/Courses';
import { Pagination } from '../../../../horizon-components/Pagination';
import { CourseCard } from '../../../../widgets/CourseCard';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedUnits?: number[];
  selectedDPId?: number;
  selectedLevel?: number;
};

export const SchoolCourses: React.FC<Props> = ({
  selectedUnits,
  selectedDPId,
  selectedLevel,
}) => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();

  const { data, isLoading, total, page, setPage } = useCourses({
    digital_pass: selectedDPId || undefined,
    digital_pass_category: Number(id),
    subject_level: selectedLevel || undefined,
    subject_level_units: selectedUnits,
  });

  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col gap-[20px]">
      {!!data?.results.length ? (
        <div className={'grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2'}>
          {data?.results.map((course, key) => (
            <CourseCard course={course} key={key} showBtns={false} />
          ))}
        </div>
      ) : (
        <NotFound text={t('course.Coming_soon')} />
      )}
      {total > 1 && (
        <div className={'flex w-full items-center justify-center'}>
          <Pagination
            totalPages={total}
            currentPage={page}
            showPrevNext={true}
            onPageChange={setPage}
          />
        </div>
      )}
    </div>
  );
};

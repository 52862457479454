import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import { ProfileTeacherMyCourseCard } from '../../../components/ProfileTeacherMyCourseCard/ProfileTeacherMyCourseCard';
import { useMyCourses } from '../../../entities/Courses';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseCardItem, CourseStatus } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import { CourseCard } from '../../../widgets/CourseCard';
import './styles.scss';
import { saveCurrentUrl } from '../../../api/sessions';

export const CourseList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const {
    data: rejectedCourses,
    isLoading: isRejectedLoading,
    fetchData: fetchRejectedData,
    fetchDelete: fetchRejectedDelete,
  } = useMyCourses();

  const {
    data: draftCourses,
    isLoading: isDraftLoading,
    fetchData: fetchDraftData,
    fetchDelete: fetchDraftDelete,
  } = useMyCourses();

  const {
    data: verifiedCourses,
    isLoading: isVerifiedLoading,
    fetchData: fetchVerifiedData,
  } = useMyCourses();

  const {
    data: unverifiedCourses,
    isLoading: isUnverifiedLoading,
    fetchData: fetchUnverifiedData,
    fetchDelete: fetchUnverifiedDelete,
  } = useMyCourses();

  const goToCreateCourse = () => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    navigate(MainRoutes.edu + EduRoutes.edit + `/0/upload-content`);
  };

  useEffect(() => {
    fetchRejectedData(CourseStatus.Rejected);
    fetchDraftData(CourseStatus.Draft);
    fetchVerifiedData(CourseStatus.Verified);
    fetchUnverifiedData(CourseStatus.Unverified);
  }, [t]);

  return (
    <div className={'b-course-list'}>
      <div className={'flex items-center justify-between gap-x-2'}>
        <h1 className={'g-main-title text-[20px] md:text-[36px]'}>
          {t('menu.MyCourses')}
        </h1>
        <MButton
          variant={'highlighted'}
          color={'primary'}
          size={'sm'}
          onClick={goToCreateCourse}
          id={'anc'}
          className={'md-max:!h-[42px] md-max:!w-[42px] md-max:!p-3.5'}
        >
          <span className="md-max:hidden">{t('course.createCourse')}</span> <Plus />
        </MButton>
      </div>
      {isRejectedLoading ||
      isDraftLoading ||
      isVerifiedLoading ||
      isUnverifiedLoading ? (
        <Loader />
      ) : !rejectedCourses?.length &&
        !draftCourses?.length &&
        !verifiedCourses?.length &&
        !unverifiedCourses?.length ? (
        <NotFound />
      ) : (
        <>
          {!!rejectedCourses?.length && (
            <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
              <h3 className={'subtitle'}>{t('edu.Rejected')}</h3>
              <div
                className={
                  'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                }
              >
                {rejectedCourses?.map((course, i) => (
                  <ProfileTeacherMyCourseCard
                    course={course}
                    key={course.id}
                    onRemove={fetchRejectedDelete}
                  />
                ))}
              </div>
            </div>
          )}
          {!!draftCourses?.length && (
            <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
              <h3 className={'subtitle'}>{t('edu.Drafts')}</h3>
              <div
                className={
                  'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                }
              >
                {draftCourses?.map((course, i) => (
                  <ProfileTeacherMyCourseCard
                    course={course}
                    key={course.id}
                    onRemove={fetchDraftDelete}
                  />
                ))}
              </div>
            </div>
          )}
          {!!verifiedCourses?.length && (
            <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
              <h3 className={'subtitle'}>{t('edu.Verified')}</h3>
              <div
                className={
                  'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-3 lg:grid-cols-4 4xl:grid-cols-5'
                }
              >
                {verifiedCourses?.map((course, i) => (
                  <CourseCard
                    course={course as unknown as CourseCardItem}
                    inCreateCourse
                    key={course.id}
                  />
                ))}
              </div>
            </div>
          )}
          {!!unverifiedCourses?.length && (
            <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
              <h3 className={'subtitle'}>{t('edu.Unverified')}</h3>
              <div
                className={
                  'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                }
              >
                {unverifiedCourses?.map((course, i) => (
                  <ProfileTeacherMyCourseCard
                    course={course}
                    key={course.id}
                    onRemove={fetchUnverifiedDelete}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

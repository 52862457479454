import { useCallback, useEffect, useState } from 'react';
import { CourseRequest, getUserCourses } from '../../../../../api/course';
import { ResponsePagination } from '../../../../../models/Response';
import { CourseCardItem } from '../../../../../models/Course';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';
import { useTranslation } from 'react-i18next';

export const useCourses = ({
  mode,
  sort_by,
  category,
  subject,
  title,
  language,
  owner,
  owner_guid,
  created_at_after,
  created_at_before,
  exclude_owner,
  subject_level,
  subject_level_units,
  digital_pass,
  digital_pass_category,
  subject_level_range_min,
  subject_level_range_max,
}: Omit<CourseRequest, 'page'>) => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ResponsePagination<CourseCardItem> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getUserCourses({
      page,
      mode,
      sort_by,
      category,
      subject,
      title,
      language,
      owner,
      owner_guid,
      created_at_after,
      created_at_before,
      exclude_owner,
      subject_level,
      subject_level_units,
      digital_pass,
      digital_pass_category,
      subject_level_range_min,
      subject_level_range_max,
    }).then((res) => {
      setData(res);
      res?.count && setCount(res.count);
      res !== undefined && setIsLoading(false);
    });
  }, [
    category,
    created_at_after,
    created_at_before,
    digital_pass,
    digital_pass_category,
    exclude_owner,
    language,
    mode,
    owner,
    owner_guid,
    page,
    sort_by,
    subject,
    subject_level,
    subject_level_range_max,
    subject_level_range_min,
    subject_level_units,
    title,
    t,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    page,
    total,
    isLoading,
    setPage,
    count,
  };
};

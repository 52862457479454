// Assets
import React from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { DropZonefile } from './DropZone';

type Props = {
  onDrop: (acceptedFiles: File[]) => void;
  title?: string;
  description?: string;
  extra?: string;
  imageExtra?: string;
  titleExtra?: string;
  descriptionExtra?: string;
};

export const StyledDropZone: React.FC<Props> = ({
  title = 'Drop your image here or click to browse',
  description = '1600 x 1200 (4:3) recommended. PNG, JPG and GIF files are allowed',
  extra = '',
  imageExtra = '',
  titleExtra = '',
  descriptionExtra = '',
  onDrop,
}) => {
  return (
    <DropZonefile
      onDrop={onDrop}
      content={
        <div
          className={`flex h-full w-full flex-col items-center justify-center rounded-xl !border border-dashed border-gray-200 bg-gray-100 px-[5px] dark:!border-white/10 dark:!bg-navy-700 ${extra}`}
        >
          <p className={`text-[80px] text-navy-700 dark:text-white ${imageExtra}`}>
            <MdOutlineCloudUpload />
          </p>
          <p
            className={`text-lg font-bold text-navy-700 dark:text-white ${titleExtra}`}
          >
            {title}
          </p>
          <p
            className={`text-lightFourth pt-2 text-sm font-medium ${descriptionExtra}`}
          >
            {description}
          </p>
        </div>
      }
    />
  );
};

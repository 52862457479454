import classNames from 'classnames';
import { useEffect, useState } from 'react';
import SliderBG from '../../../assets/images/slider/sliderBG.png';
import './Slider.scss';

type TSlider = {
  id: string;
  title: string;
  content: string;
};

interface SliderProps {
  className?: string;
  slides: TSlider[];
  delay?: number;
}

export const Slider = ({ className, slides = [], delay = 3000 }: SliderProps) => {
  const [activeId, setActiveId] = useState('mission');

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = slides.findIndex((el) => el.id === activeId);
      const nextIndex = (currentIndex + 1) % slides.length;
      setActiveId(slides[nextIndex].id);
    }, delay);

    return () => clearInterval(interval);
  }, [activeId]);

  const handleClick = (id: string) => {
    setActiveId(id);
  };

  return (
    <div
      className={classNames('Slider', className)}
      style={{ backgroundImage: `url(${SliderBG})` }}
    >
      <div className="Slider__content">
        {slides.map((item, index) => (
          <div
            key={index}
            className={`Slider__slide ${
              activeId === item.id ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        ))}
      </div>
      <div className="Slider__controls">
        {slides.map((item, index) => (
          <button
            key={index}
            className={`Slider__control ${
              activeId === slides[(index + 1) % 3].id
                ? 'left'
                : activeId === slides[(index + 2) % 3].id
                ? 'right'
                : 'active'
            }`}
            onClick={() => handleClick(item.id)}
          >
            {item.title}
          </button>
        ))}
      </div>
    </div>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDPGroupLP } from '../../../../../api/digitalPass';
import { TDPGroup } from '../../../../../models/DigitalPass';

export const useDPGroupLP = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<TDPGroup[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getDPGroupLP()
      .then((res) => setData(res))
      .finally(() => setIsLoading(false));
  }, [t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
  };
};

import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FeedRequest } from '../../../../api/weber/feed';
import { ReactComponent as EditIcon } from '../../../../assets/icons/profile/edit.svg';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { TitleBack } from '../../../../components/TitleBack';
import { useFolder } from '../../../../entities/Post';
import { CreateFolderModal } from '../../../../features/CreateFolderModal';
import useHistoryParams from '../../../../hooks/useHistoryParams';
import { MButton } from '../../../../horizon-components/MButton';
import { Modal } from '../../../../horizon-components/Modal';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { FeedFilter } from '../FeedList/components/FeedFilter';
import { Feeds } from '../FeedList/components/Feeds';
import './FavoriteView.scss';

interface FavoriteViewProps {
  className?: string;
}

export const FavoriteView = ({ className }: FavoriteViewProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [filters, setFilters] = useState<FeedRequest>({});
  const { addParamToUrl, removeParamFromUrl } = useHistoryParams();
  const [isFolder, setIsFolder] = useState(false);
  const [count, setCount] = useState(0);

  const { data, fetchData, fetchDelete, fetchUpdate } = useFolder();

  useEffect(() => {
    !!data?.posts_count && setCount(data?.posts_count);
  }, [data]);

  useEffect(() => {
    if (Number(id) !== 0) {
      fetchData(Number(id));
    }
    setFilters(
      Number(id) ? { favourite_folder: Number(id) } : { favourited_posts: true }
    );
  }, [id]);

  const handleFilter = (filter: FeedRequest) => {
    setFilters({
      ...filters,
      ...filter,
      page: 1,
    });
  };

  const handleSearch = (search: FeedRequest) => {
    if (!search.title) {
      delete filters.title;
      setFilters({ ...filters, page: 1 });
      removeParamFromUrl('title');
    } else {
      setFilters({ ...filters, ...search, page: 1 });
      addParamToUrl('title', search.title);
    }
  };

  const breads = useMemo(
    () => [
      { title: 'Weber', url: MainRoutes.weber },
      { title: t('feed.title'), url: MainRoutes.weber + WeberRoutes.feeds },
      {
        title: t('feed.favorites'),
        url:
          MainRoutes.weber + WeberRoutes.feeds + '/?from_me=true&status=favorites',
      },
      {
        title: data?.title ? data?.title : t('feed.all_favorites'),
        url: MainRoutes.weber + WeberRoutes.favorites + `/${data?.id || 0}`,
      },
    ],
    [data, t]
  );

  const handleOpenFolderModal = () => {
    setIsFolder(!isFolder);
  };

  const hndlDelete = () => {
    Number(id) !== 0 &&
      fetchDelete(Number(id)).finally(() => {
        handleOpenFolderModal();
        navigate(
          MainRoutes.weber + WeberRoutes.feeds + '/?from_me=true&status=favorites'
        );
      });
  };

  const hndlSave = (title: string) => {
    Number(id) !== 0 &&
      fetchUpdate(Number(id), title).finally(() => {
        handleOpenFolderModal();
        fetchData(Number(id));
      });
  };

  return (
    <div className={classNames('FavoriteView', className)}>
      <Breadcrumbs breads={breads} className="hidden md:block" />
      <TitleBack
        title={data?.title ? data?.title : t('feed.all_favorites')}
        backUrl={MainRoutes.edu + WeberRoutes.feeds}
        className="md:!hidden"
      />
      <div className="FavoriteView__wrapper">
        <div className="FavoriteView__head">
          <div>
            {<h3>{data?.title ? data?.title : t('feed.all_favorites')}</h3>}
            {!!count && (
              <span>
                {count} {t('feed.posts')}
              </span>
            )}
          </div>
          <MButton
            variant="highlighted"
            color="white"
            className="sm-max:self-end"
            onClick={handleOpenFolderModal}
          >
            {t('feed.edit_folder')} <EditIcon />
          </MButton>
        </div>
        <FeedFilter onFilter={handleFilter} isMy={false} onSearch={handleSearch} />
        <Feeds filters={filters} onCount={setCount} />
      </div>

      <Modal
        title={t('feed.edit_folder')}
        isOpen={isFolder}
        onClose={handleOpenFolderModal}
      >
        <CreateFolderModal isEdit onDelete={hndlDelete} onSave={hndlSave} />
      </Modal>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PinInput from 'react-pin-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import {
  authMe,
  checkCode,
  checkCodeResetPassword,
  createJWT,
  forgotPassword,
  signUp,
} from '../../../api/auth';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { AuthLayout } from '../../../widgets/AuthLayout';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import { getRedirectPath } from '../../../api/sessions';

const CheckCode = () => {
  const navigate = useNavigate();
  const {
    state,
  }: {
    state: { email: string; password?: string };
  } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setResendIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [code, setCode] = useState<string>('');
  const { t } = useTranslation(['translation']);
  const [timer, setTimer] = useState(60);
  const { saveUser } = useUserContext();
  const redirectPath = getRedirectPath();

  useEffect(() => {
    if (!!timer) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  useEffect(() => {
    if (!state.email) {
      navigate(-1);
    }
  }, [navigate, state]);

  const next = (completeCode: string) => {
    setError('');
    if (state.password) {
      setIsLoading(true);
      checkCode(state.email || '', completeCode)
        .then(() =>
          createJWT(state.email, state.password || '').then((data) => {
            saveAssesTokenStorage(data.access);
            saveRefreshTokenStorage(data.refresh);

            toast(t('profile.emailSuccessCode'), {
              type: 'success',
            });

            return authMe().then((data) => {
              saveUser(data);
              navigate(redirectPath || MAIN_PAGE_ROUTE);
            });
          })
        )
        .catch(() => setError(t('profile.notCode')))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      checkCodeResetPassword(state.email, completeCode)
        .then(() =>
          navigate(MainRoutes.auth + AuthRoutes.change, {
            state: {
              email: state.email,
              code: completeCode,
            },
          })
        )
        .catch(() => setError(t('profile.notCode')))
        .finally(() => setIsLoading(false));
    }
  };

  const resend = () => {
    if (state.password) {
      setResendIsLoading(true);
      signUp(state.email, state.password)
        .then(() => {
          setTimer(60);
        })
        .finally(() => setResendIsLoading(false));
    } else {
      setResendIsLoading(true);
      forgotPassword(state.email)
        .then(() => {
          setTimer(60);
        })
        .finally(() => setResendIsLoading(false));
    }
  };

  const goToBack = () => navigate(-1);

  return (
    <AuthLayout
      title={t('profile.check')}
      desc={t('profile.weSend') + ' ' + state?.email}
      wrapperClassName={'lg:min-h-[600px]'}
    >
      <div className="flex flex-col gap-[16px]">
        <div className="mb-0 flex w-full items-center justify-center">
          <PinInput
            length={4}
            initialValue=""
            onChange={(value, index) => setCode(value)}
            type="numeric"
            inputMode="number"
            style={{
              width: '100%',
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            inputStyle={{
              borderWidth: '2px',
              width: '65px',
              height: '65px',
              borderColor: '#E0E5F2',
              borderRadius: '16px',
              color: '#2B3674',
              fontSize: '36px',
            }}
            inputFocusStyle={{
              borderColor: '#4318FF',
              borderWidth: '3px',
            }}
            onComplete={(value) => next(value)}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            focus
          />
        </div>
        {!!error && <p className={'mt-1 text-center text-red-600'}>{error}</p>}
        <div className={'flex items-center justify-between gap-2'}>
          <MButton
            variant="secondary"
            color="grey"
            onClick={resend}
            disabled={isLoading || !!timer || isResendLoading}
            className={'w-[50%]'}
          >
            {!!timer ? `${timer}${t('s')}` : t('resend')}
          </MButton>
          <MButton
            variant="highlighted"
            color="primary"
            onClick={() => next(code)}
            disabled={isLoading}
            className={'w-[50%]'}
          >
            {t('profile.unlock')}
          </MButton>
        </div>
        <p className={'text-[14px] font-[500] text-gray-900'}>
          {t('profile.clickToChange')}:{' '}
          <span onClick={goToBack} className={'cursor-pointer text-purple-500'}>
            {state.email}
          </span>
        </p>
      </div>
    </AuthLayout>
  );
};

export default CheckCode;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/icons/union.svg';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { WizStatusEnum } from '../../../models/Wiz';
import { useUserContext } from '../../../providers';
import { RulesModal } from './components/RulesModal';
import { useTranslation } from 'react-i18next';
import {
  MainRoutes,
  WizRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

export const Start = ({
  statusTest,
  onStart,
}: {
  statusTest?: WizStatusEnum;
  onStart?: () => void;
}) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const [status, setStatus] = useState<WizStatusEnum>();
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);

  useEffect(() => setStatus(statusTest), [statusTest]);

  const goToUrl = (url: string) => navigate(url);

  return (
    <>
      <div className="m-auto flex max-w-[500px] grow flex-col items-center gap-10 text-center text-sm font-medium text-secondary-dark-grey-900 md:text-base">
        <div className="flex flex-col items-center gap-6">
          <Logo />
          <div>
            <h1
              className={
                'mb-2 text-3xl font-bold text-secondary-dark-grey-900 md:text-4xl'
              }
            >
              {t('wiz.start')}
            </h1>
            <p>{t('wiz.takeTheWIZ')}</p>
            <p className="text-primary-purple-blue-600">{t('wiz.IQA')}</p>
          </div>
        </div>

        <>
          <div className="flex flex-col gap-2 rounded-[30px] border-[2px] border-primary-purple-blue-100 p-[16px] text-left md:gap-4 md:p-6">
            {status === WizStatusEnum.COMPLETED && (
              <div className="text-center">
                <h3 className="mb-1 text-base font-semibold text-primary-purple-blue-500 md:text-lg">
                  {t('wiz.You_have_already_completed_the_daily_challenge')}
                </h3>
                <p>
                  {t('wiz.Play_again_tomorrow_to_get_to_the_top_of_the_leaderboard')}
                </p>
              </div>
            )}
            {status === WizStatusEnum.NOT_STARTED && (
              <>
                <div>
                  <h3 className="mb-1 text-base font-semibold text-primary-purple-blue-500 md:text-lg">
                    {t('wiz.Analyze_answers')}
                  </h3>
                  <p>
                    {t(
                      'wiz.Consider_all_options_before_choosing_an_answer_Sometimes_the_right_answer_may_not_be_so_obvious'
                    )}
                  </p>
                </div>
                <div>
                  <h3 className="mb-1 text-base font-semibold text-primary-purple-blue-500 md:text-lg">
                    {t('wiz.Manage_time')}
                  </h3>
                  <p>
                    {t('wiz.Quiz_has_a_limited_time_only')}{' '}
                    <span className="text-primary-purple-blue-600">
                      {t('wiz.min')}.
                    </span>{' '}
                    {t(
                      'wiz.Divide_it_between_questions_depending_on_the_difficulty_and_length_of_the_answers'
                    )}
                  </p>
                </div>
              </>
            )}
          </div>

          {status === WizStatusEnum.COMPLETED && (
            <div className="flex gap-[10px]">
              <MButton
                variant={'secondary'}
                color="primary"
                onClick={() => goToUrl(MainRoutes.wiz + WizRoutes.scoreboard)}
              >
                {t('wiz.Leaderboard')}
              </MButton>
              <MButton
                variant="highlighted"
                color="primary"
                onClick={() => goToUrl(MainRoutes.wiz + WizRoutes.performance)}
              >
                {t('wiz.Performance')}
              </MButton>
            </div>
          )}
          {status === WizStatusEnum.NOT_STARTED && (
            <div className="flex gap-[10px]">
              <MButton
                variant={'secondary'}
                color="primary"
                onClick={() => setIsRulesModalOpen(true)}
              >
                {t('wiz.Rules')}
              </MButton>
              <MButton variant="highlighted" color="primary" onClick={onStart}>
                {t('wiz.Start')}
              </MButton>
            </div>
          )}
        </>
      </div>
      <RulesModal
        isOpen={isRulesModalOpen}
        onClose={() => setIsRulesModalOpen(false)}
      />
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { toast } from 'react-toastify';
import { setStorage } from '../../../../api';
import ChatIcon from '../../../../assets/icons/share/chat.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/share/duplicate.svg';
import EmailIcon from '../../../../assets/icons/share/email.svg';
import TelegramIcon from '../../../../assets/icons/share/telegram.svg';
import TwitterIcon from '../../../../assets/icons/share/twitter.svg';
import WhatsappIcon from '../../../../assets/icons/share/whatsapp.svg';
import { Loader } from '../../../../components/Loader';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import './ShareButtons.scss';

type Props = {
  url: string;
};

export const ShareButtons: React.FC<Props> = ({ url }) => {
  const { t } = useTranslation(['translation']);
  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(url);
    toast('The link has been copied', {
      type: 'success',
    });
  };
  const hndlChatShare = () => {
    setStorage('chat_share', url);
  };
  return (
    <div className="ShareButtons">
      {!url ? (
        <Loader className="mt-5" />
      ) : (
        <>
          <div className="ShareButtons__wrapper">
            <div className="ShareButtons__button">
              <Link
                className="ShareButtons__icon"
                onClick={hndlChatShare}
                to={MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat}
              >
                <img src={ChatIcon} alt="Chat" />
              </Link>
              <p>{t('networking.chat')}</p>
            </div>
            <div className="ShareButtons__button">
              <TelegramShareButton
                className="ShareButtons__icon"
                url={url}
                disabled={!url}
              >
                <img src={TelegramIcon} alt="Telegram" />
              </TelegramShareButton>
              <p>Telegram</p>
            </div>
            <div className="ShareButtons__button">
              <WhatsappShareButton
                className="ShareButtons__icon"
                url={url}
                disabled={!url}
              >
                <img src={WhatsappIcon} alt="Whatsapp" />
              </WhatsappShareButton>
              <p>Whatsapp</p>
            </div>
            <div className="ShareButtons__button">
              <TwitterShareButton
                className="ShareButtons__icon"
                url={url}
                disabled={!url}
              >
                <img src={TwitterIcon} alt="Twitter" />
              </TwitterShareButton>
              <p>Twitter</p>
            </div>
            <div className="ShareButtons__button">
              <EmailShareButton
                className="ShareButtons__icon"
                url={url}
                disabled={!url}
              >
                <img src={EmailIcon} alt="E-mail" />
              </EmailShareButton>
              <p>E-mail</p>
            </div>
          </div>
          <div>
            <span>{t('wiz.Or_share_with_link')}</span>
            <div className="ShareButtons__copy py-2 md:py-[14px]">
              <span>{url}</span>
              <DuplicateIcon onClick={copyTextToClipboard} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { SCALE_DAY_TIME } from '@/shared/const/api-cache';
import {
  getFavouritePurchasableSubjectsV3,
  getPurchasableSubjectV3,
  getPurchasedSubjectsV3,
} from '@/api/digitalPassV3';
import { ResponsePagination } from '@/models/Response';
import { DigitalPassCategory } from '@/models/DigitalPass';
import { DigitalPassMode } from '@/api/digitalPass';

export const usePurchasableSubject = (
  params?: { page?: number; ordering?: string },
  options?: UseQueryOptions<ResponsePagination<DigitalPassCategory>, Error>
) => {
  return useQuery<ResponsePagination<DigitalPassCategory>, Error>({
    queryKey: [DigitalPassMode.DISCOVER, params],
    queryFn: () => getPurchasableSubjectV3(params?.page, params?.ordering),
    staleTime: SCALE_DAY_TIME,
    ...options,
  });
};

export const usePurchasedSubjects = (
  params?: { page?: number },
  options?: UseQueryOptions<ResponsePagination<DigitalPassCategory>, Error>
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery<ResponsePagination<DigitalPassCategory>, Error>({
    queryKey: [DigitalPassMode.MY_PASSES, params],
    queryFn: () => getPurchasedSubjectsV3(params?.page),
    staleTime: SCALE_DAY_TIME,
    ...options,
  });
};

export const useFavouritePurchasableSubjects = (
  options?: UseQueryOptions<number[], Error>
) => {
  return useQuery<number[], Error>({
    queryKey: ['favourite'],
    queryFn: () => getFavouritePurchasableSubjectsV3(),
    staleTime: SCALE_DAY_TIME,
    ...options,
  });
};

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMainEarnMoney } from '../../../../../api/auth';
import { TPossibilities } from '../../../../../models/Main';

export const useMainEarnMoney = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<TPossibilities[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getMainEarnMoney()
      .then((res) => setData(res))
      .finally(() => setIsLoading(false));
  }, [t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
  };
};

import { Card } from '../../../../horizon-components/Card/Card';
import React, { useState } from 'react';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Course, CourseStatus } from '../../../../models/Course';
import { sendToVerification } from '../../../../api/course';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';

type Props = {
  currentCourse: Course | null;
  courseId: string;
};

export const PreviewWindow: React.FC<Props> = ({ currentCourse, courseId }) => {
  const navigate = useNavigate();
  const [isVerificationLoading, setIsVerificationLoading] = useState(false);
  const { t } = useTranslation();

  const sendToVerificationClick = () => {
    setIsVerificationLoading(true);
    sendToVerification(Number(courseId || 0))
      .then(() => {
        navigate(MainRoutes.edu + EduRoutes.courses);
      })
      .finally(() => {
        setIsVerificationLoading(false);
      });
  };

  const goToBack = () => navigate(-1);

  return (
    <>
      <div className={'bottom-24 left-0 w-full lg:fixed lg:bottom-16'}>
        <Card
          className={
            'z-[11] mx-auto flex w-full flex-col items-start justify-between !rounded-[24px] bg-secondaryGrey-200 !p-[12px] !shadow-[14px_17px_40px_4px_#7090B014] lg:max-w-[800px] lg:flex-row lg:items-center lg:!rounded-[30px] lg:!p-[24px]'
          }
        >
          <div>
            <p className={'text-[18px] font-[600] text-gray-900'}>
              {t('course.preview')}
            </p>
            <p className={'text-[12px] font-[600] text-gray-500'}>
              {t('course.previewInfo')}
            </p>
          </div>
          <div
            className={
              'mt-2 flex w-full flex-col justify-end gap-2 md:flex-row md:gap-5 lg:mt-0 lg:w-auto'
            }
          >
            <MButton
              onClick={goToBack}
              variant={'outline'}
              className={'w-full md:w-auto'}
            >
              {currentCourse?.course_status === CourseStatus.Draft ||
              currentCourse?.course_status === CourseStatus.Rejected
                ? t('course.back')
                : t('course.backPerform')}
            </MButton>
            {(currentCourse?.course_status === CourseStatus.Draft ||
              currentCourse?.course_status === CourseStatus.Rejected) && (
              <MButton
                onClick={sendToVerificationClick}
                disabled={isVerificationLoading}
                className={'w-full md:w-auto'}
                variant="highlighted"
                color="primary"
              >
                {t('course.verify')}
              </MButton>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRefreshTokenStorage, saveAssesTokenStorage } from '../../../api';
import { refreshToken } from '../../../api/auth';
import { saveCurrentUrl } from '../../../api/sessions';
import {
  DayResult,
  WIZ_TOKEN_STORAGE_KEY,
  claimReward,
  getIsRewardClaimed,
  getMyIqa,
  getStatus,
  showResults,
  startTest,
} from '../../../api/wiz/wiz';
import { Loader } from '../../../components/Loader';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MainRoutes,
  WizRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { WizStatusEnum } from '../../../models/Wiz';
import { useUserContext } from '../../../providers';
import { ResultGrid } from './components/ResultGrid';
import { Share } from './components/Share';
import './Performance.scss';

export const Performance = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);

  const [iqa, setIqa] = useState<number>(0);
  const [status, setStatus] = useState<null | WizStatusEnum>(null);
  const [results, setResults] = useState<null | DayResult[]>(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isRewardClaimed, setIsRewardClaimed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const wizToken = localStorage.getItem(WIZ_TOKEN_STORAGE_KEY);
      if (!wizToken) {
        navigate(MainRoutes.wiz + WizRoutes.quizzes);
        return;
      }
      setIsLoading(true);
      await Promise.allSettled([
        getStatus().then((res) => setStatus(res)),
        getMyIqa().then((res) => setIqa(res)),
        showResults().then((res) => setResults(res)),
        getIsRewardClaimed().then((res) => setIsRewardClaimed(res)),
      ]).finally(() => setIsLoading(false));
    })();
  }, []);

  const isTestCompleted = status === WizStatusEnum.COMPLETED;

  let label =
    isTestCompleted && !isRewardClaimed
      ? t('wiz.Claim_Reward')
      : t('wiz.Start_test');

  const claimRewardAction = async () => {
    const refreshJwtToken = getRefreshTokenStorage() as string;
    const { access } = await refreshToken(refreshJwtToken);
    saveAssesTokenStorage(access);
    const { reward_amount } = await claimReward(access);

    setIsRewardClaimed(true);

    const textForToast =
      reward_amount > 0
        ? t('wiz.Congratulations_Youve_earned_ER_points', { reward_amount })
        : t('wiz.Youve_made_a_mistake_no_ER_points_earned');
    toast(textForToast, {
      type: 'success',
    });
  };

  const startNewTestAction = async (status: WizStatusEnum) => {
    if (status === WizStatusEnum.NOT_STARTED) {
      await startTest();
      navigate(MainRoutes.wiz + WizRoutes.quizzes);
    } else {
      toast('Test already started!', {
        type: 'error',
      });
    }
  };

  const onButtonClicked = async () => {
    setIsButtonDisabled(true);
    try {
      if (isTestCompleted && !user) {
        saveCurrentUrl(window.location.pathname);
        navigate(MainRoutes.auth + AuthRoutes.signin);
      } else if (isTestCompleted && user && !isRewardClaimed) {
        await claimRewardAction();
      } else {
        const status = await getStatus();
        setStatus(status);
        const isTodayTestCompleted = status === WizStatusEnum.COMPLETED;
        if (isTodayTestCompleted) {
          toast(t('wiz.You_have_already_completed_todays_quiz_Try_it_tomorrow'), {
            type: 'error',
          });
          return;
        }
        await startNewTestAction(status);
      }
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="Performance">
      <h1 className="g-main-title">WIZ</h1>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="Performance__wrapper">
          <div className="w-full">
            <ResultGrid results={results} />

            <h3 className="text-2xl md:text-[32px]">IQA: {iqa}</h3>
          </div>

          <div className={'Performance__buttons'}>
            <MButton
              variant="secondary"
              color="primary"
              onClick={() => setShowShareModal(true)}
            >
              {t('wiz.Share')}
            </MButton>
            {status === WizStatusEnum.TEST_STARTED ? (
              <MButton
                variant="highlighted"
                color="primary"
                onClick={() => navigate(MainRoutes.wiz + WizRoutes.quizzes)}
                disabled={isButtonDisabled}
              >
                {t('wiz.Continue')}
              </MButton>
            ) : (
              <MButton
                variant="highlighted"
                color="primary"
                onClick={onButtonClicked}
                disabled={isButtonDisabled}
              >
                {label}
              </MButton>
            )}
          </div>
          <Share
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
          />
        </div>
      )}
    </div>
  );
};

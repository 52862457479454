import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-icon.svg';
import { ReactComponent as LogoText } from '../../assets/icons/logo-text.svg';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { HButton } from '../../horizon-components/Button/HButton';
import { useUserContext } from '../../providers';
import NavbarMenu from './NavbarMenu';
import NavbarMenuAnonymous from './NavbarMenuAnonymous';
import { MAIN_PAGE_ROUTE, MainRoutes, WeberRoutes } from './Routes/types/routes';
import { MTooltip } from '../../horizon-components/MTooltip/MTooltip';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { user } = useUserContext();
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const goTo = (url: string) => () => navigate(url);

  const navbarButtons = () => (
    <div className="ml-[6px] hidden gap-x-[20px] lg:flex">
      <HButton
        id={'edu-btn'}
        color={pathname.indexOf('edu') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[700]'}
        onClick={goTo(MainRoutes.edu)}
      >
        EDU
        <MTooltip anchorSelect={'#edu-btn'} text={t('menu.educationalPlatform')} />
      </HButton>
      <HButton
        id={'wiz-btn'}
        color={pathname.indexOf('wiz') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[700]'}
        onClick={goTo(MainRoutes.wiz)}
      >
        WIZ
        <MTooltip anchorSelect={'#wiz-btn'} text={t('menu.quiz')} />
      </HButton>
      {!!user?.is_staff && (
        <>
          <HButton
            id={'weber-btn'}
            color={pathname.indexOf('weber') > -1 ? 'dark' : 'light'}
            className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[700]'}
            onClick={goTo(MainRoutes.weber + WeberRoutes.feeds)}
          >
            WEBER
            <MTooltip anchorSelect={'#weber-btn'} text={t('menu.socialNetwork')} />
          </HButton>
          <HButton
            id={'bid-btn'}
            color={pathname.indexOf('bid') > -1 ? 'dark' : 'light'}
            className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[700]'}
            onClick={goTo(MainRoutes.bid)}
          >
            BID
            <MTooltip anchorSelect={'#bid-btn'} text={t('menu.bid')} />
          </HButton>
        </>
      )}
    </div>
  );

  const renderText = () => {
    if (location.pathname.includes(MainRoutes.edu)) {
      return <span>EDU</span>;
    }
    if (location.pathname.includes(MainRoutes.weber)) {
      return <span>WEBER</span>;
    }
    if (location.pathname.includes(MainRoutes.wiz)) {
      return <span>WIZ</span>;
    }

    return <LogoText />;
  };

  return (
    <nav
      className={`duration-175 linear fixed right-0 top-0 z-50 flex w-full flex-row flex-wrap items-center justify-between bg-white/30 p-2  backdrop-blur-xl transition-all md:!px-4`}
    >
      <div className={'flex flex-row items-center gap-[117px]'}>
        <Link to={MAIN_PAGE_ROUTE} className={'hidden lg:block'}>
          <Logo />
        </Link>
        <div className={'flex items-center lg:hidden'}>
          <Link
            className={
              'mr-[7px] flex cursor-pointer rounded-[16px] border-[2px] border-secondaryGrey-300 bg-primary-white p-[10px] !shadow-[14px_17px_40px_4px_#7090B014]'
            }
            to={MAIN_PAGE_ROUTE}
          >
            <LogoIcon />
          </Link>
          <Link
            to={MAIN_PAGE_ROUTE}
            className="text-[20px] font-bold text-secondary-dark-grey-900"
          >
            {renderText()}
          </Link>
        </div>
        {navbarButtons()}
      </div>
      {!!user && <NavbarMenu />}
      {!user && <NavbarMenuAnonymous />}
    </nav>
  );
};

export default Navbar;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { MButton } from '@/horizon-components/MButton';
import { Modal } from '@/horizon-components/Modal';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuizDayCard } from '../QuizDayCard';
import { QuizStartModal } from '../QuizStartModal';
import './style.scss';

interface QuizStartProps {
  className?: string;
  onStart?: () => void;
}

const days: {
  title?: string;
  earn?: string;
  status?: 'passed' | 'earned' | 'new' | 'current';
}[] = [
  {
    title: 'Mon',
    earn: 'Earned 0 ER',
    status: 'passed',
  },
  {
    title: 'Tue',
    earn: 'Earned 0 ER',
    status: 'passed',
  },
  {
    title: 'Wed',
    earn: 'Earned 0 ER',
    status: 'passed',
  },
  {
    title: 'Thu',
    earn: 'Earned 40 ER',
    status: 'earned',
  },
  {
    title: 'Fri',
    earn: 'Earned 60 ER',
    status: 'current',
  },
  {
    title: 'Sat',
    earn: '60 ER',
    status: 'new',
  },
  {
    title: 'Sunday',
    earn: '100 ER',
    status: 'new',
  },
];

export const QuizStart = ({ className, onStart }: QuizStartProps) => {
  const { t } = useTranslation(['translation']);
  const [isStartOpen, setIsStartOpen] = useState(false);

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  const hndlStart = () => {
    onStart?.();
    setIsStartOpen(!isStartOpen);
  };

  return (
    <div className={classNames('QuizStart', className)}>
      <div className="QuizStart__head">
        <h1>Викторина</h1>
        <p>
          Увеличте количество заработанных ERP, <br /> заходя в игру ежедневно
        </p>
      </div>
      <div className="QuizStart__days">
        {days.map((day, idx) => (
          <QuizDayCard
            title={day.title}
            earn={day.earn}
            status={day.status}
            key={idx}
          />
        ))}
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        className="sm-max:w-full md:!px-8 md:!py-6 md:!text-lg"
        onClick={hndlOpenStartModal}
      >
        Start WIZ
      </MButton>
      <Modal isOpen={isStartOpen} onClose={hndlOpenStartModal} showClose={false}>
        <QuizStartModal onStart={hndlStart} />
      </Modal>
    </div>
  );
};

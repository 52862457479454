import React from 'react';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { Grade, UnitExamResult } from '../../../../models/Course';

type Props = {
  examResult: UnitExamResult;
  nextUnit: () => void;
  restartUnit: () => void;
  isLastUnit: () => boolean;
  summaryCourse: () => void;
};

export const ExamResultInfo: React.FC<Props> = ({
  examResult,
  nextUnit,
  restartUnit,
  isLastUnit,
  summaryCourse,
}) => {
  const { t } = useTranslation(['translation']);
  const rowClasses = 'flex flex-row justify-between items-center';
  const topicClasses =
    'text-[14px] font-medium text-secondary-dark-grey-900 md:text-[16px]';
  const resultContainerClasses =
    'rounded-[8px] md:px-[16px] md:py-[12px] px-[12px] py-[8px] font-semibold md:text-[14px] text-[12px]';
  const resultDefaultClasses = 'bg-[#C9FBD5] text-[#009B73]';
  const resultIncomeClasses = 'bg-[#E9E3FF] text-[#3311DB]';
  const hrClasses = 'border-[#E1E9F8] w-full border-1';

  const gradeContentMap: Record<Grade, string> = {
    A: 'bg-primary-green-100 text-primary-green-600',
    B: 'bg-primary-green-100 text-primary-green-600',
    C: 'bg-additional-orange-100 text-additional-orange-600',
    D: 'bg-additional-orange-100 text-additional-orange-600',
    F: 'bg-additional-pink-100 text-additional-pink-600',
  };

  return (
    <>
      <div
        className={
          'verflow-hidden overflow-ellipsis text-[16px] font-bold md:text-[20px]'
        }
      >
        {t('course.unitExamResults')}
      </div>
      <div className={'flex flex-col gap-[16px] md:gap-[24px]'}>
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.Your_grade')}</div>
          <div
            className={`${resultContainerClasses} ${
              gradeContentMap[examResult.grade] || resultDefaultClasses
            }`}
          >
            {examResult.grade}
          </div>
        </div>
        <div className={rowClasses}>
          <div className={topicClasses}>GPA</div>
          <div
            className={`${resultContainerClasses} ${
              gradeContentMap[examResult.grade] || resultDefaultClasses
            }`}
          >
            {examResult.gpa}
          </div>
        </div>
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.Percentage')}</div>
          <div
            className={`${resultContainerClasses} ${
              gradeContentMap[examResult.grade] || resultDefaultClasses
            }`}
          >
            {examResult.percentage_correct}
          </div>
        </div>
        <hr className={hrClasses} />
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.Earned')}</div>
          <div className={`${resultContainerClasses} ${resultIncomeClasses}`}>
            {examResult.er_points_income.split('.')[0]} {t('course.EDRES_POINTS')}
          </div>
        </div>
        <hr className={hrClasses} />
        <div className={'flex justify-between'}>
          <MButton color={'primary'} variant={'outline'} onClick={restartUnit}>
            {t('course.Retake')}
          </MButton>
          {!isLastUnit() ? (
            <MButton color={'primary'} variant={'highlighted'} onClick={nextUnit}>
              {t('course.Next_unit')}
            </MButton>
          ) : (
            <MButton
              color={'primary'}
              variant={'highlighted'}
              onClick={summaryCourse}
            >
              {t('course.Summary')}
            </MButton>
          )}
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { saveUserStorage } from '../../../../api';
import { changeInstitutionMeta } from '../../../../api/auth';
import useForm, { ErrorMessagesCode } from '../../../../hooks/useForm';
import { HButton } from '../../../../horizon-components/Button/HButton';
import { Card } from '../../../../horizon-components/Card/Card';
import { StyledDropZone } from '../../../../horizon-components/DropZone/StyledDropZone';
import InputField from '../../../../horizon-components/Input/InputField';
import { useUserContext } from '../../../../providers';
import { generateHashFromGuid, getUserData } from '../../../../utils/getUserData';
import { getRandomUserImage } from '../../../../utils/randomUserImage';
import { ChangePassword } from './ChangePassword';

type Form = {
  applier_full_name: string;
  phone_number: string;
  address: string;
  country: string;
  city: string;
  website: string;
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const InfoInstitution: React.FC = () => {
  const { user } = useUserContext();
  const { image, username } = getUserData(user || undefined);
  const [logo, setLogo] = useState<File | null | string>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoChanged, setLogoChanged] = useState(false);
  const { t } = useTranslation(['translation']);

  const { values, onChange, errors, validate } = useForm<Form>({
    applier_full_name: user?.institution_meta?.applier_full_name || '',
    phone_number: user?.institution_meta?.phone_number || '',
    address: user?.institution_meta?.address || '',
    country: user?.institution_meta?.country || '',
    city: user?.institution_meta?.city || '',
    website: user?.institution_meta?.website || '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        applier_full_name: yup.string().required(t(ErrorMessagesCode.required)),
        phone_number: yup
          .string()
          .required(t(ErrorMessagesCode.required))
          .matches(phoneRegExp, t(ErrorMessagesCode.phone)),
        address: yup.string().required(t(ErrorMessagesCode.required)),
        country: yup.string().required(t(ErrorMessagesCode.required)),
        city: yup.string().required(t(ErrorMessagesCode.required)),
        website: yup.string().url(t(ErrorMessagesCode.url)),
      }),
    [t]
  );

  const logoSrc = useMemo(() => {
    let src = require('../../../../assets/images/profile.200.jpg');
    if (typeof logo === 'string') {
      src = logo;
    } else if (logo instanceof File) {
      src = URL.createObjectURL(logo);
    }
    return src;
  }, [logo]);

  useEffect(() => {
    setLogo(image);
  }, [image]);

  const changeLogo = (file: File[]) => {
    setLogoChanged(true);
    setLogo(file[0]);
  };

  const submit = async () => {
    const hasError = await validate(schema);

    if (hasError) {
      return;
    }

    setIsSubmitting(true);
    changeInstitutionMeta(user?.guid || '', {
      applier_full_name: values.applier_full_name,
      address: values.address,
      website: values.website,
      country: values.country,
      city: values.city,
      phone_number: values.phone_number,
      ...(logoChanged ? { logo: logo as File } : {}),
    })
      .then((data) => {
        if (user) {
          user.institution_meta.applier_full_name = data.applier_full_name;
          user.institution_meta.address = data.address;
          user.institution_meta.website = data.website;
          user.institution_meta.country = data.country;
          user.institution_meta.city = data.city;
          user.institution_meta.phone_number = data.phone_number;
          user.institution_meta.logo = data.logo;
          saveUserStorage(user);
          window.location.reload();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className={'mt-[20px] grid w-full grid-cols-1 gap-3 md:grid-cols-2'}>
      <div>
        <Card extra={'p-3 mb-[20px]'}>
          <div
            style={{
              backgroundImage: `url(${getRandomUserImage(
                generateHashFromGuid(user?.guid || '')
              )})`,
            }}
            className="relative mb-[50px] flex h-[150px] w-full items-center justify-center rounded-[20px] bg-cover md:h-[120px] lg:h-[170px] 3xl:h-[220px]"
          >
            <div className="absolute -bottom-20 flex h-[180px] w-[180px] items-center justify-center rounded-full border-[10px] border-white bg-navy-700 dark:!border-navy-900">
              <img className="h-full w-full rounded-full" src={logoSrc} alt="" />
              <StyledDropZone
                onDrop={changeLogo}
                extra={
                  'absolute h-[180px] w-[180px] top-[0px] left-0 !bg-opacity-[0.1] !rounded-full'
                }
                title={''}
                description={''}
              />
            </div>
          </div>
          <div className="mb-[10px] mt-[30px] flex flex-col items-center">
            <h1 className="mt-2 text-[35px] font-bold text-navy-700 dark:text-white">
              {username}
            </h1>
          </div>
        </Card>
        <Card extra={'p-3 mb-[20px]'}>
          <div className="w-full pt-1">
            <h5 className="text-xl font-semibold text-navy-700 dark:text-white">
              {t('profile.settings')}
            </h5>
            <p className="text-md text-[#A3AED0]">{t('profile.info')}</p>
          </div>
          <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
            <InputField
              extra="mb-3"
              label={t('profile.fullName')}
              placeholder={t('profile.fullNameP')}
              type="text"
              value={values.applier_full_name}
              onChange={(e) => onChange(e.target.value, 'applier_full_name')}
              errorText={errors.applier_full_name}
            />
            <InputField
              extra="mb-3"
              label={t('profile.phoneNumber')}
              placeholder="+1234567890"
              type="text"
              value={values.phone_number}
              onChange={(e) => onChange(e.target.value, 'phone_number')}
              errorText={errors.phone_number}
            />
            <InputField
              extra="mb-3"
              label={t('profile.country')}
              placeholder="USA"
              id="country"
              type="text"
              value={values.country}
              onChange={(e) => onChange(e.target.value, 'country')}
              errorText={errors.country}
            />
            <InputField
              extra="mb-3"
              label={t('profile.city')}
              placeholder="Miami"
              id="city"
              type="text"
              value={values.city}
              onChange={(e) => onChange(e.target.value, 'city')}
              errorText={errors.city}
            />
            <InputField
              extra="mb-3"
              label={t('profile.address')}
              placeholder={t('profile.addressP')}
              id="address"
              type="text"
              value={values.address}
              onChange={(e) => onChange(e.target.value, 'address')}
              errorText={errors.address}
            />
            <InputField
              extra="mb-3"
              label={t('profile.website')}
              placeholder="example.com"
              id="email"
              type="text"
              value={values.website}
              onChange={(e) => onChange(e.target.value, 'website')}
              errorText={errors.website}
            />
          </div>
          <div className={'mt-[15px] flex w-full justify-end'}>
            <HButton color={'dark'} disabled={isSubmitting} onClick={submit}>
              {t('profile.saveChanges')}
            </HButton>
          </div>
        </Card>
      </div>
      <div>
        <ChangePassword />
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMainContacts } from '../../../../../entities/Main';
import { SocialCard } from '../../../../../widgets/SocialCard';
import './SocialBlock.scss';

interface SocialBlockProps {
  className?: string;
}

export const SocialBlock = ({ className }: SocialBlockProps) => {
  const { t } = useTranslation(['translation']);
  const { data } = useMainContacts();
  return (
    <div className={classNames('SocialBlock', className)}>
      <div className="SocialBlock__content">
        <h3>{t('main.contacts.title')}</h3>
        <p>{t('main.contacts.description')}</p>
      </div>
      <div className="SocialBlock__social">
        {data?.map((social) => (
          <SocialCard
            title={social.title}
            key={social.id}
            icon={social.image}
            url={social.url}
          />
        ))}
      </div>
    </div>
  );
};

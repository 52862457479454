import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LIMIT } from '../../../../shared/lib/const/limit';
import { ResponsePagination } from '../../../../models/Response';
import { TNotifications } from '../../../../models/Notifications';
import { getNotifications } from '../../../../api/auth';

export const useNotifications = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ResponsePagination<TNotifications> | null>(null);
  const [dataScroll, setDataScroll] = useState<TNotifications[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(
    (page?: number) => {
      setIsLoading(true);
      getNotifications(page)
        .then((res) => {
          page && setPage(page);
          setData(res);
          res?.results?.length &&
            setDataScroll(
              page !== 1 ? [...dataScroll, ...res.results] : res.results
            );
          res?.count && setCount(res.count);
        })
        .finally(() => setIsLoading(false));
    },
    [dataScroll, t]
  );

  return {
    dataScroll,
    data,
    currentPage,
    total,
    isLoading,
    count,
    fetchData,
    setPage,
    setDataScroll,
  };
};

import React, { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { getGoogleOAuthUrl } from '../../../api/auth';
import { generateGoogleRedirectUri } from '../SignIn/utils';
import { useTranslation } from 'react-i18next';

export type Props = {
  signIn: boolean;
};

export const GoogleAuth: React.FC<Props> = ({ signIn }) => {
  const [googleOAuthUrl, setGoogleOAuthUrl] = useState('');
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    (async () => {
      let oAuthUrl = await getGoogleOAuthUrl();
      oAuthUrl = `${oAuthUrl}&redirect_uri=${generateGoogleRedirectUri(signIn)}`;
      setGoogleOAuthUrl(oAuthUrl);
    })();
  }, []);

  return (
    <a href={googleOAuthUrl}>
      <div className="flex h-[44px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer md:h-[48px] dark:bg-navy-700 dark:text-white">
        <div className="rounded-full text-xl">
          <FcGoogle />
        </div>
        <p className="text-sm font-medium text-navy-700 dark:text-white">
          {signIn ? t('auth.signInG') : t('auth.signUpG')}
        </p>
      </div>
    </a>
  );
};

import React from 'react';
import { SelectOptions } from '../MMultSelect/MMultiSelect';
import './styles.scss';

type Props = {
  id?: string;
  label?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string, name?: string) => void;
  value?: string;
  bottomText?: string;
  bottomTextClassName?: string;
  name?: string;
  autoComplete?: string;
  labelClassName?: string;
  state?: 'error' | 'success' | 'gray' | 'default';
  leftIcon?: React.ReactNode;
  leftIconWrapperClassName?: string;
  onBlur?: (e: any) => void;
  autoFocus?: boolean;
  options: SelectOptions[];
  size?: 'sm' | 'md';
};

export const MSelect: React.FC<Props> = ({
  id,
  label,
  labelClassName,
  wrapperClassName,
  onChange,
  name,
  disabled,
  value,
//   placeholder,
  autoComplete,
  bottomText,
  bottomTextClassName,
  inputClassName,
  state = 'default',
  leftIcon,
  leftIconWrapperClassName,
  onBlur,
  autoFocus,
  options,
  size = 'md',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event.target.value, name);
  };

  return (
    <div className={`${wrapperClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className={`mb-[4px] ml-[16px] text-[14px] font-[500] text-gray-900 ${labelClassName} ${
            state === 'gray'
              ? 'text-gray-900'
              : state === 'success'
              ? 'text-green-700'
              : state === 'error'
              ? 'text-red-700'
              : ''
          }`}
        >
          {label}
        </label>
      )}
      <div className={'relative'}>
        {!!leftIcon && (
          <div
            className={`absolute left-[16px] top-[12px] ${leftIconWrapperClassName}`}
          >
            {leftIcon}
          </div>
        )}
        <select
          onChange={handleChange}
          disabled={disabled}
          value={value}
          id={id}
        //   placeholder={placeholder}
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onBlur={onBlur}
          className={`m-form-select flex min-w-[200px] appearance-none items-center justify-center rounded-[16px] border-[2px] border-secondaryGrey-500 bg-white bg-no-repeat px-[16px] text-[14px] font-[500] text-gray-700 outline-none placeholder:text-gray-500
          ${inputClassName} ${size === 'md' ? 'py-[12px]' : 'py-[8px]'} ${
            !!leftIcon && 'pl-[36px]'
          } ${
            disabled === true
              ? '!border-secondaryGrey-500 !bg-secondaryGrey-200 !text-secondaryGrey-600 placeholder:!text-secondaryGrey-400'
              : state === 'error'
              ? '!border-red-500 !text-red-600 placeholder:!text-red-400'
              : state === 'success'
              ? '!border-green-500 !text-green-600 placeholder:!text-green-400'
              : state === 'gray'
              ? '!placeholder:text-purple-700 !border-secondaryGrey-600 !text-purple-900'
              : ''
          }`}
        >
          {options.map((item, key) => (
            <option key={key} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {!!bottomText && (
        <p
          className={`ml-[16px] mt-[4px] text-[12px] font-[500] text-gray-500 ${bottomTextClassName} ${
            state === 'success'
              ? 'text-green-500'
              : state === 'error'
              ? 'text-red-500'
              : ''
          }`}
        >
          {bottomText}
        </p>
      )}
    </div>
  );
};

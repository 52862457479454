import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import WizImg from '../../../../../assets/images/wiz-block.png';
import { MButton } from '../../../../../horizon-components/MButton';
import './WizBlock.scss';
import { useNavigate } from 'react-router-dom';
import {
  MainRoutes,
  WizRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';

interface WizBlockProps {
  className?: string;
  description: string;
  title: string;
}

export const WizBlock = ({ className, description, title }: WizBlockProps) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const goTo = () => navigate(MainRoutes.wiz + WizRoutes.quizzes);
  return (
    <div className={classNames('WizBlock', className)}>
      <img src={WizImg} alt={t('main.learn_about_IQA')} />
      <div className="WizBlock__content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        className="sm-max:w-full lg:!max-h-fit lg:!px-11 lg:!py-5 lg:!text-2xl"
        onClick={goTo}
      >
        {t('buttons.Go_to_Wiz')}
      </MButton>
    </div>
  );
};

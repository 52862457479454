import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { MButton } from '../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import './PurchaseSchoolCard.scss';

interface PurchaseSchoolCardProps {
  className?: string;
  title?: string;
  isOpen?: boolean;
  isEnrolled?: boolean;
  children: React.ReactNode;
  onOpen?: () => void;
}

export const PurchaseSchoolCard = ({
  className,
  title,
  isOpen,
  isEnrolled,
  children,
  onOpen,
}: PurchaseSchoolCardProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const handleGoBuy = () => {
    navigate(MainRoutes.edu + EduRoutes.nft + `/${id}`);
  };

  return (
    <div className={classNames('PurchaseSchoolCard', className)}>
      <div className={classNames('PurchaseSchoolCard__head', { open: isOpen })}>
        <h3 onClick={onOpen}>{title}</h3>
        {isEnrolled ? (
          !isOpen && (
            <MButton variant="highlighted" color="primary" onClick={onOpen}>
              {t('buttons.Open')}
            </MButton>
          )
        ) : (
          <MButton variant="secondary" color="primary" onClick={handleGoBuy}>
            {t('buttons.Go_to_purchase')}
          </MButton>
        )}
      </div>
      {isOpen && <div className="PurchaseSchoolCard__content">{children}</div>}
    </div>
  );
};

import {
  useFavouritePurchasableSubjects,
  usePurchasableSubject,
  usePurchasedSubjects,
} from '@/entities/Subject';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getSubjectsList } from '../../../api/dictionaries';
import { DigitalPassMode } from '../../../api/digitalPass';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import { useBanners } from '../../../entities/Banners';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useUserContext } from '../../../providers';
import { Banners } from '../../../widgets/Banners';
import { SubjectCard } from '../../../widgets/SubjectCard';
import './styles.scss';

export const NFTList: React.FC = () => {
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedMode =
    (searchParams.get('mode') as DigitalPassMode) || DigitalPassMode.DISCOVER;
  const { data } = useBanners({ place_name_slug: 'digital_pass_list' });
  const { data: purchasable, isLoading: isLoadingPurchasable } =
    usePurchasableSubject();
  const { data: purchased, isLoading: isLoadingPurchased } = usePurchasedSubjects();
  const { data: favorites } = useFavouritePurchasableSubjects();

  useEffect(() => {
    getSubjectsList();
  }, [t]);

  const handleMode = (mode: DigitalPassMode) => {
    searchParams.set('mode', mode);
    setSearchParams(searchParams);
  };

  return (
    <div className={'b-nft-list'}>
      <div className="flex flex-col gap-2 md:gap-6">
        <h1 className={'g-main-title'}>{t('nft.digitalPass')}</h1>
        {!!data?.length && <Banners banners={data.slice(0, 1)} />}
        <div className={'flex items-center gap-3'}>
          <MButton
            onClick={() => handleMode(DigitalPassMode.DISCOVER)}
            variant={
              selectedMode === DigitalPassMode.DISCOVER ? 'highlighted' : 'secondary'
            }
            color="tab"
            size="sm"
            className="w-full md:w-auto"
          >
            {t('course.All')}
          </MButton>
          {user && (
            <MButton
              onClick={() => handleMode(DigitalPassMode.MY_PASSES)}
              variant={
                selectedMode === DigitalPassMode.MY_PASSES
                  ? 'highlighted'
                  : 'secondary'
              }
              color="tab"
              size="sm"
              className="w-full md:w-auto"
            >
              {t('course.myPasses')}
            </MButton>
          )}
        </div>
      </div>
      <div className={'mt-6'}>
        {isLoadingPurchasable || isLoadingPurchased ? (
          <Loader />
        ) : purchasable?.results?.length || purchased?.results?.length ? (
          <div
            className={'grid grid-cols-1 flex-wrap gap-4 md:grid-cols-2 md:gap-5'}
          >
            {(selectedMode === DigitalPassMode.DISCOVER
              ? purchasable?.results
              : purchased?.results
            )?.map((item) => (
              <SubjectCard
                data={item}
                key={item.id}
                isFavorite={
                  !!favorites?.find((favorite) => favorite === item?.id) || false
                }
              />
            ))}
          </div>
        ) : (
          <NotFound text={t('course.Coming_soon')} />
        )}
      </div>
    </div>
  );
};

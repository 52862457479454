import React, { useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import ReactAudioPlayer from 'react-audio-player';
import { Button } from '@mui/material';
import './styles.scss';

export type VTTConfigItem = { srcLang: string; src: string };

export interface IMediaViewerProps {
  link: string;
  extra?: string;
  bg?: string;
  vttFiles?: VTTConfigItem[];
  vttDownloaded?: boolean;
}

export const MediaViewer: React.FC<IMediaViewerProps> = ({
  link,
  extra,
  bg,
  vttFiles,
  vttDownloaded = true,
}) => {
  const [pressedPlay, setPressedPlay] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleVideoEnd = () => {
      setIsCollapsed(true);
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, []);

  const onPlay = () => setPressedPlay(false);

  const downloadFile = () => {
    saveAs(link);
  };

  const linkWithoutQuery = link?.split('?')[0];

  return (
    <div className={`mediaViewer ${extra}`}>
      <div
        className={`mediaViewer__content ${isCollapsed ? 'collapsed' : ''}`}
        style={{
          borderRadius: '20px',
          overflow: 'hidden',
        }}
      >
        {!!linkWithoutQuery ? (
          <>
            {linkWithoutQuery.match(/.*\.(m4v|avi|mg|mp4|webm)$/gim) &&
              vttDownloaded && (
                <div className="mediaViewer__movie">
                  <video
                    key={link}
                    width="100%"
                    height="auto"
                    onPlay={onPlay}
                    controls
                    crossOrigin={'anonymous'}
                    style={{
                      height: '100%',
                      borderRadius: '20px',
                      overflow: 'hidden',
                    }}
                    ref={videoRef}
                  >
                    <source src={link} />
                    {vttFiles?.map((item) => (
                      <track
                        label={item.srcLang}
                        kind="subtitles"
                        srcLang={item.srcLang === 'ru' ? 'ru' : 'en'}
                        src={item.src}
                        default={item.srcLang === 'ru'}
                      />
                    ))}
                  </video>
                </div>
              )}

            {linkWithoutQuery.match(/.*\.(wav|mp3)$/gim) && (
              <div className={`mediaViewer__audio`}>
                <ReactAudioPlayer
                  src={String(link)}
                  controls={true}
                  autoPlay={pressedPlay}
                  onPlay={onPlay}
                  style={{ width: '100%' }}
                />
              </div>
            )}

            {linkWithoutQuery.match(/.*\.(gif|jpe?g|png)$/gim) && (
              <img
                src={String(link)}
                className={`mediaViewer__img`}
                alt="Картинка"
              />
            )}

            {linkWithoutQuery.match(/.*\.(ppt|doc|docx|ai|psd|pdf)$/gim) && (
              <Button
                onClick={() => downloadFile()}
                variant={'outlined'}
                className={`mediaViewer__btn_download`}
              >
                Скачать
              </Button>
            )}
          </>
        ) : (
          <div
            className="mediaViewer__bg"
            style={{ backgroundImage: `url(${bg})` }}
          />
        )}
      </div>
    </div>
  );
};

import classNames from 'classnames';
import './SocialCard.scss';

interface SocialCardProps {
  className?: string;
  title?: string;
  icon?: string;
  url?: string;
}

export const SocialCard = ({ className, title, icon, url }: SocialCardProps) => {
  const goTo = () => window.open(url, '_blank');
  return (
    <div className={classNames('SocialCard', className)} onClick={goTo}>
      <img src={icon} alt={title} />
      {title}
    </div>
  );
};

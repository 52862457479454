/* eslint-disable @typescript-eslint/no-unused-vars */
import { MButton } from '@/horizon-components/MButton';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ThunderGif from '@/assets/images/wiz/thunder.gif';
import './style.scss';

interface QuizTestModalProps {
  className?: string;
  onStart?: () => void;
}

export const QuizTestModal = ({ className, onStart }: QuizTestModalProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('QuizTestModal', className)}>
      <img src={ThunderGif} alt="thunder" />
      <div className="flex flex-col gap-3">
        <h3>2:00:00</h3>
        <p>
          Энергия восстановится через 2 часа. Выполните разминку для глаз, отдохните
          от экрана.
        </p>
      </div>
      <div className="flex gap-2 md:gap-6">
        <MButton
          variant="highlighted"
          color="primary"
          className="md:!px-8 md:!py-6 md:!text-lg"
          onClick={onStart}
        >
          Крута
        </MButton>
        <MButton
          variant="secondary"
          color="primary"
          className="md:!px-8 md:!py-6 md:!text-lg"
          onClick={onStart}
        >
          Ускорить за 100 ERP
        </MButton>
      </div>
    </div>
  );
};

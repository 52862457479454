import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Subjects, getSubjectsList } from '../../../../api/dictionaries';
import { saveCurrentUrl } from '../../../../api/sessions';
import {
  CommunitiesRequest,
  CommunityOrderBy,
} from '../../../../api/weber/communities';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter/filter.svg';
import { ReactComponent as Search } from '../../../../assets/icons/search.16px.svg';
import useDebounce from '../../../../hooks/useDebounce';
import useHistoryParams from '../../../../hooks/useHistoryParams';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MainRoutes,
  WeberRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { TCommunity } from '../../../../models/Community';
import { useUserContext } from '../../../../providers';
import { Filter, FilterItems, FilterOption } from '../../../../widgets/Filter';

type CommunityFilterProps = {
  communityFeed?: TCommunity;
  title: string;
  onFilter?: (mode: any) => void;
  onSearch?: (mode: any) => void;
  onMode?: (mode: boolean) => void;
};

export const CommunityFilter: React.FC<CommunityFilterProps> = ({
  communityFeed,
  title,
  onFilter,
  onSearch,
  onMode,
}) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<boolean>(false);
  const { t } = useTranslation(['translation']);
  const [search, setSearch] = useState('');
  const debounced = useDebounce<string>(search, 500);
  const [defaultFilter, setDefaultFilterl] = useState<
    Omit<CommunitiesRequest, 'page'>
  >({ order_by: 'popularity' });
  const [defaultTitel, setDefaultTitel] = useState<{ [key: string]: string }>({});
  const { getParamFromUrl, addParamToUrl } = useHistoryParams();

  useEffect(() => onSearch?.({ name: search, my_groups: mode }), [debounced]);
  useEffect(() => {
    if (getParamFromUrl('name')) {
      setSearch(getParamFromUrl('name') || '');
      onSearch?.({ title: search });
    }
  }, []);

  useEffect(() => {
    getSubjectsList().then((data) => {
      data?.length &&
        setSubjects([
          ...data?.map((item) => ({
            id: item.id,
            title: item.title,
            slug: item.slug,
          })),
        ]);
    });

    handleMode(getParamFromUrl('my_groups') === 'true');
  }, [t]);

  const goToCreate = () => {
    if (!!user) {
      navigate(MainRoutes.weber + WeberRoutes['community-create'], {
        state: { communityIdFeed: communityFeed?.id },
      });
    } else {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
    }
  };

  const orderByOptions: FilterItems[] = useMemo(
    () =>
      Object.entries(CommunityOrderBy).map((key, index) => ({
        id: index + 1,
        title: t('sort.' + key[1].toLowerCase()),
        slug: key[0].toLowerCase(),
      })),
    [t]
  );

  const filterOption: FilterOption[] = useMemo(
    () => [
      {
        id: 1,
        title: t('buttons.sort_by'),
        items: orderByOptions,
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['order_by'],
      },
      {
        id: 2,
        title: t('buttons.subject'),
        items: subjects,
        variant: 'primary',
        type: 'array',
        component: 'badge',
        key: ['subject'],
      },
    ],
    [orderByOptions, subjects, t]
  );

  const handleMode = (mode: boolean) => {
    setMode(mode);
    onMode?.(mode);
    addParamToUrl('my_groups', mode);
  };

  const handleFilter = (filter: Omit<CommunitiesRequest, 'page'>) => {
    // setMode(mode);
    const filters = { ...filter, my_groups: mode };
    if (search) filters.name = search;
    onFilter?.(filters);
  };

  return (
    <div className="flex flex-col gap-[8px] md:gap-[24px]">
      <div className="flex justify-between gap-2">
        <h1 className={'g-main-title'}>{title}</h1>

        <div className="flex gap-[10px]">
          <MButton
            color="white"
            variant="highlighted"
            size="default"
            className="h-[42px] w-[42px] !px-[12px] shadow-sm shadow-shadow-600 sm:!hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            <FilterIcon />
          </MButton>
          <MButton
            color="primary"
            variant="highlighted"
            size="default"
            className="h-[42px] w-[42px] !px-[12px] shadow-sm shadow-shadow-600 sm:!hidden"
            onClick={goToCreate}
          >
            +
          </MButton>
        </div>
      </div>

      <div
        className={
          'relative flex w-full flex-col items-center justify-between gap-2 sm:flex-row'
        }
      >
        <div className={'flex w-full items-center gap-2 md:w-auto'}>
          <MButton
            variant={!mode ? 'highlighted' : 'secondary'}
            color="tab"
            size="sm"
            onClick={() => handleMode?.(false)}
            className="w-full md:w-auto"
          >
            {t('communities.discover')}
          </MButton>
          {!!user && (
            <MButton
              variant={mode ? 'highlighted' : 'secondary'}
              color="tab"
              size="sm"
              onClick={() => handleMode?.(true)}
              className="w-full md:w-auto"
            >
              {t('communities.following')}
            </MButton>
          )}
        </div>

        <Filter
          options={filterOption}
          onFilter={handleFilter}
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          defaultFilter={defaultFilter}
          defaultTitel={defaultTitel}
        />
      </div>

      <div className="flex w-full items-center justify-between gap-2 lg:w-auto">
        <MInput
          wrapperClassName={'w-full lg:max-w-[340px]'}
          placeholder={`${t('buttons.search')}...`}
          value={search}
          onChange={setSearch}
          inputClassName={
            'w-full !bg-[#F4F7FE] !border-[#F4F7FE] !placeholder-[#8F9BBA]'
          }
          leftIcon={<Search />}
        />
        <MButton
          color="primary"
          variant="outline"
          className="!hidden text-nowrap sm:!flex"
          onClick={goToCreate}
        >
          <span>{t('communities.create')}</span> +
        </MButton>
      </div>
    </div>
  );
};

export enum SortBy {
  POPULAR = 'POPULAR',
  LATEST = 'LATEST',
  RATING = 'RATING',
}

export interface FilterItems {
  id: number | string;
  title: string;
  slug: string | number;
}

export interface FilterOption {
  id: number;
  title: string;
  items?: FilterItems[];
  variant?: 'outline' | 'primary' | 'success' | 'danger' | 'secondary';
  settings?: {
    min: number;
    max: number;
    step: number;
  };
  type: 'single' | 'array';
  component: 'badge' | 'range';
  key: string[];
}

export enum FilterLanguages {
  EN = 'English',
  RU = 'Russian',
  KZ = 'Kazakh',
}

import { WIZ_API_URL } from '../instance';
import axios from 'axios';
import { User } from '../../models';
import { WizStatusEnum } from '../../models/Wiz';

export const WIZ_TOKEN_STORAGE_KEY = 'wiz-anonymous-token';

export type Answer = {
  id: number;
  text: string;
  is_correct_answer: boolean;
};

export type Category = {
  category_name: string;
  id: number;
  image: string;
};

export type Question = {
  text: string;
  id: number;
  question_number: number;
  answers: Answer[];
  category: Category;
};

export type DayResult = {
  day_of_week: number;
  user_answers: Answer[];
};

export type LeaderboardUser = {
  total_reward_amount: number;
  user: User;
};

const getHeaderWithToken = () => {
  const wizToken = localStorage.getItem(WIZ_TOKEN_STORAGE_KEY);
  return {
    headers: { Authorization: `Token ${wizToken}` },
  };
};

export function registerInWiz(jwtToken: string | null): Promise<string> {
  return axios
    .post(`${WIZ_API_URL}/register/`, {
      user_jwt_token: jwtToken,
    })
    .then((response) => response.data.key)
    .catch((error) => {
      throw error;
    });
}

export function startTest(): Promise<{
  key: string;
}> {
  return axios
    .post(`${WIZ_API_URL}/start-test/`, undefined, getHeaderWithToken())
    .then((response) => response.data.key)
    .catch((error) => {
      throw error;
    });
}

export function getStatus(): Promise<WizStatusEnum> {
  return axios
    .get(`${WIZ_API_URL}/wiz-status/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getQuestion(): Promise<null> {
  return axios
    .get(`${WIZ_API_URL}/get-question/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function submitAnswer(answerId?: number): Promise<null> {
  return axios
    .post(
      `${WIZ_API_URL}/submit-answer/`,
      answerId && { answer_id: answerId },
      getHeaderWithToken()
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function showResults(): Promise<DayResult[]> {
  return axios
    .get(`${WIZ_API_URL}/show-results/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyIqa(): Promise<number> {
  return axios
    .get(`${WIZ_API_URL}/get-my-iqa/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function claimReward(jwtToken: string): Promise<{ reward_amount: number }> {
  return axios
    .post(
      `${WIZ_API_URL}/claim-reward/`,
      { user_jwt_token: jwtToken },
      getHeaderWithToken()
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getIsRewardClaimed(): Promise<boolean> {
  return axios
    .get(`${WIZ_API_URL}/is-reward-claimed/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLeaderboardData(): Promise<LeaderboardUser[]> {
  return axios
    .get(`${WIZ_API_URL}/leaderboard/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function uploadResultToShare(form: FormData): Promise<{
  image: string;
}> {
  return axios
    .post(`${WIZ_API_URL}/upload-result-image/`, form, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
  } from 'react';
  import { useNavigate } from 'react-router-dom';
  import { UserProfile } from '../models';
  import { getUserStorage, removeUserStorage, saveUserStorage } from '../api';
  import { TempUserData } from '../api/auth';
  import {
    AuthRoutes,
    MainRoutes,
  } from '../horizon-layout/MainLayout/Routes/types/routes';
  
  type UserContextValue = {
    user: UserProfile | null;
    logout: () => void;
    saveUser: (user: UserProfile) => void;
    saveTempUserData: (user: TempUserData) => void;
    tempUserData: TempUserData | null;
  };
  
  const UserContext = createContext<UserContextValue>({} as UserContextValue);
  
  const UserProvider: React.FC<PropsWithChildren> = (props) => {
    const { children } = props;
    const navigate = useNavigate();
    const [user, setUser] = useState<UserProfile | null>(getUserStorage());
    const [tempUserData, setTempUserData] = useState<TempUserData | null>(null);
  
    const saveUser = useCallback((user: UserProfile) => {
      saveUserStorage(user);
      setUser(user);
    }, []);
  
    const saveTempUserData = useCallback((user: TempUserData) => {
      setTempUserData(user);
    }, []);
  
    const logout = useCallback(() => {
      removeUserStorage();
      setUser(null);
      navigate(MainRoutes.auth + AuthRoutes.signin);
    }, [navigate]);
  
    return (
      <UserContext.Provider
        value={{
          saveUser,
          logout,
          user,
          saveTempUserData,
          tempUserData,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  };
  
  const useUserContext = () => useContext(UserContext);
  
  export { UserProvider, UserContext, useUserContext };
  
import { useNavigate } from 'react-router-dom';
import { Favorite } from '../../../horizon-components/Favorite';
import { useUserContext } from '../../../providers';
import { useNFTFavorite } from '../../../entities/NFT';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { saveCurrentUrl } from '../../../api/sessions';

interface NFTFavoriteProps {
  className?: string;
  isFavorite?: boolean;
  favoriteId: number;
  digitalPassCatId: number;
}

export const NFTFavorite = ({
  className,
  isFavorite = false,
  favoriteId,
  digitalPassCatId,
}: NFTFavoriteProps) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { favorite, onFavorite, isLoading } = useNFTFavorite(isFavorite);

  const handleFavorite = () => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }

    onFavorite(favoriteId, digitalPassCatId);
  };

  return (
    <Favorite
      favorite={favorite}
      onFavorite={handleFavorite}
      className={className}
      isLoading={isLoading}
    />
  );
};

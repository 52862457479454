import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getDocsAgree } from '../../../api/auth';
import { MButton } from '../../../horizon-components/MButton';
import { TAgree } from '../../../models/Main';
import { useState } from 'react';
import './Cookie.scss';

interface CookieProps {
  className?: string;
}

type TAgreeKeys = keyof TAgree;

export const Cookie = ({ className }: CookieProps) => {
  const { t } = useTranslation(['translation']);
  const [coockie, setCookie] = useState(localStorage.getItem('cookie'));

  if (coockie === 'true') return null;

  const downloadFile = async (key: TAgreeKeys) => {
    try {
      // Получите ссылку на файл из API
      const data = await getDocsAgree();
      if (data && data?.results?.[0]?.[key]) {
        // Создайте ссылку для скачивания
        const a = document.createElement('a');
        a.href = data?.results?.[0]?.[key];
        a.target = '_blank';

        // Установите имя файла, если это необходимо
        a.download = `${key}.pdf`; // Измените имя файла и его расширение по необходимости

        // Имитация клика по ссылке
        document.body.appendChild(a);
        a.click();

        // Удалите ссылку после скачивания
        document.body.removeChild(a);
      } else {
        console.error('Invalid data received from API');
      }
    } catch (error) {
      console.error('Error fetching file link:', error);
    }
  };

  const hndlSetLoacal = () => {
    localStorage.setItem('cookie', 'true');
    setCookie('true');
  };

  return (
    <div className={classNames('Cookie', className)}>
      <p>
        {t('auth.We_use_cookies_on_our_website')}. {t('auth.Read_our')}{' '}
        <b onClick={() => downloadFile('cookies_policy')} className="cursor-pointer">
          {t('auth.Cookie_policy')}
        </b>
      </p>
      <MButton
        variant="secondary"
        color="primary"
        className="w-full !rounded-full md:w-auto"
        onClick={hndlSetLoacal}
      >
        {t('buttons.ok')}
      </MButton>
    </div>
  );
};

import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { MButton } from '../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { DigitalPassCategory, DigitalPasses } from '../../../models/DigitalPass';
import { SchoolCard } from '../../SchoolCard';
import './CourseBuyModal.scss';

interface CourseBuyModalProps {
  className?: string;
  img?: string;
  title?: string;
  description?: string;
  school?: DigitalPasses;
  pass?: DigitalPassCategory;
  onBuy?: () => void;
}

export const CourseBuyModal = ({
  className,
  img,
  title,
  description,
  school,
  pass,
  onBuy,
}: CourseBuyModalProps) => {
  const { t } = useTranslation(['translation']);

  const text = useMemo(() => {
    const arr = [];
    school?.level && arr.push(school.level + ` ${t('course.Grade')}`);
    school?.units_count && arr.push(school.units_count + ` ${t('course.units')}`);
    pass?.course_count && arr.push(pass.course_count + ` ${t('course.Courses')}`);
    return arr;
  }, [school, pass, t]);

  const link = useMemo(
    () =>
      pass?.id
        ? MainRoutes.edu +
          EduRoutes.nft +
          `/${pass?.id}` +
          EduRoutes.shcool +
          `/?school=${pass?.digital_passes?.[0].id}`
        : '',
    [pass?.digital_passes, pass?.id]
  );

  return (
    <div className={classNames('CourseBuyModal', className)}>
      {img && (
        <div className="md-max:hidden">
          <img src={img} alt={school?.name} />
        </div>
      )}
      <div className="CourseBuyModal__content">
        <div className="flex flex-col gap-3 md:gap-6">
          <div className="flex flex-col gap-3 md:gap-4">
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
          </div>

          {img && (
            <div className="flex items-center justify-center md:hidden">
              <img
                src={img}
                alt={school?.name}
                className="h-auto w-auto w-auto sm-max:max-h-64"
              />
            </div>
          )}

          {school?.id && (
            <SchoolCard
              link={link}
              first
              title={school.name}
              description={school.description}
              variant={text.join(', ')}
              showIcon={false}
              selected
            />
          )}
        </div>

        <div className="CourseBuyModal__btns">
          <Link to={link}>
            <MButton variant="secondary" color="primary" className="w-full">
              {t('buttons.open_subject_page')}
            </MButton>
          </Link>
          <MButton
            variant="highlighted"
            color="primary"
            className="w-full"
            onClick={onBuy}
          >
            {t('nft.buy_for')} ${school?.price}
          </MButton>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader';
import { NotFound } from '../../../../../components/NotFound/NotFound';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';
import { JoinStatus } from '../../../../../models/Community';
import { FolderCard } from '../../../../../widgets/FolderCard';
import { useFolders } from '../../../../../entities/Post/model/query/useFolders/useFolders';
import '../FeedList.scss';

type Props = {
  status?: JoinStatus;
};

export const Folders: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation(['translation']);
  const { data, isLoading, fetchData, count } = useFolders();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 flex-wrap content-start items-start gap-2 sm:grid-cols-2 sm:gap-4 md:grid-cols-3 md:gap-5">
        <Link to={MainRoutes.weber + WeberRoutes.favorites + `/${0}`}>
          <FolderCard
            title={t('feed.all_favorites')}
            count={`${count || 0} ${t('feed.posts')}`}
          />
        </Link>
        {data?.length ? (
          data?.map((folder) => (
            <Link
              to={MainRoutes.weber + WeberRoutes.favorites + `/${folder.id}`}
              key={folder.id}
            >
              <FolderCard
                key={folder.id}
                title={folder.title}
                count={`${folder.posts_count || 0} ${t('feed.posts')}`}
                img={folder.image}
              />
            </Link>
          ))
        ) : isLoading ? (
          <Loader />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

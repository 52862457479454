import React, { useEffect, useRef, useState } from 'react';
import { ProfileDropdown } from './ProfileDropdown';
import { Dropdown, DropdownRefs } from '../../horizon-components/Dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANGUAGES, Languages } from '../../i18n';
import { ReactComponent as Chat } from '../../assets/icons/menu/chat.svg';
import { ReactComponent as Coins } from '../../assets/icons/menu/coins.svg';
import { ReactComponent as Networking } from '../../assets/icons/menu/networking.svg';
import { ReactComponent as CommentsIcon } from '../../assets/icons/menu/comment.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/menu/notifications.svg';
import { ReactComponent as NotifyIcon } from '../../assets/icons/menu/notify.svg';
import { AuthRoutes, MainRoutes, ProfileRoutes } from './Routes/types/routes';
import { MTooltip } from '../../horizon-components/MTooltip/MTooltip';
import moment from 'moment';
import 'moment/locale/ru';
import { Notifications } from '../../features/Notifications';
import { getNotificationsConnect } from '../../api/socket/socket';
import { TNotifications } from '../../models/Notifications';
import useClickOutside from '../../hooks/useClickOutside';
import { toast } from 'react-toastify';
import { LanguageSelector } from './LanguageSelector';

type Props = {};

const NavbarMenu: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(['translation']);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isNewNotify, setIsNewNotify] = useState<boolean>(false);
  const [open, setRef, toggleOpen] = useClickOutside(false);

  useEffect(() => {
    getNotificationsConnect().then(setSocket);

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (message: MessageEvent<string>) => {
        const data = JSON.parse(message.data) as TNotifications;
        toast(data.title, { type: 'default' });
        setIsNewNotify(true);
      };
    }

    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket]);

  const goToChat = () =>
    navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat);
  const goToNetworking = () =>
    navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking);
  const goToMyAccount = () =>
    navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.wallet);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(
    () => localStorage.setItem('language', i18n.language as Languages),
    [i18n.language]
  );

  const handlNotify = () => {
    toggleOpen();
    setIsNewNotify(false);
  };

  return (
    <div className="relative mt-[3px] flex items-center justify-around gap-[14px] rounded-full bg-white px-[12px] py-[10px] text-sm font-bold text-secondary-grey-900 shadow-sm shadow-shadow-500 md:px-[16px] md:py-[11px] dark:!bg-navy-800 dark:shadow-none">
      <LanguageSelector />

      <div
        className={'mb-[2px] cursor-pointer md-max:!hidden'}
        id={'networking'}
        onClick={goToNetworking}
      >
        <Networking />
        <MTooltip anchorSelect={'#networking'} text={t('networking.title')} />
      </div>

      <div
        className={'relative mb-[2px] cursor-pointer'}
        onClick={goToChat}
        id={'chat'}
      >
        <Chat className="md-max:!hidden" />
        <CommentsIcon className="fill-primary-purple-blue-200 md:!hidden" />
        <MTooltip anchorSelect={'#chat'} text={t('chat.title')} />
      </div>

      <div
        className={'mb-[2px] cursor-pointer md-max:!hidden'}
        id={'coins'}
        onClick={goToMyAccount}
      >
        <Coins />
        <MTooltip anchorSelect={'#coins'} text={t('wallet.my')} />
      </div>

      <div className={'mb-[2px] cursor-pointer sm:relative'} id={'notify'}>
        {isNewNotify && (
          <div className="absolute right-0.5 top-0.5 h-[5px] w-[5px] rounded-full bg-primary-purple-blue-500" />
        )}
        <NotificationsIcon onClick={handlNotify} className="md-max:!hidden" />
        <NotifyIcon
          onClick={handlNotify}
          className="fill-primary-purple-blue-200 md:!hidden"
        />
        <MTooltip anchorSelect={'#notify'} text={t('notifications.title')} />
        {open && (
          <div ref={setRef}>
            <Notifications
              className="absolute right-0 top-12"
              onClose={handlNotify}
            />
          </div>
        )}
      </div>

      <ProfileDropdown />
    </div>
  );
};

export default NavbarMenu;

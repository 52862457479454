import { useState, useEffect, useRef } from 'react';

function useClickOutside(
  initialState: boolean = false
): [boolean, (node: HTMLElement | null) => void, () => void] {
  const [open, setOpen] = useState<boolean>(initialState);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setRef = (node: HTMLElement | null) => {
    ref.current = node;
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return [open, setRef, toggleOpen];
}

export default useClickOutside;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoursesFilter } from './CoursesFilter';
import { CourseRequest, CoursesMode } from '../../../api/course';
import { Recomended } from './Recomended';
import { AllCourses } from './AllCourses';
import { StartedCourses } from './StartedCourses';
import { CoursesGetStarted } from './CoursesGetStarted';
import { useUserContext } from '../../../providers';

export const Main: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [selectedMode, setSelectedMode] = useState<CoursesMode>(
    CoursesMode.DISCOVER
  );
  const [filters, setFilters] = useState<Omit<CourseRequest, 'page'>>({});

  const handleMode = (mode: CoursesMode) => {
    setSelectedMode(mode);
    setFilters({ ...filters, mode });
  };

  const handleFilter = (filter: Omit<CourseRequest, 'page'>) => {
    setFilters({ mode: filters.mode, ...filter });
  };

  return (
    <div className="flex flex-col gap-[8px] md:gap-[24px]">
      <CoursesFilter
        onMode={handleMode}
        selectedMode={selectedMode}
        onFilter={handleFilter}
        title={t('course.Courses')}
      />

      {selectedMode === CoursesMode.DISCOVER && (
        <>
          {!!user && <Recomended />}
          {!!Object.keys(filters).length && <AllCourses filters={filters} />}
        </>
      )}
      {selectedMode === CoursesMode.MY_COURSES && (
        <>
          {!!user && <StartedCourses />}
          {!!Object.keys(filters).length && <CoursesGetStarted filters={filters} />}
        </>
      )}
    </div>
  );
};

import { useCallback, useEffect, useState } from 'react';
import {
  addFavoriteDigitalPass,
  deleteFavoriteDigitalPass,
} from '../../../../../api/digitalPass';
import { useFavouritePurchasableSubjects } from '@/entities/Subject';

export const useNFTFavorite = (fav: boolean) => {
  const [favorite, setFavorite] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { refetch } = useFavouritePurchasableSubjects();

  const onFavorite = useCallback(
    (digitalPassId: number, digitalPassCategory: number) => {
      setIsLoading(true);
      (favorite
        ? deleteFavoriteDigitalPass(digitalPassCategory)
        : addFavoriteDigitalPass(digitalPassId, digitalPassCategory)
      )
        .then(() => setFavorite(!favorite))
        .finally(() => {
          setIsLoading(false);
          refetch();
        });
    },
    [favorite]
  );

  useEffect(() => {
    setFavorite(fav);
  }, [fav]);

  return {
    onFavorite,
    isLoading,
    favorite,
  };
};

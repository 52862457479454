/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScienceImg from '@/assets/images/education/science.png';
import './style.scss';
import { Modal } from '@/horizon-components/Modal';
import { QuizTestModal } from '../QuizTestModal';

interface QuizCategoriesProps {
  className?: string;
  onTest?: () => void;
}

export const QuizCategories = ({ className, onTest }: QuizCategoriesProps) => {
  const { t } = useTranslation(['translation']);
  const [active, setActive] = useState(0);
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [category, setCategory] = useState('');

  const hndlSelect = (category: string) => {
    setCategory(category);
    hndlOpenStartModal();
  };

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  const hndlStart = () => {
    onTest?.();
    setIsStartOpen(!isStartOpen);
  };

  return (
    <div className={classNames('QuizCategories', className)}>
      <div className="QuizCategories__nav">
        <div
          className={classNames({ active: active === 0 })}
          onClick={() => setActive(0)}
        >
          Простой
        </div>
        <div
          className={classNames({ active: active === 1 })}
          onClick={() => setActive(1)}
        >
          Средний
        </div>
        <div
          className={classNames({ active: active === 2 })}
          onClick={() => setActive(2)}
        >
          Сложный
        </div>
      </div>
      <div className="QuizCategories__content">
        <div onClick={() => hndlSelect('category')}>
          <img src={ScienceImg} alt="Общие знания" />
          <h5>Общие знания</h5>
        </div>
        <div onClick={() => hndlSelect('category')}>
          <img src={ScienceImg} alt="Общие знания" />
          <h5>Общие знания</h5>
        </div>
        <div onClick={() => hndlSelect('category')}>
          <img src={ScienceImg} alt="Общие знания" />
          <h5>Общие знания</h5>
        </div>
        <div onClick={() => hndlSelect('category')}>
          <img src={ScienceImg} alt="Общие знания" />
          <h5>Общие знания</h5>
        </div>
        <div onClick={() => hndlSelect('category')}>
          <img src={ScienceImg} alt="Общие знания" />
          <h5>Общие знания</h5>
        </div>
        <div onClick={() => hndlSelect('category')}>
          <img src={ScienceImg} alt="Общие знания" />
          <h5>Общие знания</h5>
        </div>
      </div>
      <Modal isOpen={isStartOpen} onClose={hndlOpenStartModal} showClose={false}>
        <QuizTestModal onStart={hndlStart} />
      </Modal>
    </div>
  );
};

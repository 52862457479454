import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMainPossibilities } from '../../../../../entities/Main';
import { PossibilityCard } from '../../../../../widgets/PossibilityCard';
import './Possibilities.scss';

interface PossibilitiesProps {
  className?: string;
}

export const Possibilities = ({ className }: PossibilitiesProps) => {
  const { t } = useTranslation(['translation']);
  const { data } = useMainPossibilities();

  return (
    <div className={classNames('Possibilities', className)}>
      <h2>{t('main.possibilities.title')}</h2>
      <div className="Possibilities__wrapper">
        {data?.map((possibility) => (
          <PossibilityCard
            description={possibility.text}
            title={possibility.title}
            icon={possibility.image}
            key={possibility.id}
          />
        ))}
      </div>
    </div>
  );
};

import { useNavigate, useLocation } from 'react-router-dom';

interface Params {
  [key: string]: string | number | boolean;
}

interface HistoryParams {
  addParamToUrl: (paramName: string, paramValue: string | number | boolean) => void;
  removeParamFromUrl: (paramName: string) => void;
  updateParamInUrl: (
    paramName: string,
    paramValue: string | number | boolean
  ) => void;
  clearParamsFromUrl: () => void;
  updateAllParamsInUrl: (params: { [key: string]: any }) => void;
  getParamFromUrl: (params: string) => string | null;
  getAllParamsFromUrl: () => { [key: string]: string };
}

function useHistoryParams(): HistoryParams {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const searchParams = new URLSearchParams(location.search);

  const addParamToUrl = (
    paramName: string,
    paramValue: string | number | boolean
  ) => {
    searchParams.set(paramName, paramValue.toString());
    navigate(`${pathname}?${searchParams}`);
  };

  const removeParamFromUrl = (paramName: string) => {
    searchParams.delete(paramName);
    navigate(`${pathname}?${searchParams}`);
  };

  const updateParamInUrl = (
    paramName: string,
    paramValue: string | number | boolean
  ) => {
    if (searchParams.has(paramName)) {
      searchParams.set(paramName, paramValue.toString());
      navigate(`${pathname}?${searchParams}`);
    }
  };

  const clearParamsFromUrl = () => {
    searchParams.forEach((_, key) => {
      searchParams.delete(key);
    });
    navigate(`${pathname}`);
  };

  const updateAllParamsInUrl = (params: { [key: string]: any }) => {
    Object.entries(params).map(
      (item) => item?.[1] && searchParams.set(item[0], item[1].toString())
    );
    const newPath = `${pathname}?${searchParams.toString()}`;
    navigate(newPath);
  };

  const getParamFromUrl = (paramName: string) => {
    if (searchParams.has(paramName)) {
      return searchParams.get(paramName);
    }
    return null;
  };

  const getAllParamsFromUrl = () => {
    const result: { [key: string]: any } = {};

    const entries = Array.from(searchParams.entries());

    for (const [key, value] of entries) {
      const parsedValue =
        value === 'true' ? true : value === 'false' ? false : value;

      if (key) {
        if (Array.isArray(value?.split(',')) && value?.split(',')?.length > 1) {
          result[key] = value?.split(',');
        } else {
          result[key] = parsedValue;
        }
      } else {
        result[key] = parsedValue;
      }
    }

    return result;
  };

  return {
    addParamToUrl,
    removeParamFromUrl,
    updateParamInUrl,
    clearParamsFromUrl,
    updateAllParamsInUrl,
    getParamFromUrl,
    getAllParamsFromUrl,
  };
}

export default useHistoryParams;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { Auction } from '../../../models/Auction';
import { Switch } from '../../../horizon-components/Switch/Switch';

type Props = {
  auction: Auction | null;
  onChange?: () => void;
  checked?: boolean;
};

export const Timer: React.FC<Props> = ({ auction, onChange, checked = false }) => {
  const { t } = useTranslation(['translation']);
  const [time, setTime] = useState<Moment>(moment());

  useEffect(() => {
    const id = setInterval(() => {
      setTime(
        auction?.status !== 'planned'
          ? moment(moment(auction?.end_datetime).diff(moment()))
          : moment(moment(auction?.start_datetime).diff(moment()))
      );
    }, 1000);
    return () => clearInterval(id);
  }, [auction?.end_datetime, auction?.start_datetime, auction?.status]);

  return (
    <div
      className={
        'mb-4 flex flex-col items-center justify-center gap-2 self-stretch rounded-2xl bg-secondaryGrey-300 p-4 '
      }
    >
      <p className={'text-center text-[14px] font-[500] text-secondaryGrey-800'}>
        {auction?.status === 'active' ? t('bid.end') : t('bid.start')}
      </p>
      <div className={'flex w-full justify-between'}>
        <div className={'w-[30%]'}>
          <p className={'text-center text-[20px] font-[600] text-secondaryGrey-900'}>
            {time.utc().format('HH')}
          </p>
          <p className={'text-center text-[12px] font-[600] text-secondaryGrey-700'}>
            {t('bid.hours')}
          </p>
        </div>
        <div className={'w-[5%]'}>
          <p className={'text-center text-[20px] font-[600] text-secondaryGrey-900'}>
            :
          </p>
        </div>
        <div className={'w-[30%]'}>
          <p className={'text-center text-[20px] font-[600] text-secondaryGrey-900'}>
            {time.utc().format('mm')}
          </p>
          <p className={'text-center text-[12px] font-[600] text-secondaryGrey-700'}>
            {t('bid.minutes')}
          </p>
        </div>
        <div className={'w-[5%]'}>
          <p className={'text-center text-[20px] font-[600] text-secondaryGrey-900'}>
            :
          </p>
        </div>
        <div className={'w-[30%]'}>
          <p className={'text-center text-[20px] font-[600] text-secondaryGrey-900'}>
            {time.utc().format('ss')}
          </p>
          <p className={'text-center text-[12px] font-[600] text-secondaryGrey-700'}>
            {t('bid.seconds')}
          </p>
        </div>
      </div>
      <hr className="h-px self-stretch rounded-full bg-primary-purple-blue-100 opacity-50" />
      <div className="flex items-center justify-center gap-2 self-stretch">
        <h4 className="flex-[1_0_0] overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-semibold text-secondary-dark-grey-900">
          {t('bid.Receive_notifications')}
        </h4>
        <Switch onChange={onChange} checked={checked} color="blue" />
      </div>
    </div>
  );
};

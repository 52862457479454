import { useTranslation } from 'react-i18next';
import { useCommunity } from '../../../../../entities/Communities';
import { AsideCard } from '../../../../../widgets/AsideCard';
import { AvatarTitleTextCard } from '../../../../../widgets/AvatarTitleTextCard';
import { CommunityFollow } from '../../../../../features/CommunityFollow';
import { useNavigate } from 'react-router-dom';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';

export const FeedCommunity = ({ groupId }: { groupId: number }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const { data: сommunity } = useCommunity(groupId);

  if (!сommunity?.id) return null;

  const goTo = () =>
    navigate(MainRoutes.weber + WeberRoutes.community + `/${сommunity.id}`);

  return (
    <AsideCard title={t('feed.community')}>
      <div className="FeedView__card">
        <AvatarTitleTextCard
          title={сommunity?.name}
          text={[`${сommunity?.subject}`, `${сommunity?.members_count} members`]}
          img={сommunity?.custom_image || сommunity?.predefined_image.image}
          onClick={goTo}
        ></AvatarTitleTextCard>
        <div className="ml-auto flex justify-end">
          <CommunityFollow
            communityId={сommunity?.id}
            join_status={сommunity?.join_status}
            type={сommunity.type}
            afterClicked={goTo}
          />
        </div>
      </div>
    </AsideCard>
  );
};

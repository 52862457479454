import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './EducationCard.scss';

interface EducationCardProps {
  className?: string;
  title?: string;
  icon?: string;
  count?: number;
}

export const EducationCard = ({
  className,
  title,
  icon,
  count,
}: EducationCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('EducationCard', className)}>
      <h4>{title}</h4>
      <img src={icon} alt={title} />
      <span>{count} courses</span>
    </div>
  );
};

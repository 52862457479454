import React, { useMemo, useState } from 'react';
import { MInput } from '../../../horizon-components/MInput/MInput';
import { useTranslation } from 'react-i18next';
import useForm, { ErrorMessages } from '../../../hooks/useForm';
import * as yup from 'yup';
import { MButton } from '../../../horizon-components/MButton';
import { winnerClaimCreate } from '../../../api/bid';
import { Auction } from '../../../models/Auction';

type WinnerFormData = {
  email: string;
  phone: string;
};

type Props = {
  auctionId: number;
  auction: Auction;
  onClose: () => void;
};

export const WinnerForm: React.FC<Props> = ({ auctionId, onClose, auction }) => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(ErrorMessages.email)
          .required(ErrorMessages.required),
        phone: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  const { values, onChange, errors, validate } = useForm<WinnerFormData>({
    email: '',
    phone: '',
  });

  const handleSubmit = async () => {
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    setIsSending(true);
    winnerClaimCreate(auctionId, values.email, values.phone)
      .then(() => {
        // @ts-ignore
        confetti({
          particleCount: 150,
          spread: 60,
        });
        onClose();
      })
      .finally(() => setIsSending(false));
  };

  return (
    <div>
      <p
        className={
          'mb-[10px] text-center text-[18px] font-bold text-secondaryGrey-900'
        }
      >
        {t('bid.You’reWinner')}
      </p>
      <p className={'mb-[10px] text-center text-[18px] font-bold text-purple-500'}>
        {auction.title}
      </p>
      <p
        className={
          'mb-[10px] text-center text-[16px] font-[600] text-secondaryGrey-900'
        }
      >
        {t('bid.form')}
      </p>
      <MInput
        label={t('bid.email')}
        placeholder={t('bid.emailPl')}
        id="email"
        name={'email'}
        type="text"
        bottomText={errors.email}
        state={errors.email ? 'error' : 'default'}
        value={values.email}
        onChange={onChange}
        inputClassName={'w-full leading-[18px] mb-[10px]'}
        wrapperClassName={'w-full'}
      />
      <MInput
        label={t('bid.phone')}
        placeholder={t('bid.phonePl')}
        id="phone"
        name={'phone'}
        type="text"
        bottomText={errors.phone}
        state={errors.phone ? 'error' : 'default'}
        value={values.phone}
        onChange={onChange}
        inputClassName={'w-full leading-[18px] mb-[16px]'}
        wrapperClassName={'w-full'}
      />
      <div className={'flex justify-end'}>
        <MButton
          variant={'highlighted'}
          color={'primary'}
          disabled={isSending}
          onClick={handleSubmit}
        >
          {t('bid.send')}
        </MButton>
      </div>
    </div>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMainContent } from '../../../../../api/auth';
import { TMainContent } from '../../../../../models/Main';

export const useMainContent = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<TMainContent>({
    community: '',
    education: '',
    how_to_earn_money: '',
    id: 0,
    iqa: '',
    science: '',
    science_dao: '',
    carousel_about_us: '',
    carousel_goal: '',
    carousel_mission: '',
    community_title: '',
    how_to_earn_money_title: '',
    iqa_title: '',
    science_create_description: '',
    science_create_title: '',
    science_dao_title: '',
    science_research_description: '',
    science_research_title: '',
    science_share_description: '',
    science_share_title: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getMainContent()
      .then((res) => setData(res))
      .finally(() => setIsLoading(false));
  }, [t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
  };
};

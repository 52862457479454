import { useCallback, useEffect, useState } from 'react';
import { UserSocialMeta } from '../../../../../models';
import { setSocialMeta } from '../../../../../api/auth';
import { ResponsePagination } from '../../../../../models/Response';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';

export const useSocialMeta = (
  userGuid?: string,
  userEmail?: string,
  id?: string
) => {
  const [data, setData] = useState<ResponsePagination<UserSocialMeta>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(() => {
    if (userGuid === 'undefined') return;
    setIsLoading(true);
    setSocialMeta(page, userGuid, userEmail, id).then((res) => {
      setData(res);
      res !== undefined && setIsLoading(false);
    });
  }, [id, page, userEmail, userGuid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, page, total, isLoading, setPage };
};

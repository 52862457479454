import { useCallback, useEffect, useState } from 'react';
import { getPublicFriendships } from '../../../../../api/weber/friendship';
import { Friendship } from '../../../../../models/Friendship';
import { ResponsePagination } from '../../../../../models/Response';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';
import { toast } from 'react-toastify';
import getErrorMessages from '../../../../../utils/getErrorMessages';

export const usePublicFriendships = (
  userGuid?: string,
  accepted?: boolean,
  fromUser?: boolean,
  username?: string
) => {
  const [data, setData] = useState<ResponsePagination<Friendship>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(() => {
    if (userGuid === 'undefined') return;
    setIsLoading(true);
    getPublicFriendships(page, userGuid, accepted, fromUser, username)
      .then((res) => {
        setData(res);
        res !== undefined && setIsLoading(false);
      })
      .catch((error) => {
        setData(undefined);
        setIsLoading(false);
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      });
  }, [accepted, fromUser, page, userGuid, username]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    page,
    total,
    isLoading,
    setPage,
  };
};

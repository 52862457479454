import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as LinkIcon } from '../../../assets/icons/link.svg';
import { NoAvatar } from '../../NoAvatar';
import './ProfilePostCard.scss';

interface ProfilePostCardProps {
  className?: string;
  img?: string;
  title?: string;
  url: string;
}

export const ProfilePostCard = ({
  className,
  img,
  title,
  url,
}: ProfilePostCardProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div className={classNames('ProfilePostCard', className)}>
      <div className="ProfilePostCard__img">
        <Link to={url}>
          <NoAvatar src={img} alt={title} isPost />
        </Link>
      </div>
      <div className="ProfilePostCard__content">
        <Link to={url}>
          <h6>{title}</h6>
        </Link>
        <div className="flex gap-[6px]">
          <span>{t('profile.Post')}</span>
          <span className="!font-semibold">•</span>
          <Link to={url}>{t('buttons.See_details')}</Link>
        </div>
      </div>
      <Link to={url}>
        <div className="ProfilePostCard__icon">
          <LinkIcon />
        </div>
      </Link>
    </div>
  );
};

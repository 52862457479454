import { useUserContext } from '../../../../providers';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { useTranslation } from 'react-i18next';

const Logout: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { logout } = useUserContext();

  return (
    <div className={'flex w-full items-center justify-between'}>
      <h3 className="text-xl font-semibold text-secondary-grey-900">
        {t('buttons.Log_Out')}
      </h3>
      <MButton color={'danger'} variant="highlighted" onClick={logout}>
        {t('buttons.Log_Out')}
      </MButton>
    </div>
  );
};

export default Logout;

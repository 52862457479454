import { Owner, User } from './User';
import { Dictionaries } from '../api/dictionaries';
import { VideoTranslationItem } from '../modules/Edu/CourseCreation/Unit/VideoTranslation';
import { number } from 'prop-types';

export enum MyCourseEnum {
  Started = 'started',
}

export type UnitItem = {
  id: number;
  course: number;
  started_exam: number | null;
  unit: {
    id: number;
    title: string;
    subject_level: number;
  };
  topics: Topic[];
  expand?: boolean;
  completed: UnitExamResult[];
};

export type TopicContent = {
  resourcetype: 'TextContent' | 'FileContent';
  id: number;
  text?: string;
  file?: string;
  videoTranslations?: VideoTranslationItem[];
};

export type Topic = {
  id: number;
  title: string;
  unit: number;
  contents: TopicContent[];
  questions?: TTopicQuestionItem[];
};

export type CourseCardItem = {
  cover_image: string;
  id: number;
  level: number;
  introduction_files: ImageItem[];
  is_in_favorites: boolean;
  owner: Owner;
  enrolls_count: number;
  units_count: number;
  average_rating: number;
  subject: {
    id: number;
    title: string;
    slug: string;
  };
  digital_pass: {
    level: number;
    title: string;
  };
  title: string;
  units: {
    id: number;
    unit: {
      title: string;
    };
    topics: {
      title: string;
    }[];
    completed: UnitExamResult[];
  }[];
  status: CourseCardStatus;
};

export enum CourseCardStatus {
  CAN_ENROLL = 'CAN_ENROLL',
  PREVIEW = 'PREVIEW',
  ENROLLED = 'ENROLLED',
}

export type MyCourseCardItem = {
  course: CourseCardItem;
  id: number;
  status: MyCourseEnum;
};

export enum CourseStatus {
  Draft = 'draft',
  Unverified = 'unverified',
  Verified = 'verified',
  Rejected = 'rejected',
}

export type Unit = {
  id: number;
  title: string;
  topics: {
    id: number;
    title: string;
  }[];
  completed: UnitExamResult[];
  unit: {
    id: number;
    title: string;
    subject_level: number;
    title_en: string;
    title_ru: string;
  };
};

type NestedUnit = {
  id: number;
  unit: Unit;
};

export type Rejection = {
  created_at: string;
  id: number;
  text: string;
};

export type Course = {
  id: number;
  owner: User;
  owner_guid: string;
  status: string;
  course_status: string;
  title: string;
  level: string;
  level_id: number;
  subject: {
    id: number;
    title: string;
    slug: string;
  };
  subject_level: {
    id: number;
    title: string;
    subject: number;
  };
  subject_level_units: [
    {
      id: number;
      title: string;
      subject_level: number;
    },
  ];
  language: string;
  description: string;
  cover_image: string;
  cover_image_predefined: number;
  introduction_files: ImageItem[];
  requirements: string;
  learning_outcomes: string;
  intended_roles: string;
  welcome_message: string;
  congratulations_message: string;
  created_at: string;
  units: Unit[];
  digital_pass?: {
    id: string;
    title: string;
    category_id: number;
    category_title: string;
    level: number;
  };
  enrolls_count: number;
  rating: number;
};

export type ImageItem = {
  id: number;
  file: string;
  image: string;
  isRemote?: boolean;
};

export type TAnswerItem = {
  id?: number;
  text: string;
  correct: boolean;
  is_multiple_answers?: boolean;
};

export type TQuestionItem = {
  uuid?: string;
  id?: number;
  text: string;
  unit_answers: TAnswerItem[];
};

export type CourseUnitQuestion = {
  course_unit_id: number;
  questions: TQuestionItem[];
};

export type TTopicQuestionItem = {
  id: number;
  uuid?: string;
  text: string;
  topic_answers: TAnswerItem[];
  is_multiple_answers: boolean;
};

export type CourseTopicQuestion = {
  course_topic_id: number;
  questions: TTopicQuestionItem[];
};

export type ExamAnswerVariant = {
  id: number;
  text: string;
  correct?: boolean;
};

export type UnitExamQuestion = {
  id: number;
  text: string;
  is_multiple_answers: boolean;
  unit_answers: ExamAnswerVariant[];
};

export type StartedExam = {
  id: number;
  unit: {
    id: number;
    questions: UnitExamQuestion[];
  };
};

export type Grade = 'A' | 'B' | 'C' | 'D' | 'F';

export type UnitExamResult = {
  id: number;
  grade: Grade;
  percentage_correct: string;
  gpa: string;
  er_points_income: string;
};

export type UploadTopicImage = {
  id: number;
  file: string;
  topic: number;
};

export type TCourseGPA = {
  id: number;
  grade: Grade;
  gpa: number;
  percentage_correct: number;
  er_points_income: number;
  course_unit: {
    id: number;
    unit_id: string;
    unit_title: string;
  };
};

export type TSummary = {
  data: TCourseGPA[];
  total_grade: Grade;
};

export type TMailing = {
  id: number;
  children_guid: string;
  parent_email: string;
  created_at: string;
};

import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  bidRoutes,
  eduRoutes,
  mainRoutes,
  userRoutes,
  weberRoutes,
  wizRoutes,
} from './routes';
import { Sidebar } from './Sidebar';
import { Portal } from '@chakra-ui/portal';
import Navbar from './Navbar';
import { EduRouter } from './Routes/EduRouter';
import { WeberRouter } from './Routes/WeberRouter';
import { AuthRouter } from './Routes/AuthRouter';
import { useTranslation } from 'react-i18next';
import { WizRouter } from './Routes/WizRouter';
import { Feedback } from '../../horizon-components/Feedback/Feedback';
import { AuthRoutes, EduRoutes, MainRoutes } from './Routes/types/routes';
import { MobileNavBar } from '../../features/MobileNavBar';
import { Footer } from '../../widgets/Footer';
import { BidRouter } from './Routes/BidRouter';
import { useUserContext } from '../../providers';

export const MainLayout: React.FC = () => {
  const location = useLocation();
  const [open, setOpen] = useState(!(window.innerWidth < 1200));
  const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState(false);
  const [hovered, setHovered] = useState(true);
  const [isShow, setIsShow] = useState(true);
  const [currentRoute, setCurrentRoute] = useState('Main Dashboard');
  const [mini] = useState(true);
  const { t } = useTranslation(['translation']);
  const { pathname } = useLocation();
  const { user } = useUserContext();

  useEffect(() => {
    const urls = [
      MainRoutes.auth + AuthRoutes.signin,
      MainRoutes.auth + AuthRoutes.signup,
      MainRoutes.auth + AuthRoutes.reset,
      MainRoutes.auth + AuthRoutes.change,
      MainRoutes.auth + AuthRoutes.code,
      MainRoutes.edu + EduRoutes.edit,
    ];
    if (urls.findIndex((url) => location.pathname.indexOf(url) > -1) > -1) {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    document.documentElement.dir = 'ltr';
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const isMyAccountPage = pathname.indexOf('edu/account') > -1;
  const isNetworkingPage = pathname.indexOf('networking') > -1;
  const isServicesPage = pathname.indexOf('services') > -1;
  const isWizPage = pathname.indexOf('wiz') > -1;
  const hideSidebar =
    pathname.startsWith(MainRoutes.auth) ||
    isMyAccountPage ||
    isNetworkingPage ||
    isServicesPage ||
    isWizPage;

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes?.[i]?.items || []);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          setCurrentRoute(routes[i].name);
        }
      }
    }
    return activeRoute;
  };

  const routes: RoutesType[] = useMemo(() => {
    if (location.pathname.indexOf(MainRoutes.edu) > -1) {
      return userRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
        items: item?.items
          ?.filter((el) => (!!el?.isStaffRoute ? !!user?.is_staff : true))
          .map((el) => ({
            ...el,
            name: t(`menu.${el.name}`),
          })) as unknown as RoutesType[],
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.weber) > -1) {
      return weberRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
        items: item.items?.map((item) => ({
          ...item,
          name: t(`menu.${item.name}`),
        })) as unknown as RoutesType[],
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.wiz) > -1) {
      return wizRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.bid) > -1) {
      return bidRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
      })) as RoutesType[];
    }

    return mainRoutes.map((item) => ({
      ...item,
      name: t(`menu.${item.name}`),
    }));
  }, [location.pathname, t]);

  React.useEffect(() => {
    getActiveRoute(routes as RoutesType[]);
    // eslint-disable-next-line
  }, [location.pathname]);

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes?.[i]?.items || []);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return !!routes?.[i]?.secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return <Route path={`${prop.path}`} element={prop.component} key={key} />;
      }
      if (prop.collapse) {
        return getRoutes(prop.items || []);
      }
      return null;
    });
  };

  const openFeedbackModal = () => setFeedbackModalIsOpen(true);
  const closeFeedbackModal = () => setFeedbackModalIsOpen(false);

  return (
    <div className="flex h-full w-full bg-background-100 dark:bg-background-900">
      <Portal>
        <Navbar />
      </Portal>
      {!hideSidebar && (
        <Sidebar
          open={open}
          hovered={hovered}
          setHovered={setHovered}
          onClose={() => setOpen(false)}
          mini={mini}
          routes={routes as RoutesType[]}
          openFeedback={openFeedbackModal}
        />
      )}
      {/* Navbar & Main Content */}
      <div className="h-full w-full dark:bg-navy-900">
        {/* Main Content */}
        <main
          className={`flex min-h-screen flex-none flex-col transition-all dark:bg-navy-900 md:mx-2.5 ${
            hideSidebar
              ? 'xl:mx-7'
              : !mini
              ? 'xl:ml-[253px]'
              : mini && hovered
              ? 'xl:ml-[253px]'
              : 'ml-0 xl:ml-[142px]'
          } `}
        >
          <div className={'mt-[35px]'} />
          <Routes>
            <Route path={MainRoutes.auth + '/*'} element={<AuthRouter />} />
            <Route path={MainRoutes.edu + '/*'} element={<EduRouter />} />
            <Route path={MainRoutes.weber + '/*'} element={<WeberRouter />} />
            <Route path={MainRoutes.wiz + '/*'} element={<WizRouter />} />
            <Route path={MainRoutes.bid + '/*'} element={<BidRouter />} />
          </Routes>

          {isShow && (
            <>
              <MobileNavBar className="lg:!hidden" />
              <div className="pt-24 lg:hidden" />
            </>
          )}
          <div className="p-3 md-max:hidden">
            <Footer />
          </div>
        </main>
      </div>
      {feedbackModalIsOpen && <Feedback onClose={closeFeedbackModal} />}
    </div>
  );
};

import { Portal } from '@chakra-ui/portal';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMainContent } from '../../../entities/Main';
import { MobileNavBar } from '../../../features/MobileNavBar';
import { Slider } from '../../../horizon-components/Slider';
import Navbar from '../../../horizon-layout/MainLayout/Navbar';
import './Main.scss';
import { ContentWithIcon } from './components/ContentWithIcon/ContentWithIcon';
import { EarnMoney } from './components/EarnMoney/EarnMoney';
import { Education } from './components/Education/Education';
import { Possibilities } from './components/Possibilities/Possibilities';
import { Science } from './components/Science/Science';
import { SocialBlock } from './components/SocialBlock/SocialBlock';
import { WizBlock } from './components/WizBlock/WizBlock';
import { Footer } from '../../../widgets/Footer';
import { getStorage } from '../../../api';
import { MainRoutes } from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../providers';

interface MainProps {
  className?: string;
}

export const Main = ({ className }: MainProps) => {
  const { t } = useTranslation(['translation']);
  const { data } = useMainContent();

  const sliderItems = useMemo(
    () => [
      {
        id: 'mission',
        title: t('main.slider.mission.title'),
        content: `<h2>${t('main.slider.mission.content.title')}</h2><p>${
          data.carousel_mission
        }</p>`,
      },
      {
        id: 'about',
        title: t('main.slider.about.title'),
        content: `<h2><strong>EdRes</strong> - ${t(
          'main.slider.about.content.title'
        )}</h2><p>${data.carousel_about_us}</p>`,
      },
      {
        id: 'goal',
        title: t('main.slider.goal.title'),
        content: `<h2>${t('main.slider.goal.content.title')}</h2><p>${
          data.carousel_goal
        }</p>`,
      },
    ],
    [t, data]
  );

  return (
    <div className={classNames('Main', className)}>
      <Portal>
        <Navbar />
      </Portal>
      <div className={classNames('Main__wrapper', className)}>
        <Slider slides={sliderItems} delay={10000} />
        <Education description={data.education} className="Main__item" />
        <Science data={data} className="Main__item" />
        <WizBlock description={data.iqa} title={data.iqa_title} />
        <Possibilities />
        <EarnMoney description={data.how_to_earn_money} />
        <ContentWithIcon />
        <SocialBlock />

        <MobileNavBar className="lg:!hidden" />
        <div className="pt-24 lg:hidden" />
        <Footer className={'md-max:hidden'} />
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MainRoutes } from '../../../horizon-layout/MainLayout/Routes/types/routes';
import {
  bidRoutes,
  eduRoutes,
  mainRoutes,
  weberRoutes,
  wizRoutes,
} from '../../../horizon-layout/MainLayout/routes';
import { useUserContext } from '../../../providers';
import { MobileTab } from '../../../widgets/MobileTab';
import './MobileNavBar.scss';

interface MobileNavBarProps {
  className?: string;
}

export const MobileNavBar = ({ className }: MobileNavBarProps) => {
  const location = useLocation();
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);

  const routes: RoutesType[] = useMemo(() => {
    if (location.pathname.indexOf(MainRoutes.edu) > -1) {
      return eduRoutes
        ?.filter((el) => (!!el?.isStaffRoute ? !!user?.is_staff : true))
        .map((item) => ({
          ...item,
          name: t(`menu.${item.name}`),
        })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.weber) > -1) {
      return weberRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
        items: item.items?.map((item) => ({
          ...item,
          name: t(`menu.${item.name}`),
          component: t(`menu.${item.component}`),
        })) as unknown as RoutesType[],
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.wiz) > -1 && !!user?.is_staff) {
      return wizRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
      })) as RoutesType[];
    }
    if (location.pathname.indexOf(MainRoutes.bid) > -1 && !!user?.is_staff) {
      return bidRoutes.map((item) => ({
        ...item,
        name: t(`menu.${item.name}`),
      })) as RoutesType[];
    }

    return mainRoutes.map((item) => ({
      ...item,
      name: t(`menu.${item.name}`),
    }));
  }, [location.pathname, t]);

  const createLinks = (routes?: RoutesType[]) => {
    return routes?.map((route, idx) => (
      <MobileTab
        title={route.name}
        key={idx}
        icon={route.icon}
        url={route.path}
        items={route.items}
        secondary={route.secondary}
        component={route.component}
      />
    ));
  };

  return (
    <div className={classNames('MobileNavBar', className)}>
      {createLinks(routes)}
    </div>
  );
};

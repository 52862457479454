/* eslint-disable @typescript-eslint/no-unused-vars */
import { MButton } from '@/horizon-components/MButton';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FireGif from '@/assets/images/wiz/fire.gif';
import './style.scss';

interface QuizStartModalProps {
  className?: string;
  onStart?: () => void;
}

export const QuizStartModal = ({ className, onStart }: QuizStartModalProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('QuizStartModal', className)}>
      <img src={FireGif} alt="Fire" />
      <div>
        <h3>3 день</h3>
        <p>Ваша награда увеличена Х2 </p>
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        className="md:!px-8 md:!py-6 md:!text-lg"
        onClick={onStart}
      >
        Крута
      </MButton>
    </div>
  );
};

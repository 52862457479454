import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import './SchoolCard.scss';

interface SchoolCardProps {
  className?: string;
  title?: string;
  description?: string;
  variant?: string;
  selected?: boolean;
  purchased?: boolean;
  showIcon?: boolean;
  first?: boolean;
  onClick?: () => void;
  link: string;
}

export const SchoolCard = ({
  className,
  title,
  description,
  variant,
  selected = false,
  purchased = false,
  first = false,
  onClick,
  link,
  showIcon = true,
}: SchoolCardProps) => {
  return (
    <div
      className={classNames('SchoolCard', className, { selected, purchased, first })}
    >
      <Link to={link} className="SchoolCard__content">
        {title && <h4>{title}</h4>}
        {description && <p>{description}</p>}
        {variant && <span>{variant}</span>}
      </Link>
      {showIcon &&
        (purchased ? (
          <Link
            to={link}
            className={classNames('SchoolCard__checkbox', { selected, purchased })}
          >
            <ArrowIcon />
          </Link>
        ) : (
          <div
            className={classNames('SchoolCard__checkbox', { selected, purchased })}
            onClick={onClick}
          >
            <CheckIcon />
          </div>
        ))}
    </div>
  );
};

import classNames from 'classnames';
import { CourseCardItem, CourseCardStatus } from '../../../models/Course';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './StartedCard.scss';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

interface StartedCardProps {
  className?: string;
  course?: CourseCardItem;
}

export const StartedCard = ({ className, course }: StartedCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  if (!course) return null;

  // const calculatePercentage = (passed: number, total: number) => {
  //   if (total === 0) {
  //     return 0;
  //   } else {
  //     let percentage = (passed / total) * 100;
  //     return percentage.toFixed(2);
  //   }
  // };

  const goToView = () =>
    navigate(MainRoutes.edu + EduRoutes.course + `/${course?.id}`);

  return (
    <div className={classNames('StartedCard', className)}>
      <div className="StartedCard__img">
        <img src={course.cover_image} alt="" />
        {/* <div className="StartedCard__img-info">
          <span>3/9 {t('course.completed')}</span>
          <div className="StartedCard__bar">
            <div style={{ width: `${calculatePercentage(3, 9)}%` }}></div>
          </div>
        </div> */}
      </div>
      <div className="StartedCard__content">
        <div>
          <h4>{course.title}</h4>
          {!!course?.digital_pass?.level && (
            <span>
              {t('course.Grade')}: {course?.digital_pass?.level}
            </span>
          )}
        </div>
        <MButton color="primary" variant="outline" onClick={goToView}>
          {course?.status === CourseCardStatus.ENROLLED ? (
            <>{t('btnsText.continue')}</>
          ) : course?.status === CourseCardStatus.PREVIEW ? (
            <>
              {t('course.Preview')} <Eye />
            </>
          ) : (
            <>
              {t('btnsText.enroll')} <Plus />
            </>
          )}
        </MButton>
      </div>
    </div>
  );
};

import React, { useMemo, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourseContext } from '../CourseContext';
import useForm, { ErrorMessages } from '../../../../hooks/useForm';
import { CustomQuill } from '../../../../components/CustomQuill/CustomQuill';
import * as yup from 'yup';
import { applyStep4 } from '../../../../api/course';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../horizon-components/Card/Card';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';

export type LearnersForm = {
  requirements: string;
  learningOutcomes: string;
};

export const Learners: React.FC = () => {
  const navigate = useNavigate();
  const { course, updateCourse, fetchCourse } = useCourseContext();
  const { id: courseId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { values, validate, onChange, errors } = useForm<LearnersForm>({
    requirements: course?.requirements || '',
    learningOutcomes: course?.learning_outcomes || '',
  });
  const { t } = useTranslation(['translation']);

  const schema = useMemo(
    () =>
      yup.object().shape({
        requirements: yup.string().required(ErrorMessages.required),
        learningOutcomes: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  const goToNext = async () => {
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    setIsSubmitting(true);
    applyStep4(courseId || '', values.requirements, values.learningOutcomes)
      .then(() => fetchCourse())
      .then(() => {
        navigate(MainRoutes.edu + EduRoutes.preview + `/${courseId}`, {
          state: { isMyCourse: true },
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const goToBack = () => navigate('../visual');

  return (
    <>
      <Card extra={'md:rounded-[30px] rounded-[20px] md:p-[24px] p-[8px] w-full'}>
        <CustomQuill
          wrapperClassName={'mb-4'}
          value={values.requirements}
          name={'requirements'}
          errorText={errors.requirements}
          onChange={onChange}
          label={t('course.requirements')}
          text={t('course.form.requirements')}
        />
        <CustomQuill
          wrapperClassName={'mb-4'}
          value={values.learningOutcomes}
          name={'learningOutcomes'}
          errorText={errors.learningOutcomes}
          onChange={onChange}
          label={t('course.learningOutcomes')}
          text={t('course.form.learningOutcomes')}
        />
      </Card>
      <Card
        extra={
          'w-full items-center md:p-[24px] p-[8px] md:rounded-[30px] rounded-[20px] mt-[20px]'
        }
      >
        <div className={'align-items-center flex w-full justify-between gap-x-2'}>
          <MButton
            variant={'outline'}
            color={'primary'}
            onClick={goToBack}
            size={'sm'}
          >
            {t('course.back2')}
          </MButton>
          <MButton
            color={'primary'}
            variant={'highlighted'}
            disabled={isSubmitting}
            onClick={goToNext}
            size={'sm'}
          >
            {t('course.next')}
          </MButton>
        </div>
      </Card>
    </>
  );
};

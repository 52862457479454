import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AuctionRoutes, MainRoutes } from './types/routes';
import { Actual } from '../../../modules/Bid/Actual/Actual';
import { About } from '../../../modules/Bid/About/About';
import { Winnings } from '../../../modules/Bid/Winnings/Winnings';
import { AuctionView } from '../../../modules/Bid/AuctionView/AuctionView';
import { useUserContext } from '../../../providers';

export const BidRouter: React.FC = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.is_staff) {
      navigate(MainRoutes.edu);
    }
  }, [user]);

  return (
    <div className="mx-auto w-full grow !pt-24 sm-max:p-2">
      <div className="mx-auto w-full grow">
        <Routes>
          <Route
            path="/"
            element={<Navigate to={MainRoutes.bid + AuctionRoutes.actual} replace />}
          />
          <Route path={AuctionRoutes.actual} element={<Actual />} />
          <Route path={AuctionRoutes.about} element={<About />} />
          <Route path={AuctionRoutes.winnings} element={<Winnings />} />
          <Route
            path={AuctionRoutes.auctionView + '/:id'}
            element={<AuctionView />}
          />
          <Route
            path={AuctionRoutes.winningsView + '/:id'}
            element={<AuctionView />}
          />
        </Routes>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BGImg from '../../../../../assets/images/bg-cover-earn.png';
import Coin1Img from '../../../../../assets/images/coin1.png';
import Coin2Img from '../../../../../assets/images/coin2.png';
import Coin3Img from '../../../../../assets/images/coin3.png';
import './EarnMoney.scss';

interface EarnMoneyProps {
  className?: string;
  description: string;
}

const coins = [
  { id: 1, name: 'DB', image: Coin1Img },
  { id: 2, name: 'Y', image: Coin2Img },
  { id: 3, name: 'OWL', image: Coin3Img },
];

export const EarnMoney = ({ className, description }: EarnMoneyProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div
      className={classNames('EarnMoney', className)}
      style={{ backgroundImage: `url(${BGImg})` }}
    >
      <div className="EarnMoney__content">
        <h2>{t('main.earn.title')}</h2>
        <p>{description}</p>
      </div>
      <div className="EarnMoney__coins">
        {coins.map((coin) => (
          <img src={coin.image} alt={coin.name} key={coin.id} />
        ))}
      </div>
    </div>
  );
};

import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MainLayout } from './horizon-layout/MainLayout/MainLayout';
import { MAIN_PAGE_ROUTE } from './horizon-layout/MainLayout/Routes/types/routes';
import { Main } from './modules/Main';

export const Router: React.FC = () => {
  return (
    <Suspense>
      <Routes>
        <Route index element={<Main />} />
        <Route path={MAIN_PAGE_ROUTE} element={<Main />} />
        <Route path="/*" element={<MainLayout />} />
        <Route path="*" element={<Navigate to={MAIN_PAGE_ROUTE} replace />} />
      </Routes>
    </Suspense>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { AiResult, getCourseAiResultNew } from '../../../../api/course';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Coursor } from '../../../../assets/icons/click-coursor.svg';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';

type Props = {
  topicId: number;
};

enum TaxonomyEnum {
  EVALUATOR = 'Evaluator',
  ANALYST = 'Analyst',
  PRACTITIONER = 'Practitioner',
}

export const AiAssistant: React.FC<Props> = ({ topicId }) => {
  const [aiResults, setAiResults] = useState<AiResult[]>([]);
  const { t } = useTranslation(['translation']);
  const [aiResultForShow, setAiResultForShow] = useState<number | null>(null);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<TaxonomyEnum | null>(
    null
  );

  const resultsPerTaxonomy = useMemo(() => {
    return Object.fromEntries(
      Object.values(TaxonomyEnum).map((taxonomy) => [
        taxonomy,
        aiResults.filter((result) => result.taxonomy === taxonomy),
      ])
    ) as Record<TaxonomyEnum, AiResult[]>;
  }, [aiResults]);

  const onGenerateButtonClicked = async (_selectedTaxonomy: TaxonomyEnum) => {
    setSelectedTaxonomy(_selectedTaxonomy);
    if (aiResultForShow === null) {
      const results = await getCourseAiResultNew(topicId);
      setAiResults(results);
      setAiResultForShow(0);
    } else if (
      !!_selectedTaxonomy &&
      resultsPerTaxonomy[_selectedTaxonomy].length - 1 !== 0
    ) {
      setAiResultForShow(1);
    } else {
      setAiResultForShow(0);
    }
  };

  useEffect(() => {
    setAiResults([]);
    setAiResultForShow(null);
  }, [topicId]);

  return (
    <div className="flex w-full flex-col gap-[8px]">
      <div className="flex w-full flex-col flex-wrap items-start justify-between gap-[10px] rounded-[20px] bg-secondary-grey-300 p-[8px] md:flex-row md:p-[16px]">
        <MButton
          className={'w-full md:w-auto'}
          color={'primary'}
          variant={'outline'}
        >
          {t('course.ai')}
          <Coursor />
        </MButton>
        <div
          className={
            'flex w-full items-center justify-between self-stretch overflow-x-auto md:w-auto md:gap-[8px]'
          }
        >
          <MButton
            size="sm"
            onClick={() => onGenerateButtonClicked(TaxonomyEnum.EVALUATOR)}
            variant="highlighted"
            color={selectedTaxonomy === TaxonomyEnum.EVALUATOR ? 'primary' : 'white'}
          >
            {t(`course.Evaluator`)}
          </MButton>
          <MButton
            size="sm"
            onClick={() => onGenerateButtonClicked(TaxonomyEnum.ANALYST)}
            variant="highlighted"
            color={selectedTaxonomy === TaxonomyEnum.ANALYST ? 'primary' : 'white'}
          >
            {t(`course.Analyst`)}
          </MButton>
          <MButton
            size="sm"
            onClick={() => onGenerateButtonClicked(TaxonomyEnum.PRACTITIONER)}
            variant="highlighted"
            color={
              selectedTaxonomy === TaxonomyEnum.PRACTITIONER ? 'primary' : 'white'
            }
          >
            {t(`course.Practitioner`)}
          </MButton>
        </div>
      </div>
      {aiResultForShow !== null && (
        <div className={'b-course-content__unit'}>
          {(!!selectedTaxonomy &&
            resultsPerTaxonomy[selectedTaxonomy][aiResultForShow]?.result) ||
            t('edu.Result_for', {
              selectedTaxonomy: `${t(`course.${selectedTaxonomy}`)}`,
            })}
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { useRecomendedStarted } from '../../../entities/Courses';
import { StartedCard } from '../../../widgets/StartedCard';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';

export const StartedCourses: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { data, isLoading } = useRecomendedStarted(1);
  return (
    <div className="flex flex-col gap-4 md:gap-[20px]">
      <h3 className="text-2xl font-bold text-secondary-grey-800">
        {t('course.started')}
      </h3>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid gap-x-4 gap-y-5 md:grid-cols-2 lg:grid-cols-3">
          {data?.map((course) => <StartedCard course={course} key={course.id} />)}
        </div>
      )}
    </div>
  );
};

interface ObjectWithNullableFields {
  [key: string]: string | null | undefined;
}

export const hasContent = (
  obj?: ObjectWithNullableFields | null | undefined,
  excludeFields: string[] = []
): boolean => {
  if (!obj) return false;
  for (let key in obj) {
    if (!excludeFields.includes(key)) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        return true;
      }
    }
  }
  return false;
};

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getChats } from '../../../api/weber/chat';
import { Loader } from '../../../components/Loader';
import { Card } from '../../../horizon-components/Card/Card';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { TChat } from '../../../models/Chat';
import { ChatFilter } from './components/ChatFilter';
import ChatItem from './components/ChatItem';
import MessageList from './components/MessageList';
import useHistoryParams from '../../../hooks/useHistoryParams';

export const Chat: React.FC = () => {
  const [chats, setChats] = useState<TChat[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState<TChat | null>(null);
  const [index, forceUpdate] = useState<number>(0);
  const [chatType, setChatType] = useState('');
  const { t } = useTranslation(['translation']);
  const [isMessageListVisibleInMobileMode, setIsMessageListVisibleInMobileMode] =
    useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { getParamFromUrl } = useHistoryParams();
  const chatId = getParamFromUrl('chat');

  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
    return () => {
      window.onresize = null;
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getChats(chatType)
      .then((data) => setChats(data))
      .finally(() => setIsLoading(false));
  }, [chatType]);

  useEffect(() => {
    if (chatId !== null) {
      const chatFromParamsData = chats?.find((chat) => chat?.id === Number(chatId));
      if (chatFromParamsData) {
        selectChat(chatFromParamsData);
      }
    }
  }, [chats, chatId]);

  const selectChat = (value: TChat) => {
    setSelectedChat(value);
    setIsMessageListVisibleInMobileMode(true);
  };

  const handleClose = () => {
    setIsMessageListVisibleInMobileMode(false);
    setSelectedChat(null);
  };

  const rerender = () => {
    forceUpdate(index + 1);
  };

  const handleChangeFilter = (value: string) => {
    setChatType(value);
    setSelectedChat(null);
  };

  const onRead = () => {
    setChats((chats) =>
      chats.map((chat) => {
        if (chat.id === selectedChat?.id) {
          chat.unread_message_count = 0;
        }
        return chat;
      })
    );
  };

  return (
    <div className="mx-auto flex h-full max-h-[calc(100vh_-_230px)] min-h-[calc(100vh_-_230px)] w-full grid-cols-10 flex-col gap-[20px] md:mt-[20px] lg:mt-6 lg:flex-row xl:max-w-[1204px]">
      {(!isMessageListVisibleInMobileMode || width > 992) && (
        <div className="h-full w-full lg:w-[35%]">
          <Card
            extra={
              'w-full h-[calc(100vh_-_230px)] p-[8px] lg:p-4 !shadow-[14px_17px_40px_4px_#7090B014]'
            }
          >
            <div>
              <div className="mb-[14px] flex items-center justify-between">
                <h1 className="text-[20px] font-[600] text-secondaryGrey-900 dark:text-white">
                  {t('chat.title')}
                </h1>
              </div>
              <ChatFilter onChangeFilter={handleChangeFilter} />
            </div>
            <div className="mt-4 overflow-auto">
              {isLoading ? (
                <div className={'flex items-center justify-center'}>
                  <Loader />
                </div>
              ) : (
                !!chats?.length &&
                chats?.map((chat) => (
                  <Link
                    to={
                      MainRoutes.auth +
                      AuthRoutes.profile +
                      ProfileRoutes.chat +
                      `?chat=${chat.id}`
                    }
                    key={chat.id}
                  >
                    <ChatItem
                      photo={chat?.profile?.chat_photo}
                      name={chat?.profile?.chat_name || t('chat.unnamedChat')}
                      time={
                        !!chat?.last_message?.created_at
                          ? moment(chat.last_message.created_at).format('HH:mm')
                          : ''
                      }
                      message={chat?.last_message?.body || ''}
                      unreadMessageCount={chat?.unread_message_count}
                      file={chat?.last_message?.file || ''}
                    />
                  </Link>
                ))
              )}
            </div>
          </Card>
        </div>
      )}
      {(isMessageListVisibleInMobileMode || width > 992) && (
        <div className="w-full md:min-h-[0px] lg:w-[64.5%]">
          <MessageList
            chat={selectedChat}
            onClose={handleClose}
            updateChat={rerender}
            onRead={onRead}
          />
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { useCourses } from '../../../entities/Courses';
import { NotFound } from '../../../components/NotFound/NotFound';
import { Pagination } from '../../../horizon-components/Pagination';
import { Loader } from '../../../components/Loader';
import { CourseCard } from '../../../widgets/CourseCard';
import { useTranslation } from 'react-i18next';
import { CourseRequest } from '../../../api/course';

interface AllCoursesProps {
  filters: Omit<CourseRequest, 'page'>;
}

export const AllCourses: React.FC<AllCoursesProps> = ({ filters }) => {
  const { t } = useTranslation(['translation']);
  const { data, isLoading, total, page, setPage } = useCourses(filters);

  return (
    <div className="flex flex-col gap-4 md:gap-5">
      <h3 className="text-2xl font-bold text-secondary-grey-800">
        {t('course.allCourses')}
      </h3>
      {isLoading ? (
        <Loader />
      ) : !data?.results.length ? (
        <NotFound text={t('course.Coming_soon')} />
      ) : (
        <div className="grid grid-cols-1 gap-x-6 gap-y-5 md:grid-cols-3 lg:grid-cols-4">
          {data?.results?.map((course) => (
            <CourseCard course={course} key={course.id} />
          ))}
        </div>
      )}
      {!!total && (
        <div className={'flex w-full items-center justify-center'}>
          <Pagination
            totalPages={total}
            currentPage={page}
            showPrevNext={true}
            onPageChange={setPage}
          />
        </div>
      )}
    </div>
  );
};

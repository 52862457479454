import classNames from 'classnames';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  applyStep3,
  attachIntroductionFile,
  coveredImageList,
  detachIntroductionFile,
  generateIntroductionFileDirectUploadUrl,
  submitIntroductionFileDirectUpload,
  uploadFileToS3,
} from '../../../../api/course';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.primary.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/course/check-img.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/course/close.svg';
import { DragAndDrop } from '../../../../components/DragAndDrop/DragAndDrop';
import { Loader } from '../../../../components/Loader';
import { Card } from '../../../../horizon-components/Card/Card';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { ImageItem } from '../../../../models/Course';
import getErrorMessages from '../../../../utils/getErrorMessages';
import { useCourseContext } from '../CourseContext';

export const Visual: React.FC = () => {
  const { course, updateCourse, fetchCourse } = useCourseContext();
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const [images, setImages] = useState<ImageItem[]>([]);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [activeImageId, setActiveImageId] = useState<number | string | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [coverImagePath, setCoverImagePath] = useState<string>('');
  const { t } = useTranslation(['translation']);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImagesLoading(true);
    coveredImageList()
      .then((data) => {
        setImages(data);
      })
      .finally(() => setImagesLoading(false));
  }, []);

  useEffect(() => {
    if (course && course.cover_image) {
      setCoverImagePath(course.cover_image);
      setActiveImageId(course.cover_image);
    }
    if (course && course.cover_image_predefined) {
      setActiveImageId(course.cover_image_predefined);
    }
  }, [course]);

  const attachFileDirectUpload = async (files: File[]) => {
    if (!course?.id) return;

    setUploading(true);
    try {
      const file = files[0];
      const { upload_url: uploadUrl, file_name: fileName } =
        await generateIntroductionFileDirectUploadUrl(
          course.id,
          file.name,
          file.size
        );
      await uploadFileToS3(uploadUrl, file);
      const updatedCourse = await submitIntroductionFileDirectUpload(
        course.id,
        fileName
      );
      updateCourse({
        ...updatedCourse,
      });
    } catch (error: any) {
      toast(getErrorMessages(error?.response?.data), {
        type: 'error',
      });
    } finally {
      setUploading(false);
    }
  };

  const removeFile = (fileId: number) => {
    setDeleting(true);
    detachIntroductionFile(course?.id || 0, fileId)
      .then((data) => {
        if (data) {
          updateCourse({
            ...data,
          });
        }
      })
      .finally(() => setDeleting(false));
  };

  const goToNext = () => {
    setIsSubmitting(true);
    let request;
    if (activeImageId === coverImagePath) {
      if (coverImage) {
        request = applyStep3(courseId || '', coverImage);
      } else {
        navigate('../learners');
      }
    } else {
      request = applyStep3(
        courseId || '',
        undefined,
        (activeImageId as number) || undefined
      );
    }

    request
      ?.then(() => fetchCourse())
      ?.then(() => {
        navigate('../learners');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const goToBack = () => navigate('../course-content');

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    // @ts-ignore
    const imageFile = e.target.files[0];

    if (['image/png', 'image/jpeg', 'image/jpg'].includes(imageFile.type)) {
      setCoverImage(imageFile);
      const reader = new FileReader();
      reader.addEventListener('load', (_e) => {
        // @ts-ignore
        setCoverImagePath(_e.target.result);
        setActiveImageId(_e?.target?.result?.toString() || '');
      });

      reader.readAsDataURL(imageFile);
    }
  };

  const handleRemove = () => {
    setCoverImagePath('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <Card extra={'md:rounded-[30px] rounded-[20px] md:p-[24px] p-[8px] w-full'}>
        <div className={'w-full'}>
          <p className={'g-title-18 mb-3'}>{t('course.chooseOne')}</p>
          <div className={'mb-4 flex flex-wrap items-center gap-2 md:gap-3'}>
            {imagesLoading ? (
              <Loader />
            ) : images.length ? (
              images.map((item, key) => (
                <div
                  className={'b-visual__item flex flex-col items-center gap-y-1'}
                  key={key}
                >
                  <div
                    className={classNames(
                      'b-visual__item__img relative overflow-hidden',
                      {
                        'b-visual__item__img__active': activeImageId === item.id,
                      }
                    )}
                    onClick={() => setActiveImageId(item.id)}
                  >
                    {activeImageId === item.id && (
                      <div className="absolute flex h-full w-full items-center justify-center bg-[#4318FF66]">
                        <CheckIcon className="fill-white" />
                      </div>
                    )}
                    <img src={item.file} alt={'item'} className={'object-cover'} />
                  </div>
                </div>
              ))
            ) : (
              <p>{t('course.notFound')}</p>
            )}
            {coverImagePath && (
              <div
                className={
                  'b-visual__item flex cursor-pointer flex-col items-center gap-y-1'
                }
              >
                <div
                  className={classNames(
                    'b-visual__item__img b-visual__item__img--add relative overflow-hidden',
                    {
                      'b-visual__item__img__active':
                        activeImageId === coverImagePath,
                    }
                  )}
                >
                  {activeImageId === coverImagePath && (
                    <div className="absolute flex h-full w-full items-center justify-center bg-[#4318FF66]">
                      <CheckIcon className="fill-white" />
                    </div>
                  )}
                  <CloseIcon
                    className="absolute right-0.5 top-0.5 h-4 w-4 rounded-full stroke-white hover:bg-primary-white hover:stroke-primary-purple-blue-500"
                    onClick={handleRemove}
                  />
                  <img
                    src={coverImagePath}
                    alt={'item'}
                    className={'object-cover'}
                    onClick={() => setActiveImageId(coverImagePath)}
                  />
                </div>
              </div>
            )}
            <label
              htmlFor={'cover-image'}
              className={
                'b-visual__item flex cursor-pointer flex-col items-center gap-y-1'
              }
            >
              <div className={'b-visual__item__img b-visual__item__img--add'}>
                <Plus />
                <input
                  id={'cover-image'}
                  type={'file'}
                  accept=".png, .jpg, .jpeg"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
              </div>
            </label>
          </div>
          <DragAndDrop
            uploadedFiles={course?.introduction_files || []}
            onChange={attachFileDirectUpload}
            removeItem={removeFile}
            uploading={uploading}
            deleting={deleting}
            fileTypes={['MP4', 'AVI']}
            multi={false}
            title={t('course.video')}
            text={t('course.dad_description')}
            optional
          />
        </div>
      </Card>
      <Card
        extra={
          'w-full items-center md:p-[24px] p-[8px] md:rounded-[30px] rounded-[20px] mt-[20px]'
        }
      >
        <div className={'flex w-full items-center justify-between gap-2'}>
          <MButton
            variant={'outline'}
            color={'primary'}
            onClick={goToBack}
            size={'sm'}
          >
            {t('course.back2')}
          </MButton>
          <MButton
            color={'primary'}
            variant={'highlighted'}
            disabled={isSubmitting}
            onClick={goToNext}
            size={'sm'}
          >
            {t('course.next')}
          </MButton>
        </div>
      </Card>
    </>
  );
};

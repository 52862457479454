import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { ReactComponent as ProfileIcon } from '../../../../assets/icons/profile/profile.svg';
import { TitleBack } from '../../../../components/TitleBack';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../../providers';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';

export const InfoHeader: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { user } = useUserContext();

  const breads: Bread[] = useMemo(
    () => [
      {
        title: t('menu.Profile'),
        url:
          MainRoutes.auth +
          AuthRoutes.profile +
          ProfileRoutes.user +
          `/${user?.guid}`,
      },
      {
        title: t('profile.settings'),
        url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit,
      },
    ],
    [t]
  );

  return (
    <div className="flex flex-row justify-between gap-3">
      <Breadcrumbs breads={breads} className="hidden md:block" />
      <TitleBack
        title={t('buttons.Edit_Profile')}
        backUrl={
          MainRoutes.auth +
          AuthRoutes.profile +
          ProfileRoutes.user +
          `/${user?.guid}`
        }
        className="md:!hidden"
      />
      <MButton
        color="white"
        variant="highlighted"
        className="!flex shadow-sm shadow-shadow-600 sm-max:h-[42px] sm-max:w-[42px] sm-max:!p-[10px]"
        onClick={() =>
          navigate(
            MainRoutes.auth +
              AuthRoutes.profile +
              ProfileRoutes.user +
              `/${user?.guid}`
          )
        }
      >
        <span className="hidden md:block">{t('buttons.Back_to_profile')}</span>{' '}
        <ProfileIcon />
      </MButton>
    </div>
  );
};

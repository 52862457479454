import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en/translation.json';
import ru from './languages/ru/translation.json';
import kz from './languages/kz/translation.json';

export enum Languages {
  EN = 'en',
  RU = 'ru',
  KZ = 'kz',
}

export const DEFAULT_LANG = Languages.RU;
export const LANG_KEY = 'language';

export const LANGUAGES = {
  [Languages.EN]: 'EN',
  [Languages.RU]: 'RU',
  [Languages.KZ]: 'KZ',
};

export const getCurrentLang = () => {
  return localStorage.getItem(LANG_KEY) || DEFAULT_LANG;
};

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANG,
  lng: getCurrentLang(),
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
    kz: {
      translation: kz,
    },
  },
});

export default i18n;

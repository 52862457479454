import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionCard } from '../AuctionCard/AuctionCard';
import { getAuctions } from '../../../api/bid';
import { Auction } from '../../../models/Auction';
import { Loader } from '../../../components/Loader';
import { ActualCarousel } from './ActualCarousel';

export const Actual: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [isLoading, setIsLoading] = useState(false);
  const [actual, setActual] = useState<Auction[]>([]);
  const [planned, setPlanned] = useState<Auction[]>([]);
  const [completed, setCompleted] = useState<Auction[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getAuctions('active')
      .then((data) => {
        setActual(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
    getAuctions('planned')
      .then((data) => {
        setPlanned(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
    getAuctions('completed')
      .then((data) => {
        setCompleted(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [t]);

  return (
    <div className={'px-[8px] lg:px-0'}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h1 className={'g-main-title mb-[16px] lg:mb-[24px]'}>
            {t('menu.Actual')}
          </h1>
          <ActualCarousel auctions={actual} />
          <div className={'flex flex-row flex-wrap gap-[16px]'}>
            {actual?.map((item) => <AuctionCard key={item.id} data={item} />)}
            {planned?.map((item) => <AuctionCard key={item.id} data={item} />)}
          </div>
          {!!completed?.length && (
            <h3
              className={
                'mb-[12px] mt-[24px] text-[24px] font-bold text-secondaryGrey-800 lg:mb-[24px]'
              }
            >
              {t('bid.pastBids')}
            </h3>
          )}
          <div className={'flex flex-row flex-wrap gap-[16px]'}>
            {completed?.map((item) => <AuctionCard key={item.id} data={item} />)}
          </div>
        </>
      )}
    </div>
  );
};

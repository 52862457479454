import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Performance } from '../../../modules/Wiz/Performance/Performance';
import { Quizzes } from '../../../modules/Wiz/Quizzes/Quizzes';
import { Scoreboard } from '../../../modules/Wiz/Scoreboard/Scoreboard';
import { MainRoutes, WizRoutes } from './types/routes';
import { useUserContext } from '../../../providers';
import { Quiz } from '@/modules/Wiz/Quiz';

export const WizRouter: React.FC = () => {
  return (
    <div className="mx-auto w-full grow !pt-24 sm-max:p-2">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={MainRoutes.wiz + WizRoutes.quiz} replace />}
        />
        <Route path={WizRoutes.quiz} element={<Quiz />} />
        <Route path={WizRoutes.quizzes} element={<Quizzes />} />
        <Route path={WizRoutes.performance} element={<Performance />} />
        <Route path={WizRoutes.scoreboard} element={<Scoreboard />} />
      </Routes>
    </div>
  );
};

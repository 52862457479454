import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Badge } from '../../../horizon-components/Badge';
import { UserMeta, UserSocialMeta } from '../../../models';
import './PublicProfileInfo.scss';

interface PublicProfileInfoProps {
  className?: string;
  userMeta?: UserMeta | null;
  socialMeta?: UserSocialMeta | null;
}

export const PublicProfileInfo = ({
  className,
  userMeta,
  socialMeta,
}: PublicProfileInfoProps) => {
  const { t } = useTranslation(['translation']);

  const goToWeb = (url: string) => window.open(url, '_blank');

  return (
    <div className={classNames('PublicProfileInfo', className)}>
      <div className="flex flex-col gap-2">
        <div className="PublicProfileInfo__head">
          <h4>{t('profile.generalInfo')}</h4>
          <span>
            {t('profile.activeSince')}{' '}
            {moment(userMeta?.joined_at || new Date()).format('DD.MM.YYYY')}
          </span>
        </div>
        <p>{socialMeta?.about}</p>
      </div>
      <div className="PublicProfileInfo__cards">
        <div>
          {!!userMeta?.education && (
            <div>
              <span>{t('profile.education')}</span>
              <h5>{userMeta?.education}</h5>
            </div>
          )}
          {!!userMeta?.major && (
            <div>
              <span>{t('profile.major')}</span>
              <h5>{userMeta?.major}</h5>
            </div>
          )}
        </div>
        <div>
          {!!userMeta?.degree && (
            <div>
              <span>{t('profile.degree')}</span>
              <h5>{userMeta?.degree}</h5>
            </div>
          )}
          {!!userMeta?.position && (
            <div>
              <span>{t('profile.position')}</span>
              <h5>{userMeta?.position}</h5>
            </div>
          )}
          {!!(socialMeta?.instagram?.length || socialMeta?.linked_in?.length) && (
            <div>
              <span>{t('profile.socials')}</span>
              <div className="flex gap-1">
                {!!socialMeta?.instagram?.length && (
                  <Badge
                    variant="primary"
                    onClick={() => goToWeb(socialMeta?.instagram || '')}
                  >
                    Instagram
                  </Badge>
                )}
                {!!socialMeta?.linked_in?.length && (
                  <Badge
                    variant="primary"
                    onClick={() => goToWeb(socialMeta?.linked_in || '')}
                  >
                    Facebook
                  </Badge>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { ReactComponent as NotFoundImage } from '../../assets/images/checking.svg';
import NotFoundRequest from '../../assets/images/not-found.png';
import NotFoundBg from '../../assets/images/create.png';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string | JSX.Element;
  isRequest?: boolean;
};

export const NotFound: React.FC<Props> = ({ text, isRequest }) => {
  const { t } = useTranslation(['translation']);
  const notFoundText = text || t('notFound');

  return (
    <div className={'mt-5 flex w-full flex-col items-center justify-center'}>
      {isRequest ? (
        <img src={NotFoundRequest} className={'w-full max-w-[300px]'} alt={''} />
      ) : (
        <img src={NotFoundBg} className={'w-full max-w-[300px]'} alt={''} />
      )}

      <p
        className={
          'text-center text-base font-semibold text-primary-purple-blue-400 md:text-xl'
        }
      >
        {isRequest ? t('networking.notFound') : notFoundText}
      </p>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useUserContext } from './UserProvider';
import { Capacitor } from '@capacitor/core';
import {default as OneSignalWEB} from 'react-onesignal';
import OneSignal from 'onesignal-cordova-plugin';

export const PushProvider = () => {
  const [initialized, setInitialized] = useState(false);
  const { user } = useUserContext();
  const OSAPPID = process.env.REACT_APP_ONESIGNAL_WEB_APPID || '';

  console.log('user', user?.guid);
  console.log(Capacitor.getPlatform())
  
  // check platform  
  if (Capacitor.getPlatform() === 'web') {
    try {
      if (!initialized) {
        OneSignalWEB.init({
          appId: OSAPPID,
          allowLocalhostAsSecureOrigin: true
        }).then(() => {
          setInitialized(true);
          OneSignalWEB.Debug.setLogLevel('error');
          if (user?.guid) {
            OneSignalWEB.login(user?.guid);
          }
          // else {
          //   OneSignal.logout()
          // }
          // OneSignal.Slidedown.promptPush();
        });
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    OneSignal.initialize(OSAPPID);
    // OneSignal.Notifications.addEventListener('click', async (e) => {
    //   let clickData = await e.notification;
    //   console.log("Notification Clicked : " + clickData);
    // })
    OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
      console.log("Notification permission granted " + success);
    })
  }

  useEffect(() => {
    // check platform 
    if (Capacitor.getPlatform() === 'web') {
      if (user?.guid) {
        console.log('user', user?.guid);
        OneSignalWEB.login(user?.guid);
      } 
      // else {
      //   OneSignal.logout()
      // }
    }
  }, [user]);
  
  return null;
};

import { useCallback, useEffect, useState } from 'react';
import { addFavoriteCourse, deleteFavoriteCourse } from '../../../../../api/course';

export const useCourseFavorite = (fav: boolean) => {
  const [favorite, setFavorite] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFavorite = useCallback(
    (courseId: number) => {
      setIsLoading(true);
      if (favorite) {
        deleteFavoriteCourse(courseId)
          .then(() => setFavorite(false))
          .finally(() => setIsLoading(false));
      } else {
        addFavoriteCourse(courseId)
          .then(() => setFavorite(true))
          .finally(() => setIsLoading(false));
      }
    },
    [favorite]
  );

  useEffect(() => {
    setFavorite(fav);
  }, [fav]);

  return {
    onFavorite,
    isLoading,
    favorite,
  };
};

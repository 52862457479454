import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as AddUserIcon } from '../../../assets/icons/profile/add-user.svg';
import { Loader } from '../../../components/Loader';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { Friendship } from '../../../models/Friendship';
import { ResponsePagination } from '../../../models/Response';
import { useUserContext } from '../../../providers';
import { ProfileFollowerCard } from '../../../widgets/ProfileFollowerCard';
import { GetUserData, getUserData } from '../../../utils/getUserData';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

interface PublicProfileFollowersProps {
  data?: ResponsePagination<Friendship>;
  isLoading: boolean;
}

export const PublicProfileFollowers = ({
  data,
  isLoading,
}: PublicProfileFollowersProps) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id: anotherUserGuid } = useParams();

  const goToNetwork = () => {
    navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking);
  };

  const handleVisit = useCallback((url: string) => navigate(url), [navigate]);

  const buildUserData = (): GetUserData[] => {
    if (!data?.results) return [];
    const userDataArray = [];
    for (const followerRaw of data.results) {
      const anotherUser =
        followerRaw.from_user?.guid === anotherUserGuid
          ? followerRaw.to_user
          : followerRaw.from_user;
      const userData = getUserData(anotherUser);
      userDataArray.push(userData);
    }

    return userDataArray;
  };

  return (
    <>
      <h3 className="md-max:hidden">
        {t('profile.network')} {!isLoading && <span>{data?.count}</span>}
      </h3>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!!data?.results.length ? (
            <div className="flex flex-col gap-2">
              {user?.guid === anotherUserGuid ? (
                <p>{t('profile.Your_connections')}</p>
              ) : (
                <p>{t('profile.Connections')}</p>
              )}
              <div className="flex flex-col gap-2 md:gap-4">
                {buildUserData().map((follower) => (
                  <ProfileFollowerCard
                    username={follower.username}
                    fullname={`${follower.last_name} ${follower.first_name}`}
                    img={follower.image}
                    url={
                      MainRoutes.auth +
                      AuthRoutes.profile +
                      ProfileRoutes.user +
                      `/${follower?.guid}`
                    }
                    guid={follower.guid}
                    key={follower.guid}
                    onVisit={() =>
                      handleVisit(
                        MainRoutes.auth +
                          AuthRoutes.profile +
                          ProfileRoutes.user +
                          `/${follower?.guid}`
                      )
                    }
                  />
                ))}
              </div>
            </div>
          ) : (
            anotherUserGuid === user?.guid && (
              <>
                <h6>{t('profile.Search_for_new_contacts_in_our_network')}</h6>
                <div className="flex justify-end">
                  <MButton
                    color="primary"
                    variant="highlighted"
                    className="w-full md:w-auto"
                    onClick={goToNetwork}
                  >
                    {t('profile.Go_to_Network')} <AddUserIcon />
                  </MButton>
                </div>
              </>
            )
          )}
        </>
      )}
    </>
  );
};

import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

export const generateGoogleRedirectUri = (isSignin?: boolean) => {
  const redirectPage = isSignin
    ? AuthRoutes['google-signin']
    : AuthRoutes['google-signup'];
  return `${process.env.REACT_APP_API_URL}${MainRoutes.auth}${redirectPage}/`;
};

import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { IoMdDocument } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { getVideoTranslationFile, textContentImage } from '../../../../api/course';
import { ReactComponent as Upload } from '../../../../assets/icons/upload.svg';
import { CustomQuill } from '../../../../components/CustomQuill/CustomQuill';
import '../../../../components/DragAndDrop/styles.scss';
import { Loader } from '../../../../components/Loader';
import { SelectOptions } from '../../../../horizon-components/MMultSelect/MMultiSelect';
import { TopicContent } from '../../../../models/Course';
import { convertBase64ToFile } from '../../../../shared/utils/convertBase64ToFile';
import { generateUniqueName } from '../../../../shared/utils/generateUniqueName';
import { getAllImages } from '../../../../shared/utils/getAllImages';
import { getBase64Extension } from '../../../../shared/utils/getBase64Extension';
import { replaceImageSrc } from '../../../../shared/utils/replaceImageSrc';
import { VideoTranslation, VideoTranslationItem } from './VideoTranslation';

export type Props = {
  content: TopicContent[];
  onChangeFile?: (files: File[]) => void;
  onChangeText?: (text: string) => void;
  uploading?: boolean;
  deletingContent?: boolean;
  removeContent?: (id: number) => void;
  isUnitText?: boolean;
  languages: SelectOptions[];
  update?: () => void;
  topicId: number;
};

export const TopicContentUploader: React.FC<Props> = ({
  content,
  onChangeFile,
  onChangeText,
  uploading,
  removeContent,
  deletingContent,
  isUnitText,
  languages,
  update,
  topicId,
}) => {
  const [uploadedTranslation, setUploadedTranslation] = useState<
    VideoTranslationItem[]
  >([]);
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    handleChangeContent?.(content.find((item) => !!item.text)?.text || '');
  }, []);

  const handleChange = (value: File) => {
    onChangeFile?.([value]);
  };

  const removeItem = (contentId: number) => {
    removeContent?.(contentId);
  };

  const handleChangeTranslationItem = (videoItems: VideoTranslationItem[]) => {
    const contentFile = content.find((item) => !!item.file);
    if (contentFile) {
      contentFile.videoTranslations = videoItems;
    }
    update?.();
  };

  const handleChangeContent = (str: string) => {
    let content = str;
    const images = getAllImages(str);

    if (images?.length) {
      setText(str);
      const img = convertBase64ToFile(images[0]);
      const expansion = getBase64Extension(images[0]);
      setIsLoading(true);
      textContentImage(img, generateUniqueName(expansion || 'jpeg'), topicId)
        .then((res) => {
          content = replaceImageSrc(str, res.file, res.id);

          onChangeText?.(content);
          setText(content);
        })
        .finally(() => setIsLoading(false));
    } else {
      onChangeText?.(content);
      setText(content);
    }
  };

  useEffect(() => {
    const file = content.find((item) => !!item.file);
    if (file) {
      getVideoTranslationFile(file.id).then((data) => {
        data?.results?.length &&
          setUploadedTranslation(
            data.results.map((item) => ({
              wasCreated: true,
              file: item.file,
              language: item.language,
              fileId: item.id,
            }))
          );
      });
    }
  }, [content]);

  return (
    <div className={'b-dad'}>
      <div className={'mt-3'}>
        <CustomQuill
          placeholder={isUnitText ? t('edu.Unit_text') : t('edu.Text_content')}
          onChange={handleChangeContent}
          loading={isLoading}
          value={text}
          image
        />
      </div>
      <div className={'mt-2 w-full overflow-hidden md:mt-6'}>
        {!content.filter((item) => item.file)?.length && (
          <FileUploader handleChange={handleChange} types={['MP4', 'AVI']}>
            <div className={'b-dad__area px-[15px]'}>
              {uploading ? <Loader /> : <Upload />}
              <p
                className={
                  'b-dad__area__text text-center text-[12px] md:text-[15px]'
                }
              >
                {t('course.dad')}
              </p>
              <p
                className={
                  'b-dad__area__shadow-text text-center text-[10px] md:text-[12px]'
                }
              >
                {t('course.dadInfo')}
              </p>
            </div>
          </FileUploader>
        )}
      </div>
      {deletingContent ? (
        <div className={'mt-5 flex items-center justify-center'}>
          <Loader />
        </div>
      ) : (
        <div className={'b-dad__list'}>
          {content
            .filter((item) => !!item.file)
            .map((item, key) => {
              const arrayPath = item?.file?.split('/');
              const text = arrayPath?.[arrayPath.length - 1].split('?')[0] || '';
              return (
                <div
                  key={key}
                  className={'b-dad__list__item flex items-center justify-between'}
                >
                  <div className={'flex items-center gap-x-3'}>
                    <IoMdDocument size={20} />
                    {item.file && (
                      <p className={'b-dad__list__item__text'}>{decodeURI(text)}</p>
                    )}
                  </div>
                  <MdClose
                    size={20}
                    className={'b-dad__list__item__trash'}
                    onClick={() => removeItem(item.id)}
                  />
                </div>
              );
            })}
          {/* {!!content.filter((item) => !!item.file).length && (
            <VideoTranslation
              languages={languages}
              onChange={handleChangeTranslationItem}
              uploadedTranslation={uploadedTranslation}
            />
          )} */}
        </div>
      )}
    </div>
  );
};

import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { getDocsAgree, signUp } from '../../../api/auth';
import useForm, { ErrorMessages } from '../../../hooks/useForm';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useUserContext } from '../../../providers';
import { AuthLayout } from '../../../widgets/AuthLayout';
import { GoogleAuth } from '../GoogleAuth/GoogleAuth';
import { MInput } from '../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { TAgree } from '../../../models/Main';

type SignUpForm = {
  email: string;
  password: string;
};

type TAgreeKeys = keyof TAgree;

const SignUp: React.FC = () => {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { saveTempUserData } = useUserContext();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    if (user) navigate(MAIN_PAGE_ROUTE);
  }, [navigate, user]);

  const { values, onChange, errors, validate } = useForm<SignUpForm>({
    email: '',
    password: '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(ErrorMessages.email)
          .required(ErrorMessages.required),
        password: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  const signUpHandler = async () => {
    setIsLoading(true);
    setError('');
    const hasErrors = await validate(schema);

    if (hasErrors) {
      setIsLoading(false);
      return;
    }

    signUp(values.email, values.password)
      .then((data) => {
        saveTempUserData(data);
        let signupUrl = MainRoutes.auth + AuthRoutes.code;
        if (searchParams.has('redirect')) {
          signupUrl = `${signupUrl}?redirect=${searchParams.get('redirect')}`;
        }
        return navigate(signupUrl, {
          state: {
            ...values,
          },
        });
      })
      .catch((error: AxiosError<any>) => {
        Object.keys(error.response?.data).forEach((key) => {
          if (error.response?.data?.[key]) {
            setError(error.response?.data?.[key]);
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.key === 'Enter' && signUpHandler();
  };

  const downloadFile = async (key: TAgreeKeys) => {
    try {
      // Получите ссылку на файл из API
      const data = await getDocsAgree();
      if (data && data?.results?.[0]?.[key]) {
        // Создайте ссылку для скачивания
        const a = document.createElement('a');
        a.href = data?.results?.[0]?.[key];
        a.target = '_blank';

        // Установите имя файла, если это необходимо
        a.download = `${key}.pdf`; // Измените имя файла и его расширение по необходимости

        // Имитация клика по ссылке
        document.body.appendChild(a);
        a.click();

        // Удалите ссылку после скачивания
        document.body.removeChild(a);
      } else {
        console.error('Invalid data received from API');
      }
    } catch (error) {
      console.error('Error fetching file link:', error);
    }
  };

  return (
    <AuthLayout
      title={t('profile.signUp')}
      desc={t('auth.signupDesc')}
      wrapperClassName={'lg:min-h-[800px]'}
    >
      <GoogleAuth signIn={false} />
      <div className="flex items-center gap-3">
        <div className="h-px w-full bg-purple-200" />
        <p className="text-xs font-[14px] text-purple-300 md:text-base">
          {' '}
          {t('profile.or')}{' '}
        </p>
        <div className="h-px w-full bg-purple-200" />
      </div>
      <div className="flex flex-col gap-[16px]">
        <MInput
          label={t('profile.email')}
          placeholder="mail@simmmple.com"
          id="email"
          name={'email'}
          type="text"
          bottomText={errors.email}
          state={errors.email ? 'error' : 'default'}
          value={values.email}
          onChange={onChange}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
          onKeyUp={handleEnter}
        />
        <MInput
          label={t('profile.create_password') + '*'}
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          name={'password'}
          bottomText={errors.password}
          state={errors.password ? 'error' : 'default'}
          value={values.password}
          onChange={onChange}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
          onKeyUp={handleEnter}
        />
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center">
            <p className="text-xs font-medium text-navy-700 md:text-sm">
              {t('auth.By_creating_an_account_means_you_agree_to_the')}{' '}
              <span
                className={'cursor-pointer text-purple-500 hover:text-purple-600'}
                onClick={() => downloadFile('terms_and_conditions')}
              >
                {t('auth.Terms_and_Conditions')}
              </span>{' '}
              {t('auth.and_our')}{' '}
              <span
                className={'cursor-pointer text-purple-500 hover:text-purple-600'}
                onClick={() => downloadFile('privacy_policy')}
              >
                {t('auth.Privacy_Policy')}
              </span>
            </p>
          </div>
        </div>
        {!!error && (
          <p className={'text-center text-xs text-red-600 md:text-sm'}>{error}</p>
        )}
        <MButton
          variant="highlighted"
          color="primary"
          onClick={signUpHandler}
          size={'md'}
          disabled={isLoading || !values.email || !values.password}
        >
          {t('profile.signUp')}
        </MButton>
        <div>
          <span className="text-xs font-medium text-navy-700 md:text-sm dark:text-gray-500">
            {t('profile.member')}
          </span>
          <Link to={MainRoutes.auth + AuthRoutes.signin}>
            <span className="ml-1 text-xs font-medium text-purple-500 hover:text-purple-600 md:text-sm dark:text-white">
              {t('profile.signIn')}
            </span>
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUp;

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as Cursor } from '../../../assets/icons/cursor.svg';
import { ReactComponent as Purchased } from '../../../assets/icons/purchased.svg';
import { NFTFavorite } from '../../../features/NFTFavorite';
import { MButton } from '../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { DigitalPassCategory } from '../../../models/DigitalPass';
import './SubjectCard.scss';

export type Props = {
  data: DigitalPassCategory;
  className?: string;
  isFavorite?: boolean;
};

export const SubjectCard: React.FC<Props> = ({
  data,
  className,
  isFavorite = false,
}) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('SubjectCard', className)}>
      <div className="SubjectCard__icon">
        <Link to={MainRoutes.edu + EduRoutes.nft + '/' + data.id}>
          <img src={data.image_small} alt={'img'} />
        </Link>
      </div>
      <div className="SubjectCard__wrapper">
        <div className={'flex justify-between'}>
          <Link to={MainRoutes.edu + EduRoutes.nft + '/' + data.id}>
            <h4>{data.subject.title}</h4>
            <p>
              {data.course_count || 0} {t('course.includedCourses')}
            </p>
          </Link>
          <NFTFavorite
            favoriteId={data?.subject?.id}
            isFavorite={isFavorite}
            digitalPassCatId={data.id}
            className="favorite"
          />
        </div>
        <div className="SubjectCard__badges">
          {data.digital_passes?.map((item) => (
            <Link
              to={
                MainRoutes.edu +
                EduRoutes.nft +
                `/${data.id}` +
                EduRoutes.shcool +
                `/?school=${item.id}`
              }
              key={item.id}
            >
              <MButton
                color={item.purchase_id ? 'primary' : 'grey'}
                variant={'outline'}
                key={item.id}
                className={`!gap-0.5 !rounded-xl !p-1 md:!p-2 ${
                  item.purchase_id
                    ? 'border-purple-700 text-purple-700'
                    : '!border-gray-100'
                }`}
              >
                {!!item.purchase_id && <Purchased />}
                <span>{item.name}</span>
              </MButton>
            </Link>
          ))}
        </div>
      </div>
      <Link to={MainRoutes.edu + EduRoutes.nft + '/' + data.id} className={'link'}>
        <Cursor />
      </Link>
    </div>
  );
};

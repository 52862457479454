import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseCategory, CourseRequest, CoursesMode } from '../../../api/course';
import { Subjects, getSubjectsList } from '../../../api/dictionaries';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter/filter.svg';
import { useLanguages } from '../../../entities/Languages';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useUserContext } from '../../../providers';
import { Filter, FilterItems, FilterOption, SortBy } from '../../../widgets/Filter';
import './styles.scss';
import useHistoryParams from '../../../hooks/useHistoryParams';

interface CoursesFilterProps {
  onMode?: (mode: CoursesMode) => void;
  onFilter?: (mode: Omit<CourseRequest, 'page'>) => void;
  selectedMode?: CoursesMode;
  title?: string;
}

export const CoursesFilter: React.FC<CoursesFilterProps> = ({
  selectedMode = CoursesMode.DISCOVER,
  onMode,
  onFilter,
  title,
}) => {
  const { user } = useUserContext();
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation(['translation']);
  const [defaultFilter, setDefaultFilterl] = useState<Omit<CourseRequest, 'page'>>({
    sort_by: SortBy.POPULAR,
  });
  const [defaultTitel, setDefaultTitel] = useState<{ [key: string]: string }>({});
  const { data: languages } = useLanguages();

  const { getParamFromUrl, addParamToUrl } = useHistoryParams();

  useEffect(() => {
    getSubjectsList().then((data) => setSubjects(data));

    handleMode(
      getParamFromUrl('mode')
        ? (getParamFromUrl('mode') as CoursesMode)
        : CoursesMode.DISCOVER
    );
  }, [t]);

  const sortByOptions: FilterItems[] = useMemo(
    () =>
      Object.keys(SortBy).map((key, index) => ({
        id: index + 1,
        title: t('sort.' + key.toLowerCase()),
        slug: key,
      })),
    [t]
  );

  const languagesOptions: FilterItems[] = useMemo(
    () =>
      languages?.results.length
        ? languages?.results.map((language) => ({
            id: language.id,
            title: language.title,
            slug: language.language_iso_code,
          }))
        : [],
    [t, languages]
  );

  const categoryOptions: FilterItems[] = useMemo(
    () =>
      Object.keys(CourseCategory).map((key, index) => ({
        id: index + 1,
        title: t('buttons.' + key),
        slug: Number(CourseCategory[key as keyof typeof CourseCategory]),
      })),
    [t]
  );

  const filterOption: FilterOption[] = useMemo(
    () => [
      {
        id: 1,
        title: t('buttons.sort_by'),
        items: sortByOptions,
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['sort_by'],
      },
      {
        id: 2,
        title: t('buttons.subject'),
        items: subjects,
        variant: 'primary',
        type: 'array',
        component: 'badge',
        key: ['subject'],
      },
      {
        id: 3,
        title: t('buttons.category'),
        items: [{ id: 0, title: t('buttons.all'), slug: 'all' }, ...categoryOptions],
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['category'],
      },
      {
        id: 4,
        title: t('buttons.grade'),
        settings: {
          min: 1,
          max: 12,
          step: 1,
        },
        type: 'single',
        component: 'range',
        key: ['subject_level_range_min', 'subject_level_range_max'],
      },
      {
        id: 5,
        title: t('buttons.language'),
        items: [
          { id: 0, title: t('buttons.all'), slug: 'all' },
          ...languagesOptions,
        ],
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['language'],
      },
    ],
    [categoryOptions, languagesOptions, sortByOptions, subjects, t]
  );

  const handleMode = (mode: CoursesMode) => {
    onMode?.(mode);
    addParamToUrl('mode', mode);
  };

  return (
    <div className="flex flex-col gap-[8px] md:gap-[24px]">
      <div className="flex justify-between gap-2">
        <h1 className={'g-main-title'}>{title}</h1>
        <MButton
          color="white"
          variant="highlighted"
          className="h-[42px] w-[42px] !px-[12px] shadow-sm shadow-shadow-600 sm:!hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FilterIcon />
        </MButton>
      </div>

      <div
        className={
          'relative flex w-full flex-col items-center justify-between gap-2 sm:flex-row'
        }
      >
        <div className={'flex w-full items-center gap-2 lg:w-auto'}>
          <MButton
            variant={
              selectedMode === CoursesMode.DISCOVER ? 'highlighted' : 'secondary'
            }
            color="tab"
            size="sm"
            onClick={() => handleMode(CoursesMode.DISCOVER)}
            className="w-full md:w-auto"
          >
            {t('course.discover')}
          </MButton>
          {!!user && (
            <MButton
              variant={
                selectedMode === CoursesMode.MY_COURSES ? 'highlighted' : 'secondary'
              }
              color="tab"
              size="sm"
              onClick={() => handleMode(CoursesMode.MY_COURSES)}
              className="w-full md:w-auto"
            >
              {t('course.myCourses')}
            </MButton>
          )}
        </div>

        <Filter
          options={filterOption}
          onFilter={onFilter}
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          defaultFilter={defaultFilter}
          defaultTitel={defaultTitel}
        />
      </div>
    </div>
  );
};

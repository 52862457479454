import classNames from 'classnames';
import { ReactNode } from 'react';
import './ScienceCard.scss';

interface ScienceCardProps {
  className?: string;
  title?: string;
  description?: string;
  children?: ReactNode;
}

export const ScienceCard = ({
  className,
  title,
  description,
  children,
}: ScienceCardProps) => {
  return (
    <div className={classNames('ScienceCard', className)}>
      <div className="ScienceCard__icon">{children}</div>
      <div className="ScienceCard__content">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { creatChat } from '../../../api/weber/chat';
import { ReactComponent as ChatIcon } from '../../../assets/icons/profile/chat.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/profile/edit.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/profile/share.svg';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { TitleBack } from '../../../components/TitleBack';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useUserContext } from '../../../providers';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

export const PublicProfileHeader: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id: anotherUserGuid } = useParams();
  const isCurrentUser = anotherUserGuid === user?.guid;

  const breads: Bread[] = useMemo(
    () => [
      { title: t('Edu'), url: MainRoutes.edu + EduRoutes.courses },
      {
        title: t('User Profile'),
        url:
          MainRoutes.auth +
          AuthRoutes.profile +
          ProfileRoutes.user +
          `/${anotherUserGuid}`,
      },
    ],
    [anotherUserGuid, t]
  );

  const goToChat = () => {
    creatChat(anotherUserGuid || '').finally(() => {
      navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat);
    });
  };

  return (
    <div className="flex flex-row justify-between gap-3">
      <TitleBack
        title={!!isCurrentUser ? 'Profile' : 'User Profile'}
        className="md:!hidden"
      />
      {!isCurrentUser ? (
        <Breadcrumbs breads={breads} className="hidden md:block" />
      ) : (
        <h1 className="g-main-title hidden md:block">{t('menu.Profile')}</h1>
      )}
      <div className="flex flex-row justify-between gap-2 md:gap-6">
        {!!isCurrentUser ? (
          <>
            <MButton
              color="white"
              variant="highlighted"
              className="shadow-md shadow-shadow-600 sm-max:!w-[42px] sm-max:!px-2"
              onClick={() =>
                navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit)
              }
            >
              <span className="hidden md:block">{t('edit')}</span>
              <EditIcon />
            </MButton>
            <MButton
              color="white"
              variant="highlighted"
              className="shadow-md shadow-shadow-600 sm-max:!w-[42px] sm-max:!px-2"
              onClick={() =>
                navigate(
                  MainRoutes.auth +
                    AuthRoutes.profile +
                    ProfileRoutes.user +
                    `/${user?.guid}`
                )
              }
            >
              <span className="hidden md:block">{t('buttons.Share')}</span>
              <ShareIcon />
            </MButton>
          </>
        ) : (
          <>
            <MButton
              color="white"
              variant="highlighted"
              className="block !w-[42px] !px-0 shadow-md shadow-shadow-600 md:!hidden"
              onClick={goToChat}
            >
              <ChatIcon />
            </MButton>
          </>
        )}
      </div>
    </div>
  );
};

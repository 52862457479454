/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { ReactComponent as ThunderIcon } from '@/assets/icons/wiz/thunder.svg';
import './style.scss';

interface QuizDayCardProps {
  className?: string;
  title?: string;
  earn?: string;
  status?: 'passed' | 'earned' | 'new' | 'current';
}

export const QuizDayCard = ({
  className,
  title,
  earn,
  status,
}: QuizDayCardProps) => {
  return (
    <div className={classNames('QuizDayCard', className, status)}>
      <h3>{title}</h3>
      <p>{earn}</p>
      {(status === 'new' || status === 'current') && (
        <ThunderIcon className="QuizDayCard__icon" />
      )}
    </div>
  );
};

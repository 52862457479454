import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NotificationCard } from '../../../widgets/NotificationCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getNotifications } from '../../../api/auth';
import { NotificationType, TNotifications } from '../../../models/Notifications';
import { ReactComponent as AddUserIcon } from '../assets/icons/add-user.svg';
import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';
import { ReactComponent as GroupIcon } from '../assets/icons/group.svg';
import { ReactComponent as RewiewIcon } from '../assets/icons/review.svg';
import { ReactComponent as StarIcon } from '../assets/icons/star.svg';
import { ReactComponent as BookIcon } from '../assets/icons/book.svg';
import { ReactComponent as BeakerIcon } from '../assets/icons/beaker.svg';
import { ReactComponent as NewspaperIcon } from '../assets/icons/newspaper.svg';
import { ReactComponent as CommentIcon } from '../assets/icons/comment.svg';
import './Notifications.scss';
import { Link } from 'react-router-dom';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import useClickOutside from '../../../hooks/useClickOutside';
import { useNotifications } from '../../../entities/Notifications';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { Loader } from '../../../components/Loader';

interface NotificationsProps {
  className?: string;
  onClose?: () => void;
}

const icons: Record<NotificationType, React.FC> = {
  FRIENDSHIP_REQUEST: AddUserIcon,
  MESSAGE: ChatIcon,
  JOINED_GROUP: GroupIcon,
  JOIN_GROUP_REQUEST: GroupIcon,
  LEFT_GROUP: GroupIcon,
  POST_REACTION: RewiewIcon,
  ER_POINTS_INCOME: StarIcon,
  OWL_POINTS_INCOME: StarIcon,
  PURCHASE_COMPLETION: BookIcon,
  NEW_REVIEW: RewiewIcon,
  COURSE_PUBLISHED: BookIcon,
  COURSE_REJECTED: BookIcon,
  DAO_MEMBER_SHIP_GROWTH: BeakerIcon,
  POST_PUBLICATION: NewspaperIcon,
  NEW_MESSAGE_RECEIVED: ChatIcon,
  FRIEND_REQUEST_RECEIVED: AddUserIcon,
  COMMENT_RESPONSE: CommentIcon,
  FRIEND_REQUEST_ACCEPTED: AddUserIcon,
  POST_COMMENT_GROWTH: RewiewIcon,
};

interface UrlConfig {
  url: string;
  needsId: boolean;
}

const urls: Record<NotificationType, UrlConfig> = {
  // Posts
  POST_REACTION: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },
  POST_COMMENT_GROWTH: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },
  POST_PUBLICATION: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },

  // Courses
  PURCHASE_COMPLETION: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  NEW_REVIEW: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COURSE_PUBLISHED: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COURSE_REJECTED: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COMMENT_RESPONSE: { url: MainRoutes.edu + EduRoutes.course, needsId: true },

  // Chats
  MESSAGE: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat,
    needsId: false,
  },
  NEW_MESSAGE_RECEIVED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat,
    needsId: false,
  },

  // Communities
  JOINED_GROUP: { url: MainRoutes.weber + WeberRoutes.community, needsId: true },
  JOIN_GROUP_REQUEST: {
    url: MainRoutes.weber + WeberRoutes.community,
    needsId: true,
  },
  LEFT_GROUP: { url: MainRoutes.weber + WeberRoutes.community, needsId: true },

  // Wallet
  ER_POINTS_INCOME: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.wallet,
    needsId: false,
  },
  OWL_POINTS_INCOME: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.wallet,
    needsId: false,
  },

  // DAO
  DAO_MEMBER_SHIP_GROWTH: { url: '', needsId: false },

  // Friends
  FRIENDSHIP_REQUEST: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },
  FRIEND_REQUEST_RECEIVED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },
  FRIEND_REQUEST_ACCEPTED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },
};

export const Notifications = ({ className, onClose }: NotificationsProps) => {
  const { t } = useTranslation(['translation']);
  const { dataScroll, fetchData, isLoading, currentPage, total } =
    useNotifications();

  useEffect(() => {
    fetchData();
  }, []);

  const getNotificationIcon = (type: NotificationType) => {
    const IconComponent = icons[type];
    return <IconComponent />;
  };

  const getNotificationUrl = (constant: NotificationType, id?: number | string) => {
    const { url, needsId } = urls[constant];
    return needsId && id ? `${url}/${id}` : url;
  };

  const loadComments = useCallback(() => {
    if (total > currentPage) {
      setTimeout(() => {
        fetchData(currentPage + 1);
      }, 1000);
    }
  }, [total, currentPage]);

  const targetRef = useRef<HTMLDivElement>(null);
  useInfiniteScroll(targetRef, loadComments);

  return (
    <div className={classNames('Notifications', className)}>
      <h3>{t('notifications.title')}</h3>
      <div className="Notifications__wrapper">
        {/* <span>Today</span> */}
        {dataScroll?.map((notify) => (
          <Link
            to={getNotificationUrl(notify.type, notify.related_object_id)}
            key={notify.id}
            onClick={onClose}
          >
            <NotificationCard
              icon={getNotificationIcon(notify.type)}
              title={notify.title}
              //   time={notify.message}
            />
          </Link>
        ))}
        <div ref={targetRef} />
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

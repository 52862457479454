import React, { useEffect, useState } from 'react';
// import { ShareButtons } from '@/components/common/ShareButtons';
import html2canvas from 'html2canvas';
import { uploadResultToShare } from '../../../../api/wiz/wiz';
import { SimpleModal } from '../../../../horizon-components/SimpleModal/SimpleModal';
import { ShareButtons } from './ShareButtons';
import { useTranslation } from 'react-i18next';

type Props = {
  showShareModal: boolean;
  setShowShareModal: (showModal: boolean) => void;
};

export const Share: React.FC<Props> = ({ showShareModal, setShowShareModal }) => {
  const [isReadyToShare, setIsReadyToShare] = useState(false);
  const [imageShareUrl, setUploadedImageUrl] = useState<string>('');
  const { t } = useTranslation(['translation']);

  const handleShare = async () => {
    setIsReadyToShare(false);
    const input = document.getElementById('result-grid');

    if (!input) return;

    html2canvas(input).then(async (canvas) => {
      canvas.toBlob(async (blob) => {
        const fileExtension = 'jpg';
        const fileName = `quiz-result.${fileExtension}`;

        if (!blob) return;

        const formData = new FormData();
        formData.append('image', blob, fileName);
        const data = await uploadResultToShare(formData);
        const publicUrl = data.image;

        console.log(publicUrl);
        setUploadedImageUrl(publicUrl);
        setIsReadyToShare(true);
      }, 'image/jpg');
    });
  };

  const onCloseModal = () => {
    setShowShareModal(false);
  };

  useEffect(() => {
    if (showShareModal) {
      handleShare();
    }
  }, [showShareModal]);

  return (
    <SimpleModal isOpen={showShareModal} onClose={onCloseModal}>
      <>
        <h3 className={'text-sm font-bold text-secondary-grey-900 md:text-lg'}>
          {t('wiz.Share_via')}
        </h3>
        <ShareButtons
          isReadyToShare={isReadyToShare}
          imageShareUrl={imageShareUrl}
        />
      </>
    </SimpleModal>
  );
};

import classNames from 'classnames';
import './PossibilityCard.scss';

interface PossibilityCardProps {
  className?: string;
  title?: string;
  icon?: string;
  description?: string;
}

export const PossibilityCard = ({
  className,
  description,
  icon,
  title,
}: PossibilityCardProps) => {
  return (
    <div className={classNames('PossibilityCard', className)}>
      <div className="PossibilityCard__icon">
        <img src={icon} alt={title} />
      </div>
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  );
};

import { Card } from '../../../../horizon-components/Card/Card';
import { ProfileCard } from '../../../../features/ProfileCard';
import { ProfileAccountSettings } from '../../../../features/ProfileAccountSettings';
import { ProfileAbout } from '../../../../features/ProfileAbout';
import { ProfileChangePassword } from '../../../../features/ProfileChangePassword';
import { InfoHeader } from './InfoHeader';
import { useUserContext } from '../../../../providers';
import Logout from './Logout';
import './InfoUser.scss';

export const InfoUser: React.FC = () => {
  const { user } = useUserContext();
  return (
    <div className="InfoUser m-auto xl:container">
      <InfoHeader />
      <div className={'InfoUser__content'}>
        <div className={'InfoUser__col md:col-span-3'}>
          <Card extra={'InfoUser__card'}>
            <ProfileCard guid={user?.guid} userMeta={user?.user_meta} edit />
          </Card>
          <Card extra={'InfoUser__card'}>
            <ProfileAccountSettings />
          </Card>
        </div>
        <div className={'InfoUser__col md:col-span-2'}>
          <Card extra={'InfoUser__card'}>
            <ProfileAbout />
          </Card>
          <Card extra={'InfoUser__card'}>
            <ProfileChangePassword />
          </Card>
          <Card extra={'InfoUser__card'}>
            <Logout />
          </Card>
        </div>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import './ProfileFollowerCard.scss';
import { NoAvatar } from '../../NoAvatar';

interface ProfileFollowerCardProps {
  className?: string;
  img?: string;
  guid?: string | null;
  username?: string;
  fullname?: string;
  url: string;
  onVisit?: () => void;
}

export const ProfileFollowerCard = ({
  className,
  img,
  username,
  fullname,
  url,
  onVisit,
}: ProfileFollowerCardProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('ProfileFollowerCard', className)}>
      <div className="ProfileFollowerCard__img">
        <Link to={url}>
          <NoAvatar src={img} alt={username} />
        </Link>
      </div>
      <div className="ProfileFollowerCard__content">
        <Link to={url}>
          <h6>{username}</h6>
        </Link>
        <div className="flex gap-[6px]">
          <span>{fullname}</span>
        </div>
      </div>
      <div className="ProfileFollowerCard__icon">
        <MButton color="primary" variant="secondary" onClick={onVisit}>
          {t('profile.view')}
        </MButton>
      </div>
    </div>
  );
};

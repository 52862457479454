import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  attachContent,
  attachVideoFile,
  createQuestions,
  createQuestionsTopic,
  createTopic,
  deleteUnitAnswer,
  deleteUnitQuestion,
  getQuestions,
  updateContent,
} from '../../../../api/course';
import { ReactComponent as Thunder } from '../../../../assets/icons/thunder.svg';
import { Loader } from '../../../../components/Loader';
import { Card } from '../../../../horizon-components/Card/Card';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { SelectOptions } from '../../../../horizon-components/MMultSelect/MMultiSelect';
import { TQuestionItem, Topic, UnitItem } from '../../../../models/Course';
import getErrorMessages from '../../../../utils/getErrorMessages';
import { generateUUID } from '../../../../utils/uuid';
import { QuestionItem } from './QuestionItem';
import { TopicItem } from './TopicItem';
import './styles.scss';

export type Props = {
  unit: UnitItem | null;
  updateCourseUnit: (v: UnitItem) => void;
  currentUnit: number | boolean;
  languages: SelectOptions[];
};

export type UnitRef = {
  save: () => void;
};

export const Unit = forwardRef<UnitRef, Props>(
  ({ unit, updateCourseUnit, currentUnit, languages }, ref) => {
    const [addTopicIsLoading, setAddTopicIsLoading] = useState(false);
    const [questionsIsLoading, setQuestionsIsLoading] = useState(false);
    const { t } = useTranslation(['translation']);
    const [unitExamQuestions, setUnitExamQuestions] = useState<TQuestionItem[]>([]);
    const [isUnitExamMode, setIsUnitExamMode] = useState(true);
    const [topicsText, setTopicsText] = useState<
      { topicId: number; text: string }[]
    >([]);
    const [isUnitTextMode, setIsUnitTextMode] = useState(unit?.topics?.length === 1);

    useEffect(() => {
      setIsUnitTextMode(unit?.topics?.length === 1);
    }, [unit?.topics?.length]);

    const isFileContentPresent = (obj: Topic) => {
      return obj.contents.some(
        (content) => content.resourcetype === 'FileContent' && content.file
      );
    };

    const isTextContentPresent = (obj: Topic) => {
      return !!topicsText.filter((el) => {
        return el.topicId === obj.id && !!el.text.replace(/<(?!img\s)[^>]*>/g, '');
      }).length;
    };

    useImperativeHandle(
      ref,
      () => ({
        save: () => {
          if (currentUnit) {
            const hasTopicText: boolean[] = [];
            unit?.topics?.forEach((obj) => {
              const hasFileContent = isFileContentPresent(obj);

              if (!hasFileContent) {
                const hasTextContent = isTextContentPresent(obj);
                hasTopicText.push(hasTextContent);
              }
            });
            if (hasTopicText.includes(false)) {
              toast(
                t('course.topicContentError', { unitTitle: unit?.unit?.title }),
                {
                  type: 'error',
                }
              );
              return Promise.reject();
            }

            if (!unitExamQuestions.length) {
              toast(t('course.unitExamError'), {
                type: 'error',
              });
              return Promise.reject();
            }
            const hasEmptyFieldInUnitExam = unitExamQuestions.find(
              (item) => !item.text || item.unit_answers.find((i) => !i.text)
            );
            if (hasEmptyFieldInUnitExam) {
              toast(t('course.questionError'), {
                type: 'error',
              });
              return Promise.reject();
            }

            const missingCorrectAnswerInUnitExam = unitExamQuestions.find(
              (question) => !question.unit_answers.some((answer) => answer.correct)
            );
            if (missingCorrectAnswerInUnitExam) {
              toast(t('course.unitCorrectAnswerError'), {
                type: 'error',
              });
              return Promise.reject();
            }

            const missingCorrectAnswerInTopicTest = unit?.topics.find(
              (topic) =>
                topic.questions?.find(
                  (question) =>
                    !question.topic_answers.some((answer) => answer.correct)
                )
            );
            if (missingCorrectAnswerInTopicTest) {
              toast(t('course.topicCorrectAnswerError'), {
                type: 'error',
              });
              return Promise.reject();
            }

            const hasEmptyFieldInTopicTest = unit?.topics.find(
              (topic) =>
                topic.questions?.find(
                  (el) => !el.text || el.topic_answers.find((answer) => !answer.text)
                )
            );
            if (hasEmptyFieldInTopicTest) {
              toast(t('course.topicError'), {
                type: 'error',
              });
              return Promise.reject();
            }

            let hasDuplicateLanguageInVideoTranslations = false;
            unit?.topics?.forEach((topic) => {
              const fileContent = topic.contents.find((content) => !!content.file);
              if (fileContent?.videoTranslations) {
                const languages = fileContent.videoTranslations
                  .filter((item) => !!item.language && !!item.file)
                  .map((item) => item.language);
                if (new Set(languages).size !== languages.length) {
                  hasDuplicateLanguageInVideoTranslations = true;
                }
              }
            });

            if (hasDuplicateLanguageInVideoTranslations) {
              toast(t('course.hasDuplicateLanguageInVideoTranslations'), {
                type: 'error',
              });
              return Promise.reject();
            }

            const createTopicTest =
              unit?.topics?.filter((topic) =>
                createQuestionsTopic({
                  course_topic_id: topic.id,
                  questions: topic.questions || [],
                })
              ) || [];

            const sendVideoTranslations =
              unit?.topics
                ?.map((topic) => {
                  const fileContent = topic.contents.find(
                    (content) => !!content.file
                  );
                  if (fileContent?.videoTranslations) {
                    return fileContent.videoTranslations
                      .filter((item) => !item.wasCreated)
                      .map((video) => {
                        if (video.language && video.file) {
                          return attachVideoFile(
                            fileContent.id.toString(),
                            video.language.toString(),
                            video.file as File
                          );
                        }
                        return Promise.resolve();
                      });
                  }
                  return [Promise.resolve()];
                })
                .reduce((acc, curr) => [...acc, ...curr], []) || [];

            const createTextContent = topicsText.map((item) => {
              const currentTopic = unit?.topics?.find(
                (topic) => topic.id === item.topicId
              );
              const currentTopicContent = currentTopic?.contents.find(
                (content) => content.resourcetype === 'TextContent'
              );
              if (currentTopicContent?.id) {
                updateContentText(item.text, currentTopicContent?.id);
              } else {
                const content = replaceImageSrcToId(item.text);
                attachContentText(content, item.topicId);
              }
            });

            return Promise.all([
              ...createTopicTest,
              ...createTextContent,
              ...sendVideoTranslations,
              createQuestions({
                course_unit_id: unit?.id || 0,
                questions: unitExamQuestions,
              }),
            ]).catch((error) => {
              toast(getErrorMessages(error?.response?.data), {
                type: 'error',
              });
              return Promise.reject();
            });
          }
        },
      }),
      [unitExamQuestions, unit?.topics, unit?.id, t]
    );

    useEffect(() => {
      setQuestionsIsLoading(true);
      getQuestions(unit?.id || 0)
        .then((data) => {
          setUnitExamQuestions(
            data.length
              ? data
              : [
                  {
                    uuid: generateUUID(),
                    text: '',
                    unit_answers: [
                      {
                        text: '',
                        correct: false,
                      },
                    ],
                  },
                ]
          );
          if (data.length) {
            setIsUnitExamMode(true);
          }
        })
        .finally(() => setQuestionsIsLoading(false));
    }, [unit?.id]);

    const addTopic = () => {
      setAddTopicIsLoading(true);
      createTopic(unit?.id || 0, t('course.enter_topic_name'))
        .then((data) => {
          if (unit && data) {
            unit.topics.push(data);
            updateCourseUnit({
              ...unit,
            });
            topicsText.push({ topicId: data.id, text: '' });
            setTopicsText(topicsText);
          }
        })
        .catch((error) => {
          toast(getErrorMessages(error?.response?.data), {
            type: 'error',
          });
        })
        .finally(() => {
          setAddTopicIsLoading(false);
        });
    };

    const updateUnit = () => {
      unit &&
        updateCourseUnit({
          ...unit,
          topics: [...unit.topics],
        });
    };

    const addTextInTopic = (text: string, topicId: number) => {
      setTopicsText((v) => [
        ...v.filter((el) => el.topicId !== topicId),
        { topicId: topicId, text },
      ]);
      updateUnit();
    };

    const attachContentText = (text: string, topicId: number) => {
      return attachContent(topicId, 'TextContent', undefined, text).then((data) => {
        unit?.topics?.find((el) => el.id === topicId)?.contents.push(data);
        unit && updateCourseUnit({ ...unit });
      });
    };

    const replaceImageSrcToId = (htmlString: string): string => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const images = doc.querySelectorAll('img');

      images.forEach((img: HTMLImageElement) => {
        const alt = img.getAttribute('alt');
        const style = img.getAttribute('style');

        if (alt) {
          img.src = `[[${alt}]]`; // Заменяем src на alt
          img.removeAttribute('srcset'); // Удаляем атрибут srcset
          img.removeAttribute('sizes'); // Удаляем атрибут sizes
          if (style) {
            img.setAttribute('style', style); // Сохраняем стили
          }
        }
      });

      return doc.body.innerHTML;
    };

    const updateContentText = (text: string, contentId: number) => {
      const content = replaceImageSrcToId(text);
      return updateContent(contentId, content);
    };

    const addQuestion = () => {
      setUnitExamQuestions([
        ...unitExamQuestions,
        {
          text: '',
          uuid: generateUUID(),
          unit_answers: [
            {
              text: '',
              correct: false,
            },
          ],
        },
      ]);
    };

    const updateQuestion = (question: TQuestionItem, index: number) => {
      unitExamQuestions.splice(index, 1, question);
      setUnitExamQuestions([...unitExamQuestions]);
    };

    const removeUnitQuestion = (question: TQuestionItem, index: number) => {
      if (question.id) {
        deleteUnitQuestion(question.id).then(() => {
          unitExamQuestions.splice(index, 1);
          setUnitExamQuestions([...unitExamQuestions]);
        });
      } else {
        unitExamQuestions.splice(index, 1);
        setUnitExamQuestions([...unitExamQuestions]);
      }
    };

    const divideIntoTopics = () => {
      setIsUnitTextMode(false);
      addTopic();
    };

    return (
      <div className="flex flex-col gap-2 md:gap-6">
        {unit?.topics.map((topic) => (
          <TopicItem
            unit={unit}
            updateCourseUnit={updateCourseUnit}
            topic={topic}
            key={topic.id}
            languages={languages}
            addTextInTopic={addTextInTopic}
          />
        ))}
        <div
          className={`flex flex-col items-end md:flex-row md:items-center ${
            isUnitTextMode ? 'justify-between' : 'justify-end'
          }`}
        >
          {isUnitTextMode && (
            <p className={'text-[12px] font-[600] text-secondaryGrey-500'}>
              {t('edu.Use_this_button_to_divide_your_course')}
            </p>
          )}
          {isUnitTextMode ? (
            <MButton
              variant={'secondary'}
              color={'primary'}
              size={'md'}
              className={'min-w-[185px]'}
              onClick={divideIntoTopics}
            >
              {t('course.divideIntoTopics')}
              <Thunder />
            </MButton>
          ) : (
            <MButton
              color={'primary'}
              variant={'highlighted'}
              size={'md'}
              disabled={addTopicIsLoading}
              onClick={addTopic}
            >
              {t('course.addTopic')}
            </MButton>
          )}
        </div>
        {isUnitExamMode && (
          <Card
            extra={
              'w-full p-[8px] md:p-[24px] md:rounded-[30px] rounded-[20px] shadow-xl !border-[#00B574] border-[1px] mb-3 pt-[16px] md:pt-[24px]'
            }
          >
            <p className={'mb-2 font-medium'}>{t('course.unitExam')}</p>
            {questionsIsLoading ? (
              <div className={'mt-5 flex items-center justify-center'}>
                <Loader />
              </div>
            ) : (
              unitExamQuestions.map((question, i) => (
                <QuestionItem
                  updateQuestion={(data) => updateQuestion(data as TQuestionItem, i)}
                  question={question}
                  index={i + 1}
                  removeAnswerFromBack={deleteUnitAnswer}
                  removeQuestion={() => removeUnitQuestion(question, i)}
                  key={question?.uuid || question?.id || i + 1}
                />
              ))
            )}
            <div className={'flex items-center justify-between'}>
              <div className={'h-[2px] w-full bg-purple-100'} />
              <MButton
                onClick={addQuestion}
                color={'primary'}
                variant={'secondary'}
                className={'min-w-fit'}
                size={'xs'}
              >
                {t('course.addQuestion')}
              </MButton>
              <div className={'h-[2px] w-full bg-purple-100'} />
            </div>
          </Card>
        )}
      </div>
    );
  }
);

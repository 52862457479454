import classNames from 'classnames';
import { useMainEarnMoney } from '../../../../../entities/Main';
import { ContentWithIconCard } from '../../../../../widgets/ContentWithIconCard';
import './ContentWithIcon.scss';

interface ContentWithIconProps {
  className?: string;
}

export const ContentWithIcon = ({ className }: ContentWithIconProps) => {
  const { data } = useMainEarnMoney();
  return (
    <div className={classNames('ContentWithIcon', className)}>
      {data?.map((content) => (
        <ContentWithIconCard
          title={content.title}
          content={content.text}
          icon={content.image}
          key={content.id}
        />
      ))}
    </div>
  );
};

import React from 'react';
import { useUserContext } from '../../../providers';
import { UserType } from '../../../models';
import { InfoInstitution } from './Info/InfoInstitution';
import { InfoUser } from './Info/InfoUser';

export const EditProfile: React.FC = () => {
  const { user } = useUserContext();

  if (!user) return null;

  return (
    <div className={'w-full'}>
      {user.user_type === UserType.INSTITUTION ? <InfoInstitution /> : <InfoUser />}
    </div>
  );
};

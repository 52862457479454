import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import * as yup from 'yup';
import { createFeedback, getFeedbackTypes } from '../../api/feedback';
import { ReactComponent as IconSuccess } from '../../assets/images/circle-success.svg';
import useForm, { ErrorMessages } from '../../hooks/useForm';
import { MButton } from '../MButton/ui/MButton';
import { SelectOptions } from '../MMultSelect/MMultiSelect';
import { MSelect } from '../MSelect/MSelect';
import { MTextArea } from '../MTextArea/MTextArea';

type FormValue = {
  type: string;
  text: string;
};

type Props = {
  onClose?: () => void;
};

export const Feedback: React.FC<Props> = ({ onClose }) => {
  const [isSuccessMode, setIsSuccessMode] = useState(false);
  const [types, setTypes] = useState<SelectOptions[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { values, errors, validate, onChange } = useForm<FormValue>({
    text: '',
    type: '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        type: yup.string().required(ErrorMessages.required),
        text: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  useEffect(() => {
    getFeedbackTypes().then((data) => {
      const translatedTypesData = data.map(({ label, value }) => ({
        label: t(`feedback.${label}`),
        value,
      }));
      setTypes([{ label: t('buttons.Select'), value: '' }, ...translatedTypesData]);
    });
  }, []);

  const send = async () => {
    const hasError = await validate(schema);

    if (hasError) {
      return;
    }

    setIsSubmitting(true);
    createFeedback(values.type, values.text)
      .then(() => setIsSuccessMode(true))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div>
      <div
        className={'fixed left-0 top-0 !z-[1000] h-full w-full'}
        onClick={onClose}
      />
      <div
        className={
          'fixed left-[50%] top-[30%] !z-[1001] ml-[-160px] flex w-[320px] flex-col gap-[16px] rounded-[20px] bg-white p-[24px] shadow-3xl md:ml-[-198px] md:w-[396px]'
        }
      >
        {!isSuccessMode ? (
          <>
            <div className={'flex w-full items-center justify-between'}>
              <p className={'mb-0 text-[24px] font-bold text-secondaryGrey-900'}>
                {t('feedback.leaveYourFeedback')}
              </p>
              <MButton
                color={'white'}
                variant={'highlighted'}
                className={'!p-[12px] shadow-sm'}
                onClick={onClose}
              >
                <MdClose size={18} />
              </MButton>
            </div>
            <MSelect
              value={values.type}
              name={'type'}
              onChange={onChange}
              options={types}
              label={t('feedback.typeOfFeedback')}
              wrapperClassName={'w-full'}
              inputClassName={'w-full'}
              state={errors.type ? 'error' : undefined}
              bottomText={errors.type}
            />
            <MTextArea
              value={values.text}
              name={'text'}
              onChange={onChange}
              label={t('feedback.feedbackMessage')}
              placeholder={''}
              wrapperClassName={'w-full'}
              inputClassName={'w-full'}
              rows={3}
              state={errors.text ? 'error' : undefined}
              bottomText={errors.text}
            />
            <div className={'flex justify-end'}>
              <MButton
                color={'primary'}
                variant={'highlighted'}
                onClick={send}
                disabled={isSubmitting}
              >
                {t('feedback.send')}
              </MButton>
            </div>
          </>
        ) : (
          <>
            <p
              className={
                'mb-0 w-full text-center text-[24px] font-bold text-secondaryGrey-900'
              }
            >
              <Trans
                i18nKey="feedback.yourFeedbackHasBeenSent"
                components={{
                  b: <br />,
                }}
              />
            </p>
            <div className={'my-[30px] flex w-full items-center justify-center'}>
              <IconSuccess />
            </div>
            <div className={'flex justify-end'}>
              <MButton color={'primary'} variant={'highlighted'} onClick={onClose}>
                {t('feedback.cool')}
              </MButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

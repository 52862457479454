import { Card } from '../../../horizon-components/Card/Card';
import React, { useEffect } from 'react';
import { authMe, authorizeViaGoogle } from '../../../api/auth';
import { useUserContext } from '../../../providers';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { generateGoogleRedirectUri } from '../SignIn/utils';
import { useTranslation } from 'react-i18next';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

const GoogleCallback: React.FC<{
  isSignin?: boolean;
}> = ({ isSignin }) => {
  const { saveUser } = useUserContext();
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const code = urlSearchParams.get('code');
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    (async () => {
      if (!code) {
        navigate(MainRoutes.auth + AuthRoutes.signin);
        return;
      }
      const redirectUri = generateGoogleRedirectUri(isSignin);
      const { access, refresh } = await authorizeViaGoogle(code, redirectUri);
      saveAssesTokenStorage(access);
      saveRefreshTokenStorage(refresh);
      const userData = await authMe();
      saveUser(userData);

      navigate(MAIN_PAGE_ROUTE);
    })();
  }, []);

  return (
    <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
      <span>{t('profile.wait')}</span>
    </Card>
  );
};

export default GoogleCallback;

import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import './TitleBack.scss';

interface TitleBackProps {
  className?: string;
  title?: string | React.ReactNode;
  backUrl?: string;
  onClick?: () => void;
}

export const TitleBack = ({
  className,
  title,
  backUrl,
  onClick,
}: TitleBackProps) => {
  const navigate = useNavigate();
  const goTo = () => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      if (onClick) {
        onClick();
        return;
      }
      navigate(-1);
    }
  };

  return (
    <div className={classNames('TitleBack', className)}>
      <MButton color="white" variant="highlighted" onClick={goTo}>
        <ArrowLeftIcon />
      </MButton>
      {title}
    </div>
  );
};

import {
  Modal as CUModal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal';
import classNames from 'classnames';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/modal/close.svg';
import { MButton } from '../../MButton/ui/MButton';
import './Modal.scss';

export type ModalProps = {
  title?: string;
  isOpen: boolean;
  onClose(): void;
  children?: JSX.Element;
  showClose?: boolean;
  bodyClassName?: string;
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  showClose = true,
  bodyClassName,
}) => {
  return (
    <CUModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay className="Modal__bg" onClick={onClose} />
      <ModalContent className="Modal__content">
        <ModalBody className={`Modal__body ${bodyClassName}`}>
          {(!!title || showClose) && (
            <div
              className={classNames('Modal__title', {
                '!justify-center': !showClose,
              })}
            >
              {title && <h3>{title}</h3>}
              {showClose && (
                <MButton
                  color="white"
                  variant="highlighted"
                  className="Modal__close !p-2"
                  onClick={onClose}
                >
                  <CloseIcon />
                </MButton>
              )}
            </div>
          )}
          {children}
        </ModalBody>
      </ModalContent>
    </CUModal>
  );
};

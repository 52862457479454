import { QuizCategories } from '@/widgets/QuizCategories';
import { QuizHeader } from '@/widgets/QuizHeader';
import { QuizStart } from '@/widgets/QuizStart';
import { QuizTest } from '@/widgets/QuizTest';
import classNames from 'classnames';
import { useState } from 'react';
import './style.scss';

interface QuizProps {
  className?: string;
}

export const Quiz = ({ className }: QuizProps) => {
  const [isStarted, setIsStarted] = useState(false);
  const [isTest, setIsTest] = useState(false);

  const hndlStart = () => {
    setIsStarted(!isStarted);
  };

  const hndlTest = () => {
    setIsTest(!isTest);
  };

  return (
    <div className={classNames('Quiz', className, { '!max-w-[1064px]': isTest })}>
      <QuizHeader />
      {!isStarted ? (
        <QuizStart onStart={hndlStart} />
      ) : !isTest ? (
        <QuizCategories onTest={hndlTest} />
      ) : (
        <QuizTest />
      )}
    </div>
  );
};

import classNames from 'classnames';
import React from 'react';
import { ReactComponent as HeartActive } from '../../../assets/icons/heart.active.svg';
import { ReactComponent as Heart } from '../../../assets/icons/heart.svg';
import { Loader } from '../../../components/Loader';
import './Favorite.scss';

interface FavoriteProps {
  className?: string;
  favorite?: boolean;
  isLoading?: boolean;
  onFavorite?: () => void;
}

export const Favorite = ({
  className,
  favorite = false,
  isLoading = false,
  onFavorite,
}: FavoriteProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onFavorite?.();
  };

  return (
    <div className={classNames('Favorite', className)} onClick={handleClick}>
      {isLoading ? (
        <Loader className="!h-5 !w-5 border-2" />
      ) : favorite ? (
        <HeartActive />
      ) : (
        <Heart />
      )}
    </div>
  );
};

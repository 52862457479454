import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ResourceType } from '../../../api/weber/feed';
import { ReactComponent as EditIcon } from '../../../assets/icons/profile/edit.svg';
import { FeedComment } from '../../../features/FeedComment';
import { FeedDots } from '../../../features/FeedDots';
import { FeedReaction } from '../../../features/FeedReaction';
import { Badge } from '../../../horizon-components/Badge';
import { MButton } from '../../../horizon-components/MButton';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Feed, FeedStatus } from '../../../models/Feed';
import { useUserContext } from '../../../providers';
import { getFileName } from '../../../shared/utils/getFileName';
import { getTimeAgo } from '../../../shared/utils/getTimeAgo';
import { getUserData } from '../../../utils/getUserData';
import { AttachementCard } from '../../AttachementCard';
import { FeedCommentCard } from '../../FeedCommentCard';
import { FeedGallery } from '../../FeedGallery';
import { FeedThumb } from '../../FeedThumb';
import { NoAvatar } from '../../NoAvatar';
import './FeedCard.scss';

interface FeedCardProps {
  className?: string;
  feed: Feed;
  onRemove?: (id: number) => void;
  onStaus?: (status: FeedStatus, resourcetype: string, id: number) => void;
  onRequest?: () => void;
}

export const FeedCard = ({
  className,
  feed,
  onRemove,
  onStaus,
  onRequest,
}: FeedCardProps) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);
  const userData = feed.author;
  const { username, image } = getUserData(userData);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    setIsExpanded(!(feed?.description?.length > 200));
  }, [feed.description]);

  return (
    <div className={classNames('FeedCard', className)}>
      <div className="FeedCard__wrapper">
        <div className="FeedCard__head">
          {user?.guid !== userData?.guid ? (
            <div className="FeedCard__user">
              <Link
                className="FeedCard__user-img cursor-pointer"
                to={
                  MainRoutes.auth +
                  AuthRoutes.profile +
                  ProfileRoutes.user +
                  `/${userData?.guid}`
                }
              >
                <NoAvatar src={image} alt={'avatar'} />
              </Link>
              <div className="flex flex-col gap-[2px] md:gap-[4px]">
                <Link
                  to={
                    MainRoutes.auth +
                    AuthRoutes.profile +
                    ProfileRoutes.user +
                    `/${userData?.guid}`
                  }
                >
                  <h5 className="cursor-pointer">{username}</h5>
                </Link>
                <div className="flex gap-[6px] md:gap-2">
                  {!!feed?.tags?.length &&
                    feed?.tags?.map((tag, idx) => (
                      <div className="flex gap-[6px] md:gap-2" key={idx}>
                        <Link to={''}>{tag?.toLowerCase()}</Link>
                        <span className="!font-semibold">•</span>
                      </div>
                    ))}
                  {!!feed?.created_at && (
                    <span className="lowercase">
                      {getTimeAgo(new Date(feed?.created_at), 'day')?.[0]
                        ? getTimeAgo(new Date(feed?.created_at), 'day')?.[0] + ' '
                        : ''}
                      {t(getTimeAgo(new Date(feed?.created_at), 'day')[1])}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap gap-1 md:gap-2">
              {!!feed?.tags?.length &&
                feed?.tags?.map((tag, idx) => (
                  <Badge
                    variant="outline"
                    size="xl"
                    className="text-nowrap capitalize !text-secondary-grey-900"
                    key={idx}
                  >
                    {tag}
                  </Badge>
                ))}
              {!!feed?.created_at && (
                <Badge
                  variant="outline"
                  size="xl"
                  className="!text-secondary-grey-900"
                >
                  {getTimeAgo(new Date(feed?.created_at), 'day')?.[0]
                    ? getTimeAgo(new Date(feed?.created_at), 'day')?.[0] + ' '
                    : ''}
                  {t(getTimeAgo(new Date(feed?.created_at), 'day')[1])}
                </Badge>
              )}
            </div>
          )}
          <FeedDots
            feed={feed}
            onRemove={onRemove}
            onStaus={onStaus}
            onRequest={onRequest}
            isAuthor={user?.guid === userData?.guid}
          />
        </div>

        <div
          className="FeedCard__body"
          style={{
            maxHeight: isExpanded ? 'none' : '3.8em',
          }}
        >
          <Link
            to={
              feed.status === 'published'
                ? MainRoutes.weber + WeberRoutes.feed + `/${feed.id}`
                : ''
            }
          >
            <h3 className="cursor-pointer">{feed?.title}</h3>
          </Link>
          <Link
            to={
              feed.status === 'published'
                ? MainRoutes.weber + WeberRoutes.feed + `/${feed.id}`
                : ''
            }
            dangerouslySetInnerHTML={{ __html: feed.description }}
            className="text-secondary-dark-grey-900"
          />
          {!isExpanded && (
            <button onClick={toggleExpanded}>...{t('buttons.see_more')}</button>
          )}
        </div>

        {feed.resourcetype === ResourceType.LinkPost ||
        feed.resourcetype === ResourceType.ArticlePost ? (
          <Link
            to={
              feed.resourcetype === ResourceType.LinkPost
                ? feed.link
                : MainRoutes.weber + WeberRoutes.feed + `/${feed.id}`
            }
            target={feed.resourcetype === ResourceType.LinkPost ? '_blank' : '_self'}
          >
            <FeedThumb
              title={feed.title}
              thumb={feed.preview_image}
              link={feed.link}
              minRead={feed.min_read}
            />
          </Link>
        ) : (
          <FeedGallery files={feed.attached_files} />
        )}

        {feed.resourcetype === ResourceType.ArticlePost &&
          !!feed?.attached_files?.length && (
            <div className="FeedGallery__files">
              {feed?.attached_files.map((file) => (
                <AttachementCard
                  key={file.id}
                  title={getFileName(file.file)}
                  file={file.file}
                  download
                />
              ))}
            </div>
          )}
      </div>

      {!(user?.guid === userData?.guid && feed.status === 'draft') && (
        <FeedReaction feed={feed} />
      )}

      {!(user?.guid === userData?.guid && feed.status === 'draft') && (
        <FeedComment id={feed.id} />
      )}

      {!(user?.guid === userData?.guid && feed.status === 'draft') &&
        !!feed.last_comment?.id && (
          <FeedCommentCard key={feed.last_comment.id} comment={feed.last_comment} />
        )}

      {user?.guid === userData?.guid && feed.status === 'draft' && (
        <div className="flex items-center justify-end gap-2.5">
          <MButton
            color="primary"
            variant="secondary"
            onClick={() =>
              navigate(MainRoutes.weber + WeberRoutes['feed-edit'] + `/${feed.id}`)
            }
          >
            {t('buttons.Edit')} <EditIcon />
          </MButton>
          <MButton
            color="primary"
            variant="highlighted"
            onClick={() =>
              navigate(MainRoutes.weber + WeberRoutes['feed-edit'] + `/${feed.id}`, {
                state: { publicate: true },
              })
            }
          >
            {t('buttons.Publish')}
          </MButton>
        </div>
      )}
    </div>
  );
};

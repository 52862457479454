import React from 'react';
import { RangeDatepicker, RangeDatepickerProps } from 'chakra-dayzed-datepicker';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/datepicker.svg';
import './DatePicker.scss';

export const DatePicker: React.FC<RangeDatepickerProps> = (props) => {
  const { onDateChange, ...otherProps } = props;

  const handleSelect = (date: Date[]) => {
    onDateChange(date);
  };

  return (
    <div className="DatePicker">
      <RangeDatepicker onDateChange={handleSelect} {...otherProps} />
      <CalendarIcon />
    </div>
  );
};

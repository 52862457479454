import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SignIn from '../../../modules/AuthNew/SignIn/SignIn';
import SignUp from '../../../modules/AuthNew/SignUp/SignUp';
import CheckCode from '../../../modules/AuthNew/CheckCode/CheckCode';
import GoogleCallback from '../../../modules/AuthNew/GoogleCallback/GoogleCallback';
import ResetPassword from '../../../modules/AuthNew/ResetPassword/ResetPassword';
import ChangePassword from '../../../modules/AuthNew/ChangePassword/ChangePassword';
import { ProfileRouter } from './ProfileRouter';
import { AuthRoutes, MainRoutes } from './types/routes';
export const AuthRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={MainRoutes.auth} element={<SignIn />} />
      <Route path={AuthRoutes.signin} element={<SignIn />} />
      <Route path={AuthRoutes.signup} element={<SignUp />} />
      <Route path={AuthRoutes.reset} element={<ResetPassword />} />
      <Route path={AuthRoutes.change} element={<ChangePassword />} />
      <Route path={AuthRoutes.code} element={<CheckCode />} />

      <Route
        path={AuthRoutes['google-signin']}
        element={<GoogleCallback isSignin />}
      />
      <Route path={AuthRoutes['google-signup']} element={<GoogleCallback />} />
      <Route path={AuthRoutes.profile + '/*'} element={<ProfileRouter />} />
      <Route
        path="*"
        element={<Navigate to={MainRoutes.auth + AuthRoutes.signin} />}
      />
    </Routes>
  );
};

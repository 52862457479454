/* eslint-disable @typescript-eslint/no-unused-vars */
import { MButton } from '@/horizon-components/MButton';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import RaiteGif from '@/assets/images/wiz/raite.gif';
import './style.scss';
import { ShareButtons } from '@/features/ShareModal/ui/components/ShareButtons';

interface QuizEndModalProps {
  className?: string;
  onStart?: () => void;
}

export const QuizEndModal = ({ className, onStart }: QuizEndModalProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('QuizEndModal', className)}>
      <img src={RaiteGif} alt="raite" />
      <div className="flex flex-col gap-3">
        <h3>Получено 200 ERP</h3>
        <p>Игрок получил максимальный балл по истории!</p>
        <ShareButtons url={'url'} />
      </div>
      <div className="flex w-full gap-2 md:gap-6">
        <MButton
          variant="secondary"
          color="primary"
          className="w-full md:!px-8 md:!py-6 md:!text-lg"
          onClick={onStart}
        >
          Перейти к курсам
        </MButton>
        <MButton
          variant="highlighted"
          color="primary"
          className="w-full md:!px-8 md:!py-6 md:!text-lg"
          onClick={onStart}
        >
          Сыграть еще
        </MButton>
      </div>
    </div>
  );
};

export type Role = 'USER' | 'INSTITUTION';

export enum UserType {
  INSTITUTION = 'INSTITUTION',
  USER = 'USER',
}

export type UserSocialMeta = {
  about: null | string;
  linked_in: null | string;
  instagram: null | string;
};

export type UserMeta = {
  username: string;
  profile_image: null | string;
  cover_image: null | string;
  joined_at: string;
  first_name: null | string;
  last_name: null | string;
  email: null | string;
  phone_number: null | string;
  address: null | string;
  education: null | string;
  major: null | string;
  degree: null | string;
  position: null | string;
};

export type InstitutionMeta = {
  areas_of_expertise: string[];
  ac_files: {
    id: number;
    file: string;
  }[];
  name: string;
  website: string;
  logo: string;
  type: string;
  created_at: string;
  nobel_laureates: null | string;
  is_verified: boolean;
  applier_full_name: string;
  phone_number: string;
  city: string;
  country: string;
  address: string;
};

export type UserProfile = {
  institution_meta: InstitutionMeta;
  user_meta: UserMeta;
  user_type: UserType;
  guid: string;
  email: string;
  social_meta: UserSocialMeta;
  is_staff: boolean;
};

export type User = {
  id: string;
  email: string;
  user_type: Role;
  user_meta: UserMeta;
  guid: string;
  institution_meta: InstitutionMeta;
  is_active: boolean;
  is_profile_filled: boolean;
  social_meta: any;
};

export type UserFeed = {
  guid: string;
  user_type: string;
  is_active: boolean;
  institution_meta: {
    areas_of_expertise: string[];
    ac_files: [
      {
        id: 0;
        file: string;
      },
    ];
    name: string;
    website: string;
    logo: string;
    type: string;
    created_at: string;
    nobel_laureates: string;
    is_verified: string;
  };
  social_meta: {
    linked_in: string;
    instagram: string;
  };
  user_meta: {
    username: string;
    profile_image: string;
    joined_at: string;
    last_name: string;
    first_name: string;
  };
};

export type Owner = {
  id: number;
  name: string;
  profile_photo?: string;
  additional_profile_data?: {
    area_of_expertise?: {
      id: number;
      title: string;
    }[];
  };
};

export type PublicProfileStats = {
  altmetrics_count: number;
  dao: [];
  network_count: number;
  posts_count: number;
  treatise_nft: [];
  treatise_nfts_count: number;
};

export enum UserCommunityRole {
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR',
  EDITOR = 'EDITOR',
}

export type UserCommunity = {
  id: number;
  joined_at: string;
  role: UserCommunityRole | null;
  user: UserProfile;
  user_guid: string;
};

import React, { useState } from 'react';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/icons/eye-off.svg';

type Props = {
  id?: string;
  label?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  onChange?: (value: string, name?: string) => void;
  value?: string;
  bottomText?: string;
  bottomTextClassName?: string;
  name?: string;
  autoComplete?: string;
  labelClassName?: string;
  state?: 'error' | 'success' | 'gray' | 'default';
  leftIcon?: React.ReactNode;
  leftIconWrapperClassName?: string;
  rightIcon?: React.ReactNode;
  rightIconWrapperClassName?: string;
  onBlur?: (e: any) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  size?: 'sm' | 'md';
};

export const MInput: React.FC<Props> = ({
  id,
  label,
  labelClassName,
  wrapperClassName,
  onChange,
  name,
  disabled,
  type,
  value,
  placeholder,
  autoComplete,
  bottomText,
  bottomTextClassName,
  inputClassName,
  state = 'default',
  leftIcon,
  leftIconWrapperClassName,
  rightIconWrapperClassName,
  rightIcon,
  onBlur,
  onKeyUp,
  autoFocus,
  size = 'md',
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      onChange?.(event.target.value.replace(/\D/g, '') || '0', name);
    } else {
      onChange?.(event.target.value, name);
    }
  };

  return (
    <div className={`${wrapperClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className={`mb-[4px] ml-[16px] text-[14px] font-[500] text-gray-900 ${labelClassName} ${
            state === 'gray'
              ? 'text-gray-900'
              : state === 'success'
              ? 'text-green-700'
              : state === 'error'
              ? 'text-red-700'
              : ''
          }`}
        >
          {label}
        </label>
      )}
      <div className={'relative'}>
        {!!leftIcon && (
          <div
            className={`absolute left-[16px] translate-y-1/2 ${leftIconWrapperClassName}`}
          >
            {leftIcon}
          </div>
        )}
        <input
          onChange={handleChange}
          disabled={disabled}
          value={value}
          type={
            type === 'password'
              ? showPassword
                ? 'text'
                : 'password'
              : type === 'number'
              ? 'text'
              : type
          }
          id={id}
          placeholder={placeholder}
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          className={`flex items-center justify-center rounded-[16px] border-[2px] border-secondaryGrey-500 bg-white px-[16px] text-[14px] font-[500] text-gray-700 outline-none placeholder:text-gray-500
          ${inputClassName} ${size === 'md' ? 'py-[12px]' : 'py-[8px]'} ${
            !!leftIcon && 'pl-[36px]'
          } ${!!rightIcon && 'pr-[36px]'} ${
            disabled === true
              ? '!border-secondaryGrey-500 !bg-secondaryGrey-200 !text-secondaryGrey-600 placeholder:!text-secondaryGrey-400'
              : state === 'error'
              ? '!border-red-500 !text-red-600 placeholder:!text-red-400'
              : state === 'success'
              ? '!border-green-500 !text-green-600 placeholder:!text-green-400'
              : state === 'gray'
              ? '!placeholder:text-purple-700 !border-secondaryGrey-600 !text-purple-900'
              : ''
          }`}
        />
        {type === 'password' && (
          <button
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-[12px] md:right-[18px] md:top-2"
          >
            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </button>
        )}
        {!!rightIcon && (
          <div className={`absolute right-[16px] ${rightIconWrapperClassName}`}>
            {rightIcon}
          </div>
        )}
      </div>
      {!!bottomText && (
        <p
          className={`ml-[16px] mt-[4px] text-[12px] font-[500] text-gray-500 ${bottomTextClassName} ${
            state === 'success'
              ? 'text-green-500'
              : state === 'error'
              ? 'text-red-500'
              : ''
          }`}
        >
          {bottomText}
        </p>
      )}
    </div>
  );
};

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { forgotPassword } from '../../../api/auth';
import useForm, { ErrorMessages } from '../../../hooks/useForm';
import { Card } from '../../../horizon-components/Card/Card';
import InputField from '../../../horizon-components/Input/InputField';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { AuthLayout } from '../../../widgets/AuthLayout';
import { MInput } from '../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

type SignInForm = {
  email: string;
};

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const { values, onChange, errors, validate } = useForm<SignInForm>({
    email: '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(ErrorMessages.email)
          .required(ErrorMessages.required),
      }),
    []
  );

  const resetPasswordHandler = async () => {
    setError('');
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    setIsLoading(true);
    forgotPassword(values.email)
      .then(() => {
        navigate(MainRoutes.auth + AuthRoutes.code, {
          state: { email: values.email },
        });
      })
      .catch(() => {
        setError(t('profile.forgotErrorEmail'));
      })
      .finally(() => setIsLoading(false));
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.key === 'Enter' && resetPasswordHandler();
  };

  return (
    <AuthLayout title={t('profile.forgot')} desc={t('profile.forgotInfo')}>
      <div className="flex flex-col gap-[16px]">
        <MInput
          label={t('profile.email')}
          placeholder="mail@simmmple.com"
          id="email"
          name={'email'}
          type="text"
          bottomText={errors.email}
          state={errors.email ? 'error' : 'default'}
          value={values.email}
          onChange={onChange}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
          onKeyUp={handleEnter}
        />
        {!!error && <p className={'mt-3 text-center text-red-600'}>{error}</p>}
        <MButton
          variant="highlighted"
          color="primary"
          onClick={resetPasswordHandler}
          disabled={isLoading}
          size={'md'}
        >
          {t('profile.continue')}
        </MButton>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;

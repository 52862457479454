import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Router } from './Router';
import { GoogleTagManager } from './components/GoogleTagManager/GoogleTagManager';
import { PushProvider, QueryProvider, UserProvider } from './providers';
import { store } from './stores';
import { Cookie } from './features/Cookie';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryProvider>
        <UserProvider>
          <PushProvider />
          <GoogleTagManager />
          <ToastContainer />
          <Provider store={store}>
            <Router />
          </Provider>
        </UserProvider>
      </QueryProvider>
      <Cookie />
    </BrowserRouter>
  );
};

export default App;

import { useCallback, useEffect, useState } from 'react';
import { getUserCoursesStarted } from '../../../../../api/course';
import { CourseCardItem } from '../../../../../models/Course';
import { useTranslation } from 'react-i18next';

export const useRecomendedStarted = (page?: number) => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<CourseCardItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getUserCoursesStarted(page)
      .then((res) => {
        setData(res);
        res !== undefined && setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [page, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
  };
};

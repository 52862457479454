/* eslint-disable jsx-a11y/iframe-has-title */
// @ts-nocheck
import React from 'react';

export const GoogleTagManager: React.FC = () => {
  const GTMID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

  if (!GTMID) return null;

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GTMID);

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTMID}`}
        style={{ display: 'none', visibility: 'hidden', height: 0, width: 0 }}
      ></iframe>
    </noscript>
  );
};

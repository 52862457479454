import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useDPGroupLP } from '../../../../../entities/Main';
import { MButton } from '../../../../../horizon-components/MButton';
import { EducationCard } from '../../../../../widgets/EducationCard';
import './Education.scss';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../../horizon-layout/MainLayout/Routes/types/routes';

interface EducationProps {
  className?: string;
  description: string;
}

export const Education = ({ className, description }: EducationProps) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { data } = useDPGroupLP();
  const goTo = () => navigate(MainRoutes.edu + EduRoutes['digital-passes']);

  return (
    <div className={classNames('Education', className)}>
      <div className="Education__main">
        <h3>{t('main.education.title')}</h3>
        <p>{description}</p>
      </div>
      {data?.slice(0, 5).map((item) => (
        <Link to={MainRoutes.edu + EduRoutes.nft + `/${item.id}`} key={item.id}>
          <EducationCard
            title={item.subject.title}
            icon={item.image_small}
            count={item.course_count}
          />
        </Link>
      ))}
      <div className="flex flex-col gap-2 md:gap-5">
        <div className="Education__item">
          <span>{t('main.education.you_can_also')}</span>
          <Link to={MainRoutes.edu + EduRoutes.edit + '/0/upload-content'}>
            {t('main.education.create_a_course')}
          </Link>
        </div>
        <MButton
          variant="highlighted"
          color="primary"
          className="!text-lg md:!text-2xl"
          onClick={goTo}
        >
          {t('main.education.in_EDU')}
        </MButton>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './ScienceSecondaryCard.scss';

interface ScienceSecondaryCardProps {
  className?: string;
  title?: string;
  description?: string;
  link?: string;
  btnText?: string;
}

export const ScienceSecondaryCard = ({
  className,
  btnText,
  description,
  link,
  title,
}: ScienceSecondaryCardProps) => {
  return (
    <div className={classNames('ScienceSecondaryCard', className)}>
      <div className="ScienceSecondaryCard__content">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      {link && <Link to={link}>{btnText}</Link>}
    </div>
  );
};

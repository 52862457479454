import instance, { WEBER_API_URL } from '../instance';
import { ResponsePagination } from '../../models/Response';
import { TTags } from '../../models/Tags';

export type CreateFeedForm = {
  title: string;
  text: string;
  subject: string;
  as_anonymous: boolean;
  is_draft: boolean;
  group?: number;
};

export function getTagsList(page?: number): Promise<ResponsePagination<TTags>> {
  return instance()
    .get(`${WEBER_API_URL}/tags/autocomplete/`, { params: { page } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

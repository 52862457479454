import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import { useCourses } from '../../../entities/Courses';
import { Pagination } from '../../../horizon-components/Pagination';
import { CourseCard } from '../../../widgets/CourseCard';

type Props = {
  selectedUnits?: number[];
  selectedDPId?: number;
  selectedLevel?: string;
};

export const IncludedCourses: React.FC<Props> = ({
  selectedUnits,
  selectedDPId,
  selectedLevel,
}) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);

  const { data, isLoading, total, page, setPage, count } = useCourses({
    digital_pass: Number(selectedDPId) || undefined,
    digital_pass_category: Number(id),
    subject_level: Number(selectedLevel) || undefined,
    subject_level_units: selectedUnits,
  });

  return (
    <div className="flex flex-col gap-[20px]">
      <h4 className={'text-2xl font-bold text-secondary-grey-800'}>
        {count} {t('course.includedCourses')}
      </h4>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className={
              'grid grid-cols-1 gap-x-6 gap-y-5 md:grid-cols-3 lg:grid-cols-4'
            }
          >
            {!!data?.results.length ? (
              data?.results.map((course, key) => (
                <CourseCard course={course} key={key} />
              ))
            ) : (
              <NotFound text={t('course.Coming_soon')} />
            )}
          </div>
          {total > 1 && (
            <div className={'flex w-full items-center justify-center'}>
              <Pagination
                totalPages={total}
                currentPage={page}
                showPrevNext={true}
                onPageChange={setPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

import React, { memo } from 'react';
import { useRecomendedCourses } from '../../../entities/Courses';
import { CourseCard } from '../../../widgets/CourseCard';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';

export const Recomended: React.FC = memo(function () {
  const { t } = useTranslation(['translation']);
  const { data, isLoading } = useRecomendedCourses(1);

  return (
    <div className="flex flex-col gap-4 md:gap-[20px]">
      <h3 className="text-2xl font-bold text-secondary-grey-800">
        {t('course.recommended')}
      </h3>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 gap-x-5 gap-y-5 md:grid-cols-3">
          {data?.map((course) => (
            <CourseCard course={course} variant="recomended" key={course.id} />
          ))}
        </div>
      )}
    </div>
  );
});

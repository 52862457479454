import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { saveUserStorage } from '../../../api';
import { setSocialMedia } from '../../../api/auth';
import useForm from '../../../hooks/useForm';
import InputField from '../../../horizon-components/Input/InputField';
import { useUserContext } from '../../../providers';
import classNames from 'classnames';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { TextArea } from '../../../horizon-components/TextInput/TextArea';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import getErrorMessages from '../../../utils/getErrorMessages';
import './ProfileAbout.scss';

type Form = {
  about: string;
  socialNetwork1: string;
  socialNetwork2: string;
};

interface ProfileAccountSettingsProps {
  className?: string;
}

export const ProfileAbout: React.FC<ProfileAccountSettingsProps> = ({
  className,
}) => {
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { values, onChange, errors, validate } = useForm<Form>({
    about: user?.social_meta?.about || '',
    socialNetwork1: user?.social_meta?.linked_in || '',
    socialNetwork2: user?.social_meta?.instagram || '',
  });

  const schema = useMemo(() => {
    return yup.object().shape({
      about: yup.string().nullable(),
      socialNetwork1: yup.string().url(t('errors.link')).nullable(),
      socialNetwork2: yup.string().url(t('errors.link')).nullable(),
    });
  }, []);

  const submit = async () => {
    const hasError = await validate(schema);

    if (hasError) {
      toast(t('errors.checkForm'), { type: 'error' });
      return;
    }

    setIsSubmitting(true);

    setSocialMedia({
      about: values.about,
      linked_in: values.socialNetwork1,
      instagram: values.socialNetwork2,
    })
      .then((data) => {
        if (user) {
          user.social_meta.about = values.about;
          user.social_meta.linked_in = values.socialNetwork1;
          user.social_meta.instagram = values.socialNetwork2;
          saveUserStorage(user);

          toast(t('profile.success'), {
            type: 'success',
          });
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let inputValue = event.target.value.trim().replace(/^https:\/\//, '');
    if (!inputValue.length) {
      inputValue = '';
    } else if (!inputValue.startsWith('https://')) {
      inputValue = 'https://' + inputValue;
    }

    onChange(inputValue, type);
  };

  return (
    <div className={classNames('ProfileAbout', className)}>
      <div className="ProfileAbout__head">
        <h3>{t('profile.aboutYou')}</h3>
        <p>{t('profile.info')}</p>
      </div>
      <div className="ProfileAbout__content">
        <TextArea
          id="about"
          cols={1}
          rows={2}
          placeholder={t('profile.something')}
          label={t('profile.about')}
          value={values.about}
          onChange={(e) => onChange(e.target.value, 'about')}
        />
        <InputField
          label={t('profile.socialNetwork')}
          value={values.socialNetwork1}
          onChange={(e) => handleChange(e, 'socialNetwork1')}
          placeholder="https://example.com/"
          errorText={errors.socialNetwork1}
          type="text"
          name="social"
        />
        <InputField
          label={t('profile.socialNetwork')}
          value={values.socialNetwork2}
          onChange={(e) => handleChange(e, 'socialNetwork2')}
          placeholder="https://example.com/"
          errorText={errors.socialNetwork2}
          type="text"
          name="network"
        />
      </div>
      <div className={'ProfileAbout__foot mt-1'}>
        <MButton
          color={'primary'}
          variant="highlighted"
          disabled={isSubmitting}
          onClick={submit}
        >
          {t('profile.saveChanges')}
        </MButton>
      </div>
    </div>
  );
};

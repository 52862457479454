import { HiX } from 'react-icons/hi';
import React from 'react';
import { Card } from '../../horizon-components/Card/Card';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from '../../utils/scrollbar';
import SidebarLinks from './SidebarLinks';
import { ReactComponent as Telegram } from '../../assets/icons/tg.svg';
import { ReactComponent as Instagram } from '../../assets/icons/inst.svg';

type Props = {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement | HTMLAnchorElement>;
  mini?: boolean;
  hovered?: boolean;
  setHovered: (value: boolean) => void;
  variant?: 'auth' | 'admin';
  routes: RoutesType[];
  openFeedback?: () => void;
};

export const Sidebar: React.FC<Props> = ({
  open,
  onClose,
  mini,
  hovered,
  variant,
  routes,
  openFeedback,
}) => {
  const goToTelegram = () => window.open('https://t.me/edres_com', '_blank');
  const goToInstagram = () =>
    window.open(
      'https://www.instagram.com/edres_com?igsh=eDlrOXppeXV2OWZz&utm_source=qr',
      '_blank'
    );

  return (
    <div
      className={`md:none ${
        mini === false
          ? 'w-[225px]'
          : mini === true && hovered === true
          ? 'w-[225px]'
          : 'w-[225px] xl:!w-[90px]'
      } duration-175 linear fixed !z-50 min-h-full transition-all lg:!z-50 xl:!z-0 ${
        variant === 'auth' ? 'xl:hidden' : 'xl:block'
      } ${open ? '' : '-translate-x-[105%]'}`}
    >
      <Card
        extra={`ml-3 w-full h-[calc(100vh-135px)] lg:h-[91vh] md:mr-4 m-7 mt-16 xl:mt-[100px] xl:pt-3 !rounded-[20px]`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute right-0 top-0 !z-50 block cursor-pointer px-4 py-3 xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <ul className={'mt-5 xl:!mt-0'}>
                <SidebarLinks
                  onClose={onClose}
                  mini={mini}
                  hovered={hovered}
                  routes={routes}
                />
              </ul>
            </div>
          </div>
        </Scrollbars>
        <div className="mb-4 ml-4 w-full text-[12px] text-gray-600 lg:hidden">
          <div className={'mb-[10px] flex items-center gap-2'}>
            <div className={'cursor-pointer'} onClick={goToTelegram}>
              <Telegram />
            </div>
            <div className={'cursor-pointer'} onClick={goToInstagram}>
              <Instagram />
            </div>
          </div>
          <p
            className="cursor-pointer text-[12px] text-purple-300 underline"
            onClick={openFeedback}
          >
            Feedback
          </p>
          <p className="text-[12px] text-gray-600 md:!mb-0 lg:text-base">
            Edres ©{new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
      </Card>
    </div>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { applyStep2, getCourseUnit } from '../../../../api/course';
import { getLanguagesList } from '../../../../api/dictionaries';
import { DynamicTabItem } from '../../../../components/DynamicTab/DynamicTabHeader';
import { DynamicTabWrapper } from '../../../../components/DynamicTab/DynamicTabWrapper';
import { Loader } from '../../../../components/Loader';
import { Card } from '../../../../horizon-components/Card/Card';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { SelectOptions } from '../../../../horizon-components/MMultSelect/MMultiSelect';
import { UnitItem } from '../../../../models/Course';
import { useCourseContext } from '../CourseContext';
import { Unit, UnitRef } from '../Unit/Unit';

export const CourseContent: React.FC = () => {
  const { course, updateCourse, fetchCourse } = useCourseContext();
  const [activeTab, setActiveTab] = useState<number>(
    course?.subject_level_units?.[0].id || 1
  );
  const [languages, setLanguages] = useState<SelectOptions[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [courseUnits, updateCourseUnits] = useState<UnitItem[]>();
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const ref = useRef<UnitRef[]>([]);
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    getLanguagesList().then((data) => {
      data?.results?.length &&
        setLanguages([
          { label: t('course.select'), value: '' },
          ...data.results.map((item) => ({
            label: item.title,
            value: item.id.toString(),
          })),
        ]);
    });
  }, [t]);

  const tabs: DynamicTabItem[] = useMemo(
    () =>
      courseUnits?.map((el) => ({
        key: el.id,
        name: el.unit.title,
      })) || [],
    [courseUnits]
  );

  const goToNext = () => {
    setIsSubmitting(true);
    Promise.all(ref.current?.map((ref) => ref.save()))
      .then(() => {
        if (courseUnits?.length === activeTabIndex + 1) {
          applyStep2(courseId || '')
            .then(() => fetchCourse())
            .then(() => {
              navigate('../visual');
            });
        } else {
          goToRight();
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const goToBack = () => {
    if (activeTabIndex === 0) {
      navigate('../upload-content');
    } else {
      goToLeft();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getCourseUnit(Number(courseId) || 0)
      .then((data) => {
        updateCourseUnits(data);
        setActiveTab(data?.[0].id);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [courseId]);

  const activeTabIndex = useMemo(
    () => tabs?.findIndex((tab) => tab.key === activeTab) || 0,
    [activeTab, tabs]
  );

  if (isLoading) {
    return <Loader />;
  }

  const updateCourseUnit = (data: UnitItem) => {
    if (courseUnits) {
      const index = courseUnits.findIndex((el) => el.id === data.id);
      if (index >= 0) {
        courseUnits.splice(index, 1, data);
        updateCourseUnits([...courseUnits]);
      }
    }
  };

  const goToRight = () => {
    if (activeTabIndex === tabs.length - 1) {
      setActiveTab(tabs?.[0]?.key);
    } else {
      setActiveTab(tabs?.[activeTabIndex + 1]?.key);
    }
    window.scrollTo(0, 0);
  };

  const goToLeft = () => {
    if (activeTabIndex === 0) {
      setActiveTab(tabs?.[tabs.length - 1]?.key);
    } else {
      setActiveTab(tabs?.[activeTabIndex - 1]?.key);
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex flex-col gap-2 md:gap-6">
      <Card
        extra={
          'w-full flex !flex-row justify-between items-center md:p-[24px] p-[8px] md:rounded-[30px] rounded-[20px]'
        }
      >
        <h3 className={'ml-[12px] text-[16px] font-bold md:ml-0 md:text-[30px]'}>
          {tabs?.[activeTabIndex]?.name}
        </h3>
        <div className={'flex items-center gap-x-2'}>
          <div
            onClick={goToLeft}
            className={
              'flex h-[38px] w-[38px] min-w-[38px] cursor-pointer items-center justify-center rounded-full border border-[#4318FF] bg-[#4318FF]'
            }
          >
            <MdChevronLeft size={18} color={'white'} />
          </div>
          <div
            onClick={goToRight}
            className={
              'flex h-[38px] w-[38px] min-w-[38px] cursor-pointer items-center justify-center rounded-full border border-[#4318FF] bg-[#4318FF]'
            }
          >
            <MdChevronRight size={18} color={'white'} />
          </div>
        </div>
      </Card>

      {tabs.map((item, index) => (
        <DynamicTabWrapper isVisible={activeTab === item.key} key={item.key}>
          {activeTab === item.key && (
            <Unit
              languages={languages}
              unit={courseUnits?.[index] || null}
              updateCourseUnit={updateCourseUnit}
              ref={(el) => {
                if (el) {
                  ref.current[index] = el;
                }
              }}
              currentUnit={activeTab === item.key && item.key}
              key={item.key}
            />
          )}
        </DynamicTabWrapper>
      ))}

      <Card
        extra={
          'w-full items-center md:p-[24px] p-[8px] md:rounded-[30px] rounded-[20px]'
        }
      >
        <div className={'align-items-center flex w-full justify-between gap-x-2'}>
          <MButton
            variant={'outline'}
            color={'primary'}
            onClick={goToBack}
            size={'sm'}
          >
            {t('course.back2')}
          </MButton>
          <MButton
            color={'primary'}
            variant={'highlighted'}
            disabled={isSubmitting}
            onClick={goToNext}
            size={'sm'}
          >
            {t('course.next')}
          </MButton>
        </div>
      </Card>
    </div>
  );
};

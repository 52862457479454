import instance from './instance';
import { Auction, Bid, BidStatus } from '../models/Auction';

export function getAuctions(status?: BidStatus): Promise<Auction[]> {
  return instance(true, false)
    .get(`/auctions/`, { params: { status } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getAuctionById(id: string): Promise<Auction> {
  return instance()
    .get(`/auctions/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function placeBid(id: number, amount: string): Promise<void> {
  return instance()
    .post(`/auctions/place-bids/`, {
      bid_amount: amount,
      auction: id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function placeBidList(auctionId: number): Promise<Bid[]> {
  return instance()
    .get(`/place-bids/${auctionId}/list/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function winnerClaimCreate(
  id: number,
  email: string,
  phone_number: string
): Promise<void> {
  return instance()
    .post(`/auctions/winner-claim/create/`, {
      email,
      phone_number,
      auction: id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setAuctionNotifications(
  auction: number
): Promise<{ auction: number; id: number }> {
  return instance()
    .post(`/auction-notifications/`, { auction })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteAuctionNotifications(auction: number): Promise<void> {
  return instance()
    .delete(`/auction-notifications/${auction}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

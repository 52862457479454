import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EduIcon } from '../../../assets/icons/services/cap.svg';
import { ReactComponent as WizIcon } from '../../../assets/icons/services/comment.svg';
import { ReactComponent as BidIcon } from '../../../assets/icons/services/molot.svg';
import { ReactComponent as WeberIcon } from '../../../assets/icons/services/newspaper.svg';
import { MainRoutes } from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import { useNavigate } from 'react-router-dom';
import './Services.scss';

type TServices = {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  url: string;
};

interface ServicesProps {
  className?: string;
}

export const Services = ({ className }: ServicesProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const navigate = useNavigate();

  const goTo = (url: string) => navigate(url);

  const services: TServices[] = useMemo(
    () =>
      [
        {
          title: 'EDU',
          subtitle: t('services.edu'),
          icon: <EduIcon />,
          url: MainRoutes.edu,
        },
        {
          title: 'WIZ',
          subtitle: t('services.wiz'),
          icon: <WizIcon />,
          url: MainRoutes.wiz,
        },
        !!user?.is_staff && {
          title: 'WEBER',
          subtitle: t('services.weber'),
          icon: <WeberIcon />,
          url: MainRoutes.weber,
        },
        !!user?.is_staff && {
          title: 'BID',
          subtitle: t('services.bid'),
          icon: <BidIcon />,
          url: MainRoutes.bid,
        },
      ] as TServices[],
    [t]
  );

  return (
    <div className={classNames('Services', className)}>
      {services.map(
        (service, idx) =>
          service && (
            <div
              className="Services__item"
              key={idx}
              onClick={() => goTo(service.url)}
            >
              <div className="Services__icon">{service.icon}</div>
              <div>
                <h4>{service.title}</h4>
                {service.subtitle}
              </div>
            </div>
          )
      )}
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { ProfilePostCard } from '../../../widgets/ProfilePostCard';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../../providers';
import { Loader } from '../../../components/Loader';
import { ResponsePagination } from '../../../models/Response';
import { Feed } from '../../../models/Feed';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { ActionBar } from '../../../features/ActionBar';

interface PublicProfilePostsProps {
  data?: ResponsePagination<Feed>;
  isLoading: boolean;
}

export const PublicProfilePosts = ({ data, isLoading }: PublicProfilePostsProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const { id: anotherUserGuid } = useParams();

  return (
    <>
      <div>
        <h3 className="md-max:hidden">
          {t('posts')} <span>{!isLoading && data?.count}</span>
        </h3>
        {anotherUserGuid === user?.guid && (
          <p>{t('profile.Here_is_your_publicised_posts_from_WEBER')}</p>
        )}
      </div>

      {isLoading ? (
        <Loader />
      ) : !!data?.results.length ? (
        data?.results.map((post) => (
          <ProfilePostCard
            title={post.title}
            img={post.preview_image}
            url={MainRoutes.weber + WeberRoutes.feed + `/${post.id}`}
            key={post.id}
          />
        ))
      ) : (
        anotherUserGuid === user?.guid && (
          <>
            <h6>{t('profile.Create_your_first_post_in_Weber')}</h6>
            <div className="flex justify-end">
              <ActionBar />
            </div>
          </>
        )
      )}
    </>
  );
};

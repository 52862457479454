import instance from './instance';
import { ResponsePagination } from '../models/Response';
import { TCoutseReview, TRating, TReview } from '../models/Review';

export type OrderBy = 'rating' | '-created_at' | '-rating';

export function getUserCoursesReviews(
  course: number,
  page: number,
  review_owner?: 'mine' | 'others',
  order_by?: OrderBy
): Promise<ResponsePagination<TRating>> {
  return instance(true, false)
    .get(`/user-courses/reviews/`, {
      params: { course, page, review_owner, order_by },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCourseReview(courseId: number): Promise<TReview> {
  return instance()
    .get(`/course-review-report/${courseId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setUserCoursesReviews(
  course: number,
  rating: number,
  comment?: string
): Promise<TCoutseReview> {
  return instance()
    .post(`/user-courses/reviews/`, { course, rating, comment })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setCourseReviewReaction(reviewId: number): Promise<TCoutseReview> {
  return instance()
    .post(`/course-review-reaction/`, { course_review: reviewId })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

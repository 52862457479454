import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { changeStep1, createCourse } from '../../../../api/course';
import {
  getLanguagesList,
  getLevelsList,
  getSubjectsList,
  getUnitsList,
} from '../../../../api/dictionaries';
import { CustomQuill } from '../../../../components/CustomQuill/CustomQuill';
import useForm, { ErrorMessages } from '../../../../hooks/useForm';
import { Card } from '../../../../horizon-components/Card/Card';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import {
  MMultiSelect,
  SelectOptions,
} from '../../../../horizon-components/MMultSelect/MMultiSelect';
import { MSelect } from '../../../../horizon-components/MSelect/MSelect';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import getErrorMessages from '../../../../utils/getErrorMessages';
import { useCourseContext } from '../CourseContext';
import { DocsModal } from '../Modals/DocsModal';

export type UploadContentForm = {
  title: string;
  subject: string;
  subjectLevel: string;
  subjectLevelUnits: SelectOptions[];
  language: string;
  description: string;
};

export const UploadContent: React.FC = () => {
  const { course, updateCourse } = useCourseContext();
  const [docModalIsOpen, setDocModalIsOpen] = useState(false);
  const [isLevelsLoading, setIsLevelsLoading] = useState(false);
  const [isUnitsLoading, setIsUnitsLoading] = useState(false);
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState<SelectOptions[]>([]);
  const [levels, setLevels] = useState<SelectOptions[]>([]);
  const [languages, setLanguages] = useState<SelectOptions[]>([]);
  const [units, setUnits] = useState<SelectOptions[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id: courseId } = useParams();
  const { t } = useTranslation(['translation']);

  const { values, validate, onChange, errors, setValues } =
    useForm<UploadContentForm>({
      title: course?.title || '',
      subject: course?.subject?.id.toString() || '',
      subjectLevel: '',
      subjectLevelUnits:
        course?.subject_level_units?.map((item) => ({
          label: item.title,
          value: item.id.toString(),
        })) || [],
      language: '',
      description: course?.description || '',
    });

  useEffect(() => {
    setValues((v) => ({
      ...v,
      subjectLevel:
        levels.find(
          (el) =>
            el.value === course?.subject_level?.id?.toString() ||
            el.value === course?.level_id?.toString()
        )?.value || '',
      language:
        languages.find(
          (el) =>
            el.value === course?.language?.toString() ||
            el.label === course?.language?.toString()
        )?.value || '',
    }));
  }, [
    course,
    course?.language,
    course?.level,
    course?.level_id,
    languages,
    levels,
    setValues,
  ]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(ErrorMessages.required),
        subject: yup.string().required(ErrorMessages.required),
        subjectLevel: yup.string().required(ErrorMessages.required),
        language: yup.string().required(ErrorMessages.required),
        description: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  useEffect(() => {
    getSubjectsList().then((data) => {
      data?.length &&
        setSubjects([
          { label: t('course.select'), value: '' },
          ...data?.map((item) => ({
            label: item.title,
            value: item.id.toString(),
          })),
        ]);
    });

    getLanguagesList().then((data) => {
      data?.results?.length &&
        setLanguages([
          { label: t('course.select'), value: '' },
          ...data?.results.map((item) => ({
            label: item.title,
            value: item.id.toString(),
          })),
        ]);
    });
  }, [t]);

  useEffect(() => {
    if (values.subject) {
      setIsLevelsLoading(true);
      getLevelsList(values.subject)
        .then((data) => {
          data?.length &&
            setLevels([
              { label: t('course.select'), value: '' },
              ...data?.map((item) => ({
                label: item.title,
                value: item.id.toString(),
              })),
            ]);
        })
        .finally(() => setIsLevelsLoading(false));
    }
  }, [t, values.subject]);

  useEffect(() => {
    if (values.subjectLevel) {
      setIsUnitsLoading(true);
      getUnitsList(values.subjectLevel)
        .then((data) => {
          data?.length &&
            setUnits(
              data?.map((item) => ({
                label: item.title,
                value: item.id.toString(),
              }))
            );
        })
        .finally(() => setIsUnitsLoading(false));
    }
  }, [values.subjectLevel]);

  const goToNext = async () => {
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    setIsSubmitting(true);

    let request;
    if (courseId === '0') {
      request = createCourse({
        title: values.title,
        subject: Number(values.subject),
        subject_level: Number(values.subjectLevel),
        subject_level_units: values.subjectLevelUnits.map((el) => Number(el.value)),
        language: values.language,
        description: values.description,
      });
    } else {
      request = changeStep1(courseId || '', {
        title: values.title,
        subject: Number(values.subject),
        subject_level: Number(values.subjectLevel),
        subject_level_units: values.subjectLevelUnits.map((el) => Number(el.value)),
        language: values.language,
        description: values.description,
      });
    }

    request
      .then((data) => {
        updateCourse(data);
        navigate(MainRoutes.edu + EduRoutes.edit + `/${data.id}/course-content`);
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const goBack = () => navigate(MainRoutes.edu + EduRoutes.courses);

  const onChangeSubjectLevel = (value: string) => {
    onChange(value, 'subjectLevel');
    onChange([], 'subjectLevelUnits');
  };

  return (
    <>
      <Card extra={'rounded-[20px] md:rounded-[30px]'}>
        <div className={'flex flex-col items-center p-2 md:p-6'}>
          <div className={'flex w-full flex-wrap justify-between gap-3'}>
            <div className={'w-full md:w-[48%]'}>
              <MInput
                placeholder={t('addProduct.text')}
                name={'title'}
                label={t('course.Coursetitle')}
                value={values.title}
                bottomText={errors.title}
                state={errors.title ? 'error' : 'default'}
                wrapperClassName={'mb-4 w-full'}
                inputClassName={'w-full'}
                onChange={onChange}
                autoFocus
                size={'sm'}
              />
              <MSelect
                placeholder={t('course.select')}
                label={t('course.Coursesubject')}
                wrapperClassName={'mb-4'}
                value={values.subject}
                name={'subject'}
                bottomText={errors.subject}
                inputClassName={'w-full'}
                size={'sm'}
                state={errors.subject ? 'error' : 'default'}
                onChange={onChange}
                options={subjects}
              />
              <MSelect
                placeholder={t('course.select')}
                label={t('course.Grade')}
                wrapperClassName={'mb-4'}
                value={values.subjectLevel}
                name={'subjectLevel'}
                bottomText={errors.subjectLevel}
                state={errors.subjectLevel ? 'error' : 'default'}
                inputClassName={'w-full'}
                size={'sm'}
                onChange={onChangeSubjectLevel}
                options={levels}
                disabled={isLevelsLoading || !values.subject}
              />
              <MSelect
                placeholder={t('course.select')}
                label={t('course.Courselanguage')}
                inputClassName={'w-full'}
                size={'sm'}
                wrapperClassName={'mb-4'}
                value={values.language}
                name={'language'}
                bottomText={errors.language}
                state={errors.language ? 'error' : 'default'}
                onChange={onChange}
                options={languages}
              />
            </div>
            <div className={'w-full md:w-[48%]'}>
              <CustomQuill
                wrapperClassName={'mb-4'}
                label={t('course.Coursedescription')}
                placeholder={t('addProduct.text')}
                value={values.description}
                onChange={onChange}
                errorText={errors.description}
                name={'description'}
              />
              <MMultiSelect
                placeholder={t('course.select')}
                label={t('course.Courseunit')}
                wrapperClassName={'mb-4'}
                value={values.subjectLevelUnits}
                name={'subjectLevelUnits'}
                bottomText={errors.subjectLevelUnits}
                state={errors.subjectLevelUnits ? 'error' : 'default'}
                onChange={onChange}
                options={units}
                inputClassName={'w-full'}
                disabled={isUnitsLoading || isLevelsLoading || !values.subjectLevel}
              />
            </div>
          </div>
          {docModalIsOpen && (
            <DocsModal
              isOpen={docModalIsOpen}
              onClose={() => setDocModalIsOpen(false)}
            />
          )}
        </div>
      </Card>
      <Card
        extra={
          'w-full items-center md:p-[24px] p-[8px] rounded-[20px] md:rounded-[30px] mt-[20px]'
        }
      >
        <div className={'flex w-full items-center justify-between gap-2'}>
          <MButton
            variant={'outline'}
            color={'primary'}
            onClick={goBack}
            size={'sm'}
          >
            {t('course.back2')}
          </MButton>
          <MButton
            color={'primary'}
            variant={'highlighted'}
            disabled={isSubmitting}
            onClick={goToNext}
            size={'sm'}
          >
            {t('course.next')}
          </MButton>
        </div>
      </Card>
    </>
  );
};

import classNames from 'classnames';
import { Card } from '../../../horizon-components/Card/Card';
import './AuthLayout.scss';

interface AuthLayoutProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
  desc?: string;
  wrapperClassName?: string;
}

export const AuthLayout = ({
  className,
  children,
  title,
  desc,
  wrapperClassName,
}: AuthLayoutProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  return (
    <>
      <div
        className={`flex h-[calc(100vh_-_35px)] w-full items-center justify-center ${wrapperClassName}`}
      >
        <form
          className={classNames('AuthLayout', className)}
          onSubmit={handleSubmit}
        >
          <Card className="AuthLayout__wrapper">
            <div className="AuthLayout__title">
              <h1>{title}</h1>
              <span>{desc}</span>
            </div>
            {children}
          </Card>
          <img
            src={require('../../../assets/images/auth-bg-sign-in.png')}
            className="AuthLayout__bg"
            alt=""
          />
        </form>
      </div>
    </>
  );
};

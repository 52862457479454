import { useNavigate } from 'react-router-dom';
import { useCourseFavorite } from '../../../entities/Courses';
import { Favorite } from '../../../horizon-components/Favorite';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useUserContext } from '../../../providers';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { saveCurrentUrl } from '../../../api/sessions';

interface CourseFavoriteProps {
  className?: string;
  isFavorite?: boolean;
  favoriteId: number;
}

export const CourseFavorite = ({
  className,
  isFavorite = false,
  favoriteId,
}: CourseFavoriteProps) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { favorite, onFavorite, isLoading } = useCourseFavorite(isFavorite);

  const handleFavorite = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    onFavorite(favoriteId);
  };

  return (
    <MButton
      size="xs"
      color="white"
      variant="highlighted"
      className={`!rounded-full !px-2 !py-[7px] ${className}`}
      onClick={handleFavorite}
      disabled={isLoading}
    >
      <Favorite
        favorite={favorite}
        isLoading={isLoading}
        onFavorite={() => onFavorite(favoriteId)}
      />
    </MButton>
  );
};

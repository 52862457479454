import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import { authMe, createJWT } from '../../../api/auth';
import useForm, { ErrorMessages } from '../../../hooks/useForm';
import { Checkbox } from '../../../horizon-components/Checkbox/Checkbox';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useUserContext } from '../../../providers';
import { AuthLayout } from '../../../widgets/AuthLayout';
import { GoogleAuth } from '../GoogleAuth/GoogleAuth';
import { MInput } from '../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { getRedirectPath } from '../../../api/sessions';

type SignInForm = {
  email: string;
  password: string;
};

const SignIn: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { saveUser, user } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const redirectPath = getRedirectPath();

  useEffect(() => {
    if (user) navigate(MAIN_PAGE_ROUTE);
  }, [navigate, user]);

  const { values, onChange, errors, validate } = useForm<SignInForm>({
    email: '',
    password: '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(ErrorMessages.email)
          .required(ErrorMessages.required),
        password: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  const signInHandler = async () => {
    setError('');
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    setIsLoading(true);
    createJWT(values.email, values.password)
      .then((data) => {
        saveAssesTokenStorage(data.access);
        saveRefreshTokenStorage(data.refresh);

        return authMe().then((data) => {
          saveUser(data);
          const redirectUrl = searchParams.get('redirect');
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            navigate(redirectPath || MAIN_PAGE_ROUTE);
          }
        });
      })
      .catch(() => {
        setError(t('profile.errorEmail'));
      })
      .finally(() => setIsLoading(false));
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.key === 'Enter' && signInHandler();
  };

  return (
    <AuthLayout
      title={t('profile.signIn')}
      desc={t('auth.loginDesc')}
      wrapperClassName={'lg:min-h-[760px]'}
    >
      <>
        <GoogleAuth signIn={true} />
        <div className="flex items-center gap-3">
          <div className="h-px w-full bg-purple-200" />
          <p className="text-xs font-[14px] text-purple-300 md:text-base">
            {' '}
            {t('profile.or')}{' '}
          </p>
          <div className="h-px w-full bg-purple-200" />
        </div>
        <div className="flex flex-col gap-[16px]">
          <MInput
            label={t('profile.email')}
            placeholder="mail@simmmple.com"
            id="email"
            name={'email'}
            type="text"
            bottomText={errors.email}
            state={errors.email ? 'error' : 'default'}
            value={values.email}
            onChange={onChange}
            inputClassName={'w-full leading-[18px]'}
            wrapperClassName={'w-full'}
            onKeyUp={handleEnter}
          />
          <MInput
            label={t('profile.password')}
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            name={'password'}
            bottomText={errors.password}
            state={errors.password ? 'error' : 'default'}
            value={values.password}
            onChange={onChange}
            inputClassName={'w-full leading-[18px]'}
            wrapperClassName={'w-full'}
            onKeyUp={handleEnter}
          />
          <div className="mt-2 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox
                onChange={(e) => setRememberMe(e.target.checked)}
                checked={rememberMe}
                color={'navy'}
              />
              <p className="ml-2 text-xs font-medium text-navy-700 dark:text-white md:text-sm">
                {t('profile.keep')}
              </p>
            </div>
            <Link
              to={MainRoutes.auth + AuthRoutes.reset}
              className="text-right text-xs font-medium text-purple-500 hover:text-purple-500 md:text-sm"
            >
              {t('profile.forgot')}
            </Link>
          </div>
          {!!error && <p className={'mt-3 text-center text-red-600'}>{error}</p>}
          <MButton
            variant="highlighted"
            color="primary"
            onClick={signInHandler}
            disabled={isLoading}
            size={'md'}
          >
            {t('auth.Sign_In')}
          </MButton>
          <div>
            <span className="text-xs font-medium text-navy-700 dark:text-gray-500 md:text-sm">
              {t('profile.notRegistered')}
            </span>
            <Link to={MainRoutes.auth + AuthRoutes.signup}>
              <span className="ml-1 text-xs font-medium text-purple-500 hover:text-purple-600 md:text-sm">
                {t('profile.createAccount')}
              </span>
            </Link>
          </div>
        </div>
      </>
    </AuthLayout>
  );
};

export default SignIn;

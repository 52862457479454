import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useUserContext } from '../../../providers';
import { generateHashFromGuid } from '../../../utils/getUserData';
import { getRandomUserImage } from '../../../utils/randomUserImage';
import { saveUserStorage } from '../../../api';
import { changeUserMetaAvatar } from '../../../api/auth';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import getErrorMessages from '../../../utils/getErrorMessages';
import { UserMeta } from '../../../models';
import { DropImg } from './DropImg';
import './ProfileCard.scss';
import { fileToBase64 } from '../../../utils/fileToBase64';

interface ProfileCardProps {
  guid?: string;
  className?: string;
  edit?: boolean;
  userMeta?: UserMeta | null;
}

export const ProfileCard = ({
  guid,
  className,
  edit = false,
  userMeta,
}: ProfileCardProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [avatar, setAvatar] = useState<File | null | string>(null);
  const [cover, setCover] = useState<File | null | string>(null);

  function onDropAvatar(file: File[]) {
    setAvatar(file[0]);
    submit(file[0], 'AVATAR');
  }

  function onDropCover(file: File[]) {
    setCover(file[0]);
    submit(file[0], 'COVER');
  }

  const avatarSrc = useMemo(() => {
    let src =
      userMeta?.profile_image || require('../../../assets/images/profile.200.jpg');
    if (typeof avatar === 'string') {
      src = avatar;
    } else if (avatar instanceof File) {
      src = URL.createObjectURL(avatar);
    }
    return src;
  }, [avatar, userMeta?.profile_image]);

  const coverSrc = useMemo(() => {
    let src =
      userMeta?.cover_image || getRandomUserImage(generateHashFromGuid(guid || ''));
    if (typeof cover === 'string') {
      src = cover;
    } else if (cover instanceof File) {
      src = URL.createObjectURL(cover);
    }
    return src;
  }, [cover, userMeta?.cover_image]);

  const submit = async (file: File, title: 'AVATAR' | 'COVER') => {
    changeUserMetaAvatar(guid || '', {
      ...(title === 'AVATAR' ? { profile_image: file as File } : {}),
      ...(title === 'COVER' ? { cover_image: file as File } : {}),
    })
      .then(async (data) => {
        if (user) {
          user.user_meta.profile_image = data.profile_image
            ? await fileToBase64(data.profile_image)
            : null;
          user.user_meta.cover_image = data.cover_image
            ? await fileToBase64(data.cover_image)
            : null;
          saveUserStorage(user);

          toast(t('profile.success'), {
            type: 'success',
          });
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      });
  };

  return (
    <div className={classNames('ProfileCard', className)}>
      <div
        style={{
          backgroundImage: `url(${coverSrc})`,
        }}
        className="ProfileCard__banner"
      >
        {edit && <DropImg onDrop={onDropCover} className="ProfileCard__edit" />}
        <div className="ProfileCard__avatar">
          {edit ? (
            <DropImg onDrop={onDropAvatar} className="ProfileCard__edit">
              <img
                className="absolute left-0 h-full w-full rounded-full object-cover"
                src={avatarSrc}
                alt={userMeta?.username}
              />
            </DropImg>
          ) : (
            <img
              className="absolute left-0 h-full w-full rounded-full object-cover"
              src={avatarSrc}
              alt={userMeta?.username}
            />
          )}
        </div>
      </div>
      <div className="ProfileCard__user">
        <h1>{userMeta?.username}</h1>
        <p>
          {userMeta?.last_name} {userMeta?.first_name}
        </p>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ImageIcon } from '../../../assets/icons/feed/image.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/feed/link.svg';
import { ReactComponent as ArticleIcon } from '../../../assets/icons/feed/article.svg';
import { MButton } from '../../../horizon-components/MButton';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import './ActionBar.scss';
import { DOIModal } from '../../DOIModal';
import { Modal } from '../../../horizon-components/Modal';
import { createFeed, getСitation, ResourceType } from '../../../api/weber/feed';
import { Feed } from '../../../models/Feed';
import { toast } from 'react-toastify';
import getErrorMessages from '../../../utils/getErrorMessages';

export interface ActionButton {
  title: string;
  link?: string;
  icon: JSX.Element; // Assuming the icon is a JSX element
  description: string;
  onClick?: () => void;
}

interface ActionBarProps {
  className?: string;
  communityFeedId?: string | number;
  variant?: 'highlighted' | 'outline';
}

export const ActionBar = ({
  className,
  communityFeedId,
  variant = 'highlighted',
}: ActionBarProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [showActions, setShowActions] = useState<boolean>(false);
  const [isOpenDOI, setIsOpenDOI] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [feed, setFeed] = useState<Feed | null>(null);
  const [count, setCount] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const duration = 10; // seconds
  const interval = 100; // milliseconds
  const increments = 100; // final value
  const step = increments / ((duration * 1000) / interval);

  useEffect(() => {
    if (!!isLoading) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= increments) {
            clearInterval(intervalId);
            return increments;
          }
          return prevCount + step;
        });
      }, interval);

      return () => {
        clearInterval(intervalId);
        setCount(100);
      };
    }
  }, [isLoading]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowActions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowActions]);

  const buttons: ActionButton[] = useMemo(
    () => [
      {
        title: t('feed.post_with_attachements'),
        link: MainRoutes.weber + WeberRoutes['feed-create-attachments'],
        icon: <ImageIcon />,
        description: t('feed.Share_content_with_images_and_docs'),
      },
      {
        title: t('feed.Share_a_paper'),
        icon: <LinkIcon />,
        description: t('feed.Share_a_paper_via_DOI'),
        onClick: () => hndlOpenDOI(),
      },
      {
        title: t('feed.post_with_link'),
        link: MainRoutes.weber + WeberRoutes['feed-create-link'],
        icon: <LinkIcon />,
        description: t('feed.Publish_link_to_your_article'),
      },
      {
        title: t('feed.post_with_article'),
        link: MainRoutes.weber + WeberRoutes['feed-create-article'],
        icon: <ArticleIcon />,
        description: t('feed.Write_your_article_with_large_content'),
      },
    ],
    [t]
  );

  const handleClick = (link: string) =>
    navigate(link, {
      state: { communityIdFeed: communityFeedId },
    });

  const hndlOpenDOI = () => {
    setIsOpenDOI(!isOpenDOI);
    setCount(0);
    setFeed(null);
    setIsLoading(false);
  };

  const hndlCreateDOI = (urlDOI: string) => {
    setIsLoading(true);
    getСitation(urlDOI)
      .then((res) => {
        res?.title
          ? createFeed({
              ...res,
              resourcetype: ResourceType.DoiPost,
              tags: [{ label: 'Chemistry', value: 'Chemistry' }],
              doi_link: res?.link,
              is_published_in_feed: true,
            })
              .then((res) => setFeed(res))
              .catch((error) => {
                toast(getErrorMessages(error?.response?.data), {
                  type: 'error',
                });
              })
              .finally(() => setIsLoading(false))
          : toast(t('feed.Insufficient_data'), {
              type: 'warning',
            });
      })
      .catch(() => {
        setCount(0);
        setIsLoading(false);
      });
  };

  return (
    <div className={classNames('sm:relative', className)}>
      <MButton
        color="primary"
        variant={variant}
        onClick={() => setShowActions(!showActions)}
        className="text-nowrap sm-max:h-[42px] sm-max:w-[42px] sm-max:!rounded-[16px] sm-max:bg-purple-500 sm-max:!px-[12px] sm-max:!text-white"
      >
        <span className="hidden sm:block">{t('feed.createFeed')}</span> +
      </MButton>
      {showActions && (
        <div ref={ref} className={classNames('ActionBar', className)}>
          {buttons?.map((button, idx) => (
            <div
              className="ActionBar__item"
              onClick={() =>
                button?.link ? handleClick(button.link) : button?.onClick?.()
              }
              key={idx}
            >
              {!!button.icon && <div className="ActionBar__icon">{button.icon}</div>}
              <div className="ActionBar__content">
                <h6>{button.title}</h6>
                <span>{button.description}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      <Modal
        title={t('feed.Share_an_article')}
        isOpen={isOpenDOI}
        onClose={hndlOpenDOI}
      >
        <DOIModal
          onSave={hndlCreateDOI}
          onCancel={hndlOpenDOI}
          isLoading={isLoading}
          count={count}
          feed={feed}
        />
      </Modal>
    </div>
  );
};
